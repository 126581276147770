import { OnboardingSchedulerDesktop, OnboardingSchedulerMobile } from './components';

type OnboardingSchedulerProps = {
  firstName: string;
};
const OnboardingScheduler = ({ firstName }: OnboardingSchedulerProps) => {
  return (
    <div className="lg:p-4x-large gap-3x-large flex h-full max-h-[calc(100vh_-_72px)] flex-col overflow-y-auto">
      <div>
        <h4 className="text-4x-large font-F37Bolton-Bold">Welcome, {firstName} 👋</h4>
        <p className="md:text-content-body-strong text-content-body-medium max-w-[835px]">
          Book a slot with our support and we will help you onboard in minutes. Keep the needed
          documents ready to make the process even faster!
        </p>
      </div>
      {/* for mobile view */}
      <OnboardingSchedulerMobile />
      {/* for desktop view */}
      <OnboardingSchedulerDesktop />
    </div>
  );
};

export default OnboardingScheduler;
