import { useRadio, useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import React, { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface RadioOption {
  value: string | number;
  label: string;
}

interface RadioGroupProps {
  options: RadioOption[];
  label?: string;
  onChange?: (value: string) => void;
  isRequired?: boolean;
  defaultValue?: string;
  parentClasses?: string;
  labelClasses?: string;
}

const RadioButtonVerticalGroup: React.FC<RadioGroupProps> = ({
  options,
  label,
  onChange,
  isRequired = false,
  defaultValue,
  parentClasses,
  labelClasses = '',
}) => {
  const state = useRadioGroupState({
    onChange,
    defaultValue,
    value: defaultValue,
  });

  const effectiveLabel = label || 'Default Radio Group Label';
  const { radioGroupProps, labelProps } = useRadioGroup({ 'aria-label': effectiveLabel }, state);

  return (
    <div {...radioGroupProps} className={`flex flex-col ${parentClasses}`}>
      {label && (
        <span {...labelProps} className="font-F37Bolton-Medium text-gray-700">
          {label} {isRequired && <span className="text-red-500">*</span>}
        </span>
      )}
      {options.map((option, index) => (
        <RadioButton
          labelClasses={labelClasses}
          key={option.value}
          option={option}
          state={state}
          index={index}
        />
      ))}
    </div>
  );
};

interface RadioButtonProps {
  option: RadioOption;
  state: ReturnType<typeof useRadioGroupState>;
  index: number;
  labelClasses?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ option, state, index, labelClasses }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { inputProps } = useRadio(
    {
      value: option.value,
      children: option.label,
    },
    state,
    ref,
  );

  const extendedInputProps = {
    ...inputProps,
    onFocus: e => {
      inputProps.onFocus?.(e);
      setIsFocused(true);
    },
    onBlur: e => {
      inputProps.onBlur?.(e);
      setIsFocused(false);
    },
  };

  return (
    <label
      className={twMerge(
        `mt-3 inline-flex items-center ${isFocused && 'rounded-md ring-2 ring-blue-500'}`,
        labelClasses ?? '',
      )}
    >
      <input {...extendedInputProps} ref={ref} className="visuallyHidden" />
      <div
        className={`size-6 rounded-full border border-gray-300  p-[3px] ${
          inputProps.checked ? 'bg-white' : ''
        }`}
      >
        <div
          className={`size-[16px] rounded-full ${inputProps.checked ? 'bg-emerald-500' : ''}`}
        ></div>
      </div>
      <span className="ml-2 text-gray-700">{option.label}</span>
    </label>
  );
};

export default RadioButtonVerticalGroup;
