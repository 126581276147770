import { MutationOptions, useMutation } from '@tanstack/react-query';

import { postCall } from '@/api/axios';

import { payrollScheduleMutationEndpoints } from './endpoints';

const useSubmitPayrollSchedule = ({
  companyId,
  mutationParams,
}: {
  companyId: number;
  mutationParams?: MutationOptions;
}) => {
  const { mutationKey, url } = payrollScheduleMutationEndpoints.submitPayrollSchedule(companyId);

  return useMutation({
    mutationKey,
    mutationFn: data => postCall(url, data),
    ...mutationParams,
  });
};

export default useSubmitPayrollSchedule;
