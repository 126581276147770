import { CancelObjectType } from '../types';

export type usePostCancelSubscriptionType = {
  onClose: () => void;
};

export type Data = {
  data: CancelObjectType;
  cancellationActionType: 'call_now' | 'schedule_a_call' | 'cancel_subscription';
};

interface ReasonMappings {
  [key: string]: string | undefined;
}

export const reasonMappings: ReasonMappings = {
  '1': 'Product/Feature Gap',
  '2': 'User-friendliness',
  '3': 'Price',
  '4': 'Persona Mismatch',
  other: 'Other',
};

enum FeedbackOption {
  BusinessOwnerNeedsPaystubs = 1,
  EmployeeNeedsPaystubs,
  OneTimeTaxForm,
  UnsupportedEmployeeCount,
  Other,
}

type OptionMappings = {
  [key in FeedbackOption]: string;
};

const optionMappings: OptionMappings = {
  [FeedbackOption.BusinessOwnerNeedsPaystubs]:
    'I am a business owner and just need pay stub(s) for my employee(s)',
  [FeedbackOption.EmployeeNeedsPaystubs]: 'I am an employee and need pay stub for personal reason',
  [FeedbackOption.OneTimeTaxForm]: 'I just wanted to generate a one-time tax form',
  [FeedbackOption.UnsupportedEmployeeCount]: `Your solution doesn't support the number of employees that I have`,
  [FeedbackOption.Other]: 'Another reason',
};

export const feedbackForCaseFour = (options: number | 'other'): string => {
  let optionKey: FeedbackOption = typeof options === 'number' ? options : FeedbackOption.Other;
  return optionMappings[optionKey];
};
