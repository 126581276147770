import React from 'react';

import { ReactComponent as ArrowIcon } from '@/assets/arrow.svg';
import { ReactComponent as BlackArrowIcon } from '@/assets/arrowBlack.svg';
import { ReactComponent as BackUpdatedIcon } from '@/assets/backUpdated.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/chevronRight.svg';
import { ReactComponent as ContractorIcon } from '@/assets/contractor.svg';
import { ReactComponent as DownloadIcon } from '@/assets/download.svg';
import { ReactComponent as DownloadAndPrintIcon } from '@/assets/downloadAndPrint.svg';
import { ReactComponent as EditIcon } from '@/assets/edit.svg';
import { ReactComponent as EmployeeIcon } from '@/assets/employee.svg';
import { ReactComponent as FacebookIcon } from '@/assets/facebook.svg';
import { ReactComponent as GoogleIcon } from '@/assets/google.svg';
import { ReactComponent as LogoutIcon } from '@/assets/logout.svg';
import { ReactComponent as MinusIcon } from '@/assets/minus.svg';
import { ReactComponent as PlusIcon } from '@/assets/plus.svg';
import { ReactComponent as BlackPlusIcon } from '@/assets/plusBlack.svg';
import { ReactComponent as ShareIcon } from '@/assets/share.svg';
import { ReactComponent as TrashIcon } from '@/assets/trash.svg';
import { ReactComponent as TwitterIcon } from '@/assets/twitter.svg';
import { ReactComponent as XmarkIcon } from '@/assets/x-mark.svg';

import { WithGTMButton } from '../../HOCs/Analytics';

type Props = {
  type:
    | 'primary'
    | 'loading'
    | 'link'
    | 'linkWithArrow'
    | 'socialMedia'
    | 'back'
    | 'add'
    | 'download'
    | 'logout'
    | 'counterMinus'
    | 'counterPlus'
    | 'simpleDownload'
    | 'deleteIcon'
    | 'editIcon'
    | 'closeIcon'
    | 'secondary'
    | 'employee'
    | 'contractor'
    | 'goBack'
    | 'downloadAndPrint'
    | 'share';
  isCritical?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  action?: 'button' | 'reset' | 'submit';
  socialMediaType?: string;
  onClick?: () => void;
  width?: string;
  isLoading?: boolean;
  fontSize?: string;
  height?: string;
  bgColor?: string;
  marginX?: string;
  isLeft?: boolean;
  className?: string;
};

const ButtonWithoutGTM = ({
  type,
  children,
  isCritical,
  socialMediaType,
  isDisabled,
  marginX,
  isLoading = false,
  action = 'submit',
  onClick,
  width,
  fontSize,
  height,
  bgColor,
  isLeft,
  className,
  ...rest
}: Props) => {
  {
    if (type === 'loading') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`flex h-12 ${width ? width : 'w-80'} items-center justify-center rounded-3xl ${
            bgColor ? bgColor : 'bg-emerald-500'
          } ${!bgColor && 'hover:opacity-90'}  ${className ?? ''}`}
        >
          <img
            src={'https://i.ibb.co/cvv6sQB/loader.png'}
            alt="loader"
            // eslint-disable-next-line
            className="animate-reverse-spin h-6 w-6"
          />
        </button>
      );
    }
    if (type === 'primary') {
      return (
        <button
          onClick={onClick}
          type={action}
          disabled={isDisabled}
          {...rest}
          className={`h-12 ${width ? width : 'w-[335px]'}  ${
            isDisabled ? 'bg-emerald-200' : bgColor ? bgColor : 'bg-emerald-500'
          } rounded-3xl ${fontSize ? fontSize : 'text-base'} font-F37Bolton-Medium text-white ${
            !bgColor && 'hover:opacity-90'
          } ${isLoading && 'flex items-center justify-center'}
          ${marginX ? marginX : ''}
          ${className ?? ''}
          `}
        >
          {isLoading ? (
            <img
              src={'https://i.ibb.co/cvv6sQB/loader.png'}
              alt="loader"
              // eslint-disable-next-line
              className="animate-reverse-spin h-6 w-6"
            />
          ) : (
            children
          )}
        </button>
      );
    }
    if (type === 'goBack') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          // eslint-disable-next-line
          className={`h-12 ${
            width ? width : 'w-[335px]'
          } border-navy-500 text-navy-500 rounded-3xl border ${
            fontSize ? fontSize : 'text-base'
          } text-navy-500 hover:opacity-90`}
        >
          {/* eslint-disable-next-line */}
          <span className="font-F37Bolton-Medium flex items-center justify-center ">
            <BlackArrowIcon />
            {children}
          </span>
        </button>
      );
    }
    if (type === 'link') {
      return (
        <button
          onClick={onClick}
          type={action}
          disabled={isDisabled}
          {...rest}
          className={`${isLeft ? 'text-left' : ''} ${height ? height : 'h-full'} ${fontSize} ${
            width ? width : 'w-full'
          } ${className ?? ''} ${
            isCritical
              ? isDisabled
                ? 'text-red-300'
                : 'text-red-500'
              : isDisabled
              ? 'text-blue-300'
              : 'text-blue-500'
          } font-F37Bolton-Medium font-medium ${
            !isDisabled && 'hover:text-blue-600 active:text-blue-700'
          } ${!isDisabled && isCritical && 'hover:text-red-600 active:text-red-700'}`}
        >
          {children}
        </button>
      );
    }
    if (type === 'linkWithArrow') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`group size-fit ${
            isDisabled ? 'text-blue-300' : 'text-blue-500 hover:text-blue-600 active:text-blue-700'
          } font-F37Bolton-Medium `}
        >
          <div className="flex items-center justify-center">
            <span className="font-F37Bolton-Medium">{children}</span>
            <ArrowIcon
              className={`${
                isDisabled
                  ? 'stroke-blue-300'
                  : 'stroke-blue-500 group-hover:stroke-blue-600 group-active:stroke-blue-700'
              } `}
            />
          </div>
        </button>
      );
    }
    if (type === 'socialMedia') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`h-12 w-24 ${
            isDisabled ? 'bg-gray-50' : 'hover:border-gray-200 active:border-gray-300'
          } m-4 flex items-center justify-center rounded-3xl border border-gray-100`}
        >
          {socialMediaType === 'google' && <GoogleIcon />}
          {socialMediaType === 'facebook' && <FacebookIcon />}
          {socialMediaType === 'twitter' && <TwitterIcon />}
        </button>
      );
    }
    if (type === 'back') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`group flex w-fit items-center justify-center font-medium ${
            isDisabled ? 'text-blue-300' : 'text-blue-500 hover:text-blue-600 active:text-blue-700'
          }`}
        >
          <>
            <BackUpdatedIcon />
            <span className="font-F37Bolton-Medium">{children ? children : 'Back'}</span>
          </>
        </button>
      );
    }
    if (type === 'add') {
      return (
        <button
          onClick={onClick}
          type="button"
          disabled={isDisabled}
          {...rest}
          className={`group ${width} flex items-center justify-center ${
            fontSize ? fontSize : 'text-sm'
          }  font-F37Bolton-Medium ${
            isDisabled ? 'text-blue-300' : 'text-blue-500 hover:text-blue-600 active:text-blue-700'
          } font-F37Bolton-Medium`}
        >
          <>
            <PlusIcon
              className={`${
                isDisabled
                  ? 'stroke-blue-300'
                  : 'stroke-blue-500 group-hover:stroke-blue-600 group-active:stroke-blue-700'
              }`}
            />
            <span className="font-F37Bolton-Medium">{children ?? 'Add'}</span>
          </>
        </button>
      );
    }
    if (type === 'download') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`group m-4 flex items-center justify-center text-base font-medium ${
            isDisabled ? 'text-blue-300' : 'text-blue-500 hover:text-blue-600 active:text-blue-700'
          }`}
        >
          <>
            <DownloadIcon
              className={`mr-2.5 ${
                isDisabled
                  ? 'stroke-blue-300'
                  : 'stroke-blue-500 group-hover:stroke-blue-600 group-active:stroke-blue-700'
              }`}
            />
            <span className="font-F37Bolton-Medium">{children}</span>
          </>
        </button>
      );
    }
    if (type === 'logout') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`group flex items-center justify-center text-base font-medium ${
            isDisabled ? 'text-red-300' : 'text-red-500 hover:text-red-600 active:text-red-700'
          }`}
        >
          <>
            <LogoutIcon
              className={`mr-2.5 ${
                isDisabled
                  ? 'stroke-red-300'
                  : 'stroke-red-500 group-hover:stroke-red-600 group-active:stroke-red-700'
              }`}
            />
            <span className="font-F37Bolton-Medium">Log out</span>
          </>
        </button>
      );
    }
    if (type === 'counterMinus') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`m-4 flex size-7 items-center justify-center border-2 ${
            isDisabled ? 'bg-gray-50' : 'hover:border-gray-200 active:border-gray-300'
          } border border-gray-100`}
        >
          <MinusIcon />
        </button>
      );
    }
    if (type === 'counterPlus') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`m-4 flex size-7 items-center justify-center border-2 ${
            isDisabled ? 'bg-gray-50' : 'hover:border-gray-200 active:border-gray-300'
          } border border-gray-100`}
        >
          <BlackPlusIcon />
        </button>
      );
    }
    if (type === 'simpleDownload') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`group m-4 flex items-center justify-center text-base font-medium ${
            isDisabled ? 'text-blue-300' : 'text-blue-500 hover:text-blue-600 active:text-blue-700'
          }`}
        >
          <span className="font-F37Bolton-Medium">Download</span>
        </button>
      );
    }
    if (type === 'deleteIcon') {
      return (
        <button onClick={onClick} disabled={isDisabled} {...rest}>
          <TrashIcon
            className={`${
              isDisabled
                ? 'stroke-gray-200'
                : 'stroke-red-500 hover:stroke-red-700 active:stroke-red-600'
            }`}
          />
        </button>
      );
    }
    if (type === 'editIcon') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          className="flex items-center justify-center"
          {...rest}
        >
          <EditIcon
            className={`${
              isDisabled
                ? 'stroke-blue-300'
                : 'stroke-blue-500 hover:stroke-blue-600 active:stroke-blue-700'
            } mr-[6px]`}
          />
          {children}
        </button>
      );
    }
    if (type === 'closeIcon') {
      return (
        <button onClick={onClick} disabled={isDisabled} {...rest}>
          <XmarkIcon
            className={`${
              isDisabled
                ? 'stroke-gray-200'
                : 'stroke-gray-400 hover:stroke-gray-500 active:stroke-gray-600'
            } ${width && width} ${className ?? ''}`}
          />
        </button>
      );
    }
    if (type === 'secondary') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`h-12 ${width ? width : 'w-80'} border ${
            isDisabled
              ? 'border-emerald-300 text-emerald-300'
              : 'border-emerald-500 text-emerald-500 hover:border-emerald-600 hover:text-emerald-600 active:border-emerald-600 active:bg-emerald-50 active:text-emerald-600'
          } rounded-3xl text-base`}
        >
          {children}
        </button>
      );
    }
    if (type === 'employee') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`rounded-2xl bg-white ${fontSize ? fontSize : 'text-base'} ${
            width ? width : 'w-[335px]'
          } ${
            height ? height : 'h-12 '
          } flex items-center justify-between border-[1px] border-gray-300 px-[16px] py-[14px] shadow-[3px_3px_0_var(--navy-200)]`}
        >
          <div className="flex items-center">
            <EmployeeIcon />
            {/* eslint-disable-next-line */}
            <span className="font-F37Bolton-Medium ml-[16px]">Employee</span>
          </div>
          <ChevronRightIcon />
        </button>
      );
    }
    if (type === 'contractor') {
      return (
        <button
          onClick={onClick}
          disabled={isDisabled}
          {...rest}
          className={`rounded-2xl bg-white ${fontSize ? fontSize : 'text-base'} ${
            width ? width : 'w-[335px]'
          } ${
            height ? height : 'h-12 '
          } flex items-center justify-between border-[1px] border-gray-300 px-[16px] py-[14px] shadow-[3px_3px_0_var(--navy-200)]`}
        >
          {/* eslint-disable-next-line */}
          <div className="flex items-center">
            <ContractorIcon />
            {/* eslint-disable-next-line */}
            <span className="font-F37Bolton-Medium ml-[16px]">Contractor</span>
          </div>
          <ChevronRightIcon />
        </button>
      );
    }
    if (type === 'downloadAndPrint') {
      return (
        <button
          onClick={onClick}
          type={action}
          disabled={isDisabled}
          {...rest}
          className={`h-12 ${width ? width : 'w-[335px]'} rounded-3xl bg-white ${
            fontSize ? fontSize : 'text-base'
          } font-F37Bolton-Medium flex items-center justify-center border border-gray-200 text-blue-500 hover:opacity-90`}
        >
          <DownloadAndPrintIcon />
          <span className="ml-[8px]">Download & Print</span>
        </button>
      );
    }
    if (type === 'share') {
      return (
        <button
          onClick={onClick}
          type={action}
          disabled={isDisabled}
          {...rest}
          className={`h-12 ${width ? width : 'w-[335px]'} rounded-3xl bg-white ${
            fontSize ? fontSize : 'text-base'
          } font-F37Bolton-Medium flex items-center justify-center border border-gray-200 text-blue-500 hover:opacity-90`}
        >
          <ShareIcon />
          <span className="ml-[8px]">
            {children ? children : 'Share with Contractor via Email'}
          </span>
        </button>
      );
    }
    return null;
  }
};

const Button = WithGTMButton(ButtonWithoutGTM);
export default Button;
