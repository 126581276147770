import { isObjHasData } from '@/utils/helpers';
import shuffle from 'lodash/shuffle';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { CalculateButtonDisabledStateType, useDetailsFormType } from './types';

export const useDetailsForm = ({ reason, nextTab }: useDetailsFormType) => {
  const [lookingFor, setLookingFor] = useState<string>('');
  const [lookingForOther, setLookingForOther] = useState<string>('');
  const [isNoProvider, setIsNoProvider] = useState<boolean>(false);
  const [featuresOptions, setFeaturesOptions] = useState<{ label: string; value: string }[]>([]);
  const [difficultiesOptions, setDifficultiesOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const formState = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    nextTab(isObjHasData(data) ? data : { lookingFor, lookingForOther });
  };

  const otherInterestValue = formState.watch('otherInterest');
  const features = formState.watch('features');
  const difficulties = formState.watch('difficulties');
  const noProvider = formState.watch('noProvider');
  const amount = formState.watch('amount');

  const isButtonDisabled = calculateButtonDisabledState({
    features,
    difficulties,
    amount,
    noProvider,
    otherInterestValue,
    lookingFor,
    lookingForOther,
  });

  const renderTitle = () => {
    if (reason === '1') return `Which features were you hoping to find?`;
    if (reason === '2') return `What part you did you find difficult?`;
    if (reason === '3') return `How much do you pay per month with your current payroll provider?`;
    if (reason === '4') return `What were you looking for?`;
  };

  useEffect(() => {
    const initialFeatures = [
      { label: `Direct deposit`, value: 'Direct deposit' },
      { label: `Employee portal`, value: 'Employee portal' },
      { label: `Tax reports`, value: `Tax reports` },
      { label: `Tax filing`, value: `Tax filing` },
      { label: `Employee time tracking`, value: `Employee time tracking` },
      { label: `Integration with my current tools`, value: `Integration with my current tools` },
      { label: `Other`, value: 'other' },
    ];

    const shuffledOptions = [
      ...shuffle(initialFeatures.filter(option => option.value !== 'other')),
      initialFeatures.find(option => option.value === 'other'),
    ];

    setFeaturesOptions(shuffledOptions);

    const initialDifficulties = [
      { label: `Setting Employer's Taxes`, value: `Setting Employer's Taxes` },
      { label: `Setting Payroll Schedule`, value: `Setting Payroll Schedule` },
      { label: `Adding Payroll history`, value: `Adding Payroll history` },
      { label: `Adding employees/contractors`, value: `Adding employees/contractors` },
      { label: `Business Profile info`, value: `Business Profile info` },
      { label: `Running a Payroll`, value: `Running a Payroll` },
      { label: `Other`, value: 'other' },
    ];

    const shuffledDifficulties = [
      ...shuffle(initialDifficulties.filter(option => option.value !== 'other')),
      initialDifficulties.find(option => option.value === 'other'),
    ];

    setDifficultiesOptions(shuffledDifficulties);
  }, []);

  return {
    formState,
    onSubmit,
    setLookingFor,
    setLookingForOther,
    isButtonDisabled,
    renderTitle,
    noProvider,
    lookingFor,
    lookingForOther,
    isNoProvider,
    setIsNoProvider,
    featuresOptions,
    difficultiesOptions,
  };
};

function calculateButtonDisabledState({
  features,
  difficulties,
  amount,
  noProvider,
  otherInterestValue,
  lookingFor,
  lookingForOther,
}: CalculateButtonDisabledStateType) {
  const isFirstFlowDisabled = features?.includes('other') ? !otherInterestValue : !features?.length;
  const isSecondFlowDisabled = difficulties?.includes('other')
    ? !otherInterestValue
    : !difficulties?.length;
  const isThirdFlowDisabled = !amount && !noProvider;
  const isFourthFlowDisabled = lookingFor === 'other' ? !lookingForOther.trim() : !lookingFor;

  return isFirstFlowDisabled && isSecondFlowDisabled && isThirdFlowDisabled && isFourthFlowDisabled;
}
