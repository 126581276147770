import { ReactComponent as ClosedEyeIcon } from '@/assets/closedEye.svg';
import { ReactComponent as EyeIcon } from '@/assets/eye.svg';
import { FormInputTextField } from '@/components';
import useInputPasswordField from './useInputPasswordField';

interface InputPasswordFieldProps {
  name: string;
  title: string;
  placeholder?: string;
  shouldUnregister?: boolean;
}

const InputPasswordField: React.FC<InputPasswordFieldProps> = ({
  name,
  title,
  placeholder,
  shouldUnregister = true,
}) => {
  const { handleShowPassword, showPassword } = useInputPasswordField();
  return (
    <FormInputTextField
      shouldUnregister={shouldUnregister}
      name={name}
      placeholder={placeholder}
      key={name}
      isRequired
      type={showPassword ? 'text' : 'password'}
      title={title}
      width="w-full"
      endAdornment={
        !showPassword ? (
          <EyeIcon
            className="absolute bottom-[8px] right-[20px] hover:cursor-pointer"
            onClick={() => handleShowPassword()}
          />
        ) : (
          showPassword && (
            <ClosedEyeIcon
              className="absolute bottom-[8px] right-[20px] hover:cursor-pointer"
              onClick={() => handleShowPassword()}
            />
          )
        )
      }
    />
  );
};

export default InputPasswordField;
