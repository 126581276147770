import { MutationEndpoint, QueryEndpoint } from '../types';

interface Payroll {
  payDate: string;
  payPeriod: PayPeriod;
  frequency: number;
}

interface PayPeriod {
  startDate: string;
  endDate: string;
}

export const queryEndpoints = {
  runnablePayroll: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'get-runnable'],
    url: `company/${companyId}/pay-employee/get-runnable`,
  }),
};

export const mutationEndpoints = {
  submitRunnablePayroll: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'test-payroll'],
    url: `/company/${companyId}/pay-employee/submit-runnable`,
    invalidateKeys: [],
  }),
  skipRunnablePayroll: (companyId: number) => ({
    mutationKey: ['company', companyId, 'skip-payroll'],
    url: `/company/${companyId}/pay-employee/skip-payroll`,
    invalidateKeys: [],
  }),
};
