import { impersonationLogoutHandler } from '@/utils/helpers';

const useImpersonateAccountBanner = () => {
  const stopImpersonationHandler = () => {
    impersonationLogoutHandler();
    window.location.reload();
  };

  return { stopImpersonationHandler };
};

export default useImpersonateAccountBanner;
