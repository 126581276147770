import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useSubscriptionBillingCard from '@/components/shared/SubscriptionBillingCard/useSubscriptionBillingCard';
import { useAccountInfo } from '@/hooks';
import { RootState } from '@/redux/store';
import { FREE_3_MONTH_TRIAL_SUBSCRIPTION, TRIAL_DAYS } from '@/utils/constants';
import { checkAccountForSubscriptionReminder, isArrayHasData } from '@/utils/helpers';

const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

type BillingCenterCommonType = { isManageBillingCenter: boolean };
export type BillingCenterType = BillingCenterCommonType;
export type UseBillingCenterType = BillingCenterCommonType;

const useBillingCenter = ({ isManageBillingCenter }: UseBillingCenterType) => {
  const [isOpenedCancelModal, setIsOpenedCancelModal] = useState<boolean>(false);
  const isPlansAlreadyVisible = sessionStorage.getItem('isPlansAlreadyVisible');
  const [isSubscriptionReminderModalOpen, setIsSubscriptionReminderModalOpen] = useState(false);
  const [isUpgradeSubscriptionPlanOpen, setIsUpgradeSubscriptionPlanOpen] = useState(false);
  const [isUpgradePlanWithPaymentOpen, setIsUpgradePlanWithPaymentOpen] = useState(false);

  const discountCode = sessionStorage.getItem('discountCode') ?? '';

  const { account } = useAccountInfo();

  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);
  const paymentMethods = subscriptionInformation?.paymentMethods;

  const invoiceHistory = useSelector(
    (state: RootState) => state.auth.subscriptionInfo?.invoicesToDisplay,
  );

  const formattedSubscriptionAgreement = useMemo(() => {
    const { activeSubscriptionAgreement, subscriptionAgreements } = subscriptionInformation || {};
    if (activeSubscriptionAgreement) {
      return activeSubscriptionAgreement;
    } else {
      return subscriptionAgreements?.length
        ? subscriptionAgreements.reduce((max, curr) => (curr.id > max.id ? curr : max))
        : null;
    }
  }, [subscriptionInformation]);

  const { formattedSubscriptionData } = useSubscriptionBillingCard({
    subscriptionData: formattedSubscriptionAgreement,
    usageStates: subscriptionInformation?.usageStats,
    subscriptionInformation: subscriptionInformation,
  });

  const trialDays =
    useSelector((state: RootState) => state?.auth?.callbackOnboardingInfo?.trialDays) ?? TRIAL_DAYS;

  const sortedPaymentMethods = paymentMethods?.sort(
    (a, b) => Number(b.isDefault) - Number(a.isDefault),
  );

  const { availableProducts } =
    useSelector((state: RootState) => state.auth?.subscriptionPlan) ?? [];

  const subscriptionInfo = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const bannerInfo = checkAccountForSubscriptionReminder({ subscriptionInfo, account });

  const handleOpenCancelModal = () => setIsOpenedCancelModal(!isOpenedCancelModal);

  const closeReminderModalHandler = () => {
    setIsSubscriptionReminderModalOpen(false);
  };

  const chooseSubscriptionPlanHandler = () => {
    const activeSubscriptionAgreement = subscriptionInformation?.activeSubscriptionAgreement;
    const subscriptionAgreements = subscriptionInformation?.subscriptionAgreements;

    const filteredSubscriptions = subscriptionAgreements.filter(
      item => item.plan !== FREE_3_MONTH_TRIAL_SUBSCRIPTION,
    );
    const isPaidPlanScheduledToBeCancelled = isArrayHasData(filteredSubscriptions)
      ? filteredSubscriptions.every(item => item.scheduledToBeCancelled)
      : false;

    const shouldRenderPayment =
      !activeSubscriptionAgreement ||
      activeSubscriptionAgreement?.status === 'canceled' ||
      isPaidPlanScheduledToBeCancelled ||
      activeSubscriptionAgreement?.scheduledToBeCancelled ||
      !paymentMethods.some(method => method.status === 'default');

    if (shouldRenderPayment) {
      setIsUpgradePlanWithPaymentOpen(true);
    } else {
      setIsUpgradeSubscriptionPlanOpen(true);
    }
  };

  useEffect(() => {
    if (isManageBillingCenter && !isPlansAlreadyVisible) {
      sessionStorage.setItem('isPlansAlreadyVisible', 'true');
      chooseSubscriptionPlanHandler();
    }
  }, []);

  const closeSubscriptionPlanHandler = () => {
    setIsUpgradeSubscriptionPlanOpen(false);
    setIsUpgradePlanWithPaymentOpen(false);
  };

  const isSubscribedAnyPlan = isArrayHasData(paymentMethods);

  const { remainingDays, isFreeTrailOver } = bannerInfo || {};

  return {
    subscriptionInformation,
    trialDays,
    sortedPaymentMethods,
    availableProducts,
    isAppModeDemo,
    invoiceHistory,
    handleOpenCancelModal,
    isOpenedCancelModal,
    formattedSubscriptionData,
    isSubscriptionReminderModalOpen,
    closeReminderModalHandler,
    chooseSubscriptionPlanHandler,
    closeSubscriptionPlanHandler,
    isUpgradeSubscriptionPlanOpen,
    isUpgradePlanWithPaymentOpen,
    setIsUpgradePlanWithPaymentOpen,
    remainingDays,
    isFreeTrailOver,
    isSubscribedAnyPlan,
    discountCode,
  };
};
export default useBillingCenter;
