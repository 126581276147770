import React from 'react';

import Button from '@/components/Button/Button';

import BillingHistoryTable from './components/BillingHistoryTable/BillingHistoryTable';
import BillingHistoryView from './components/BillingHistoryView/BillingHistoryView';

interface Props {
  invoices: any;
  choosePlanHandler: () => void;
  isSubscribedAnyPlan: boolean;
}

const BillingHistory: React.FC<Props> = ({ invoices, choosePlanHandler, isSubscribedAnyPlan }) => {
  return (
    <div className="mt-10 flex flex-col gap-4">
      <span className="font-F37Bolton-Medium text-xl text-gray-900">Invoicing</span>
      {invoices?.length > 0 ? (
        <>
          {invoices.map((bill: any) => (
            <BillingHistoryView data={bill} />
          ))}
          <BillingHistoryTable data={invoices} />
        </>
      ) : (
        <div className="border-navy-100 flex flex-col items-center justify-center rounded-xl border px-8 py-[60px] text-center">
          <span className="font-F37Bolton-Medium">No invoices yet.</span>

          <span className="max-w-[400px] pt-2 text-sm text-gray-400">
            {isSubscribedAnyPlan
              ? 'When your free trial ends, your subscription invoice will appear here.'
              : 'It looks like you haven’t started using our paid services yet. Once you subscribe, your invoices will appear here.'}
          </span>

          {!isSubscribedAnyPlan && (
            <Button
              variant="primary"
              children="Subscribe now"
              width="w-[155px]"
              parentClasses="mt-4"
              onPress={choosePlanHandler}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BillingHistory;
