import { Outlet } from 'react-router-dom';

import {
  Header,
  LoaderComponent,
  SideBarDesk as SideBar,
  SubscriptionReminderModal,
  TermsAndCondition,
} from '@/components';
import useDashBoardLayout from './useDashBoardLayout';

const DashBoardLayout = () => {
  const {
    updatedDesignRoutes,
    isLoading,
    isShowingDayReminderPopup,
    toggleReminderPopup,
    remainingDays,
    isFreeTrailOver,
    location,
  } = useDashBoardLayout();

  return (
    <div className="flex h-screen flex-col overflow-auto">
      <div className="sticky top-0 z-[45]">
        <Header showHelp showProfile showMobileMenuIcon />
      </div>
      <div className={`flex flex-1`}>
        <SideBar />
        <div className="flex w-full flex-1 flex-col">
          <main
            className={`relative flex w-full flex-1 flex-col lg:left-[270px] lg:max-w-[calc(100%-270px)] ${
              updatedDesignRoutes.some(item => location.pathname.includes(item))
                ? 'bg-white'
                : 'bg-gray'
            }`}
          >
            {isLoading ? <LoaderComponent /> : <Outlet />}
          </main>
        </div>
      </div>

      {isShowingDayReminderPopup && (
        <SubscriptionReminderModal
          isOpen={isShowingDayReminderPopup}
          onCancel={toggleReminderPopup}
          remainingDays={remainingDays}
          isFreeTrailOver={isFreeTrailOver}
        />
      )}
      <TermsAndCondition />
    </div>
  );
};
export default DashBoardLayout;
