import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import { InlineWidget } from 'react-calendly';
import { twMerge } from 'tailwind-merge';
import { CalendlyModalType } from '../types';
import { LINKS } from '@/utils/constants';

export const CalendlyModal = ({ onClose, pageSettings, closeButtonOnRight }: CalendlyModalType) => {
  return (
    <div className="h-full">
      <Button
        variant="icon"
        icon={CloseIcon}
        onPress={onClose}
        parentClasses={twMerge('absolute  ', closeButtonOnRight ? 'right-[16px]' : ' left-[16px]')}
      />
      <InlineWidget
        url={LINKS.calendlySupport}
        styles={{ height: '100%' }}
        pageSettings={pageSettings && pageSettings}
      />
    </div>
  );
};
