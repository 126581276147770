import { ReactComponent as SelfOnboardingIllustration } from '@/assets/illustration/selfOnboardingIllustration.svg';
import { Button } from '@/components/ui/designSystem/Button/Button';
import useSelfOnboarding from './useSelfOnboarding';

const SelfOnboarding = () => {
  const { handleSelfOnBoarding } = useSelfOnboarding();
  return (
    <div className="pt-4x-large rounded-small bg-primary-emerald-light-bg relative flex max-h-[calc(100vh_-_72px)]  flex-col items-start  justify-center gap-10 px-[45px] lg:w-2/5 lg:gap-0 lg:rounded-none  lg:bg-white 2xl:w-1/2 ">
      <div>
        <h4 className="text-2x-large font-F37Bolton-Bold ">
          Feel confident to continue on your own?
        </h4>
        <p className="text-content-body-medium w-full  pt-2 sm:max-w-[381px] ">
          Go ahead with self-onboarding, and we’ll be there to assist you anytime along the way
        </p>
        <Button
          variant="secondary"
          className="mt-4 w-full lg:max-w-[267px] "
          onPress={handleSelfOnBoarding}
        >
          Continue with self onboarding
        </Button>
      </div>
      <div className="flex w-full items-end justify-end">
        <SelfOnboardingIllustration className="bottom-0 right-0 h-auto lg:absolute [@media(max-height:716px)]:hidden [@media(max-height:800px)]:w-3/6" />
      </div>
    </div>
  );
};

export default SelfOnboarding;
