import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCall } from '@/api/axios';
import { RunnablePayrolls } from '@/redux/dto/employee';

import { queryEndpoints } from './endpoints';

type RunnablePayrollResponseType = AxiosResponse<RunnablePayrolls>;

const useGetRunnablePayroll = ({
  companyId,
  queryParams,
}: {
  companyId: number;
  queryParams?: Omit<
    UseQueryOptions<RunnablePayrollResponseType, Error, RunnablePayrollResponseType>,
    'queryKey'
  >;
}) => {
  const { queryKey, url } = queryEndpoints.runnablePayroll(companyId);

  return useQuery<RunnablePayrollResponseType, Error>({
    queryKey,
    queryFn: () => getCall(url),
    ...queryParams,
  });
};

export default useGetRunnablePayroll;
