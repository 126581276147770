import { useEffect, useImperativeHandle } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import { field as TaxFieldType } from '@/redux/dto/company';
import { handleEmployerTaxData } from '@/utils/helpers';

import { SUTA_TAX_ITEM_ID } from '../utils/constants';
import { FormType, useEmployerTaxItemFormType } from './types';

const useEmployerTaxItemForm = ({
  taxItem,
  shouldShowAllFields,
  onSubmit,
  isFederalTaxItem,
  ref,
  handleIsDirty,
}: useEmployerTaxItemFormType) => {
  const { description, info } = taxItem;

  const defaultValues: FormType = {
    isExempt: taxItem.isExempt,
    fields: taxItem.fields.map(field => ({
      ...field,
      value: field.value === null ? field.defaultValue : field.value,
    })),
  };

  const form = useForm<FormType>({ defaultValues });

  const {
    formState: { isDirty },
  } = form;

  useEffect(() => {
    handleIsDirty(isDirty);
  }, [isDirty]);

  const {
    append: addTaxField,
    remove: removeTaxField,
    fields: taxFormFields,
  } = useFieldArray({
    control: form.control,
    name: 'fields',
  });

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      form.handleSubmit(onSubmit)();
    },
  }));

  useEffect(() => {
    if (taxItem) {
      form.reset(defaultValues);
      handleTaxItems();
    }
  }, [taxItem, shouldShowAllFields]);

  const handleTaxItems = () => {
    removeTaxField();
    if (Array.isArray(taxItem.fields)) {
      const formattedTaxItems = handleEmployerTaxData({
        taxItems: [taxItem],
        shouldShowAllFields: isFederalTaxItem || shouldShowAllFields,
      });
      if (formattedTaxItems.length) {
        const [formattedTaxItem] = formattedTaxItems;
        formattedTaxItem.fields.forEach((field: TaxFieldType) => {
          addTaxField({
            ...field,
            value: field.value === null ? field.defaultValue : field.value,
          });
        });
      }
    }
  };

  const isExempt = form.watch('isExempt');

  const formattedTaxFormFields = taxFormFields.filter(field => field.name !== 'RATE');

  const rateTaxFieldIndex = taxFormFields.findIndex(field => field.name === 'RATE');

  const SubmitBtnHandler: SubmitHandler<FormType> = values => {
    onSubmit(values);
  };

  const formattedInfo = isFederalTaxItem ? (
    <p>
      The employer Federal Unemployment Tax Act (FUTA) rate applies to all businesses in the US. Not
      sure what your FUTA rate is?{' '}
      <a
        target="_blank"
        className="text-blue-500 underline"
        href="https://oui.doleta.gov/unemploy/uitaxtopic.asp#:~:text=Federal%20Tax%20Rate,times%20the%20employer's%20taxable%20wages."
        rel="noreferrer"
      >
        Click here
      </a>{' '}
      to learn how to calculate it.
    </p>
  ) : !info && taxItem.id.includes(SUTA_TAX_ITEM_ID) ? (
    <p>
      Your employer State Unemployment Insurance (SUI/SUTA) rate is assigned to your business when
      you register with your state tax agency. Not sure what your SUI rate is?{' '}
      <a
        target="_blank"
        className="text-blue-500 underline"
        href="https://oui.doleta.gov/unemploy/agencies.asp"
        rel="noreferrer"
      >
        Click here
      </a>{' '}
      to learn where to find it.
    </p>
  ) : info ? (
    <p dangerouslySetInnerHTML={{ __html: info.replace(/\n/g, '<br />') }} />
  ) : null;

  const formattedTitle = isFederalTaxItem
    ? "Let's set up your FUTA rate"
    : taxItem.id.includes(SUTA_TAX_ITEM_ID)
    ? `Set your SUI - ${description}`
    : description;

  return {
    taxFormFields,
    formattedTaxFormFields,
    rateTaxFieldIndex,
    formattedTitle,
    form,
    isExempt,
    SubmitBtnHandler,
    formattedInfo,
  };
};

export default useEmployerTaxItemForm;
