import { CreditCardIcon } from '@heroicons/react/24/outline';

import { SubscriptionAgreement } from '@/redux/dto/auth';

import { Button } from '@/components/ui/designSystem/Button/Button';
import SubscriptionPlan from '../../../UpgradeSubscriptionPlan/SubscriptionPlan/SubscriptionPlan';
import { SubscriptionPlanType } from '../../../UpgradeSubscriptionPlan/types';

type SubscriptionPlanGroupProps = {
  plans: SubscriptionPlanType[];
  selectedPlan: string | null;
  recommendedPlan: string | null;
  onSelectSubscriptionPlan: (plan: string) => void;
  onConfirmPlan: () => void;
  isPending: boolean;
  openCancelModalHandler: () => void;
  activeSubscriptionAgreement: SubscriptionAgreement;
  onCancel: () => void;
};

const SubscriptionPlanGroup = ({
  plans,
  selectedPlan,
  onSelectSubscriptionPlan,
  recommendedPlan,
  onConfirmPlan,
  isPending,
  openCancelModalHandler,
  activeSubscriptionAgreement,
  onCancel,
}: SubscriptionPlanGroupProps) => {
  return (
    <div className="flex h-full flex-col justify-center gap-5">
      <div className="flex flex-col items-center">
        <div className="rounded-full bg-emerald-50 p-2">
          <div className="rounded-[28px] bg-emerald-100 p-2">
            <CreditCardIcon className="size-6 rounded-[28px] text-emerald-500" />
          </div>
        </div>
        <span className="font-F37Bolton-Medium mt-4 text-[24px]">Manage your subscription</span>
        <span className="mt-2 w-[312px] text-center text-gray-400 md:w-[384px]">
          Take control of your plan. Upgrade, downgrade, or make changes to fit your needs.
        </span>
      </div>
      <div className="flex flex-col gap-3">
        {plans.map(plan => (
          <SubscriptionPlan
            key={plan.name}
            {...plan}
            isSelected={plan.name === selectedPlan}
            isRecommended={recommendedPlan === plan.name}
            amount={plan.price}
            planName={plan.name}
            maxEmployee={plan.metadata?.maxSeats ?? 0}
            pricingStrategy={'monthly'}
            onSelectSubscriptionPlan={() => !plan.isDisabled && onSelectSubscriptionPlan(plan.name)}
          />
        ))}
      </div>

      <div className="flex flex-col-reverse items-center justify-between gap-3 sm:flex-row">
        <div>
          {activeSubscriptionAgreement && (
            <Button
              variant="link"
              size="x-small"
              className="text-semantic-danger-red hover:text-semantic-danger-red-hover focus:text-semantic-danger-red-active"
              onPress={openCancelModalHandler}
            >
              Cancel subscription
            </Button>
          )}
        </div>
        <Button
          variant="primary"
          width="mobile-full"
          onPress={() => onConfirmPlan()}
          isLoading={isPending}
          className="sm:w-auto"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlanGroup;
