import {
  FastSpringEmbeddedPaymentProvider,
  SubscriptionBillingCard,
  SubscriptionReminderModal,
  UpgradePlanWithPaymentModal,
  UpgradeSubscriptionPlanModal,
} from '@/components';
import DialogComponent from '@/components/Dialog/DialogComponent';

import BillingHistory from '../BillingHistory/BillingHistory';
import { CancelSubscriptionReworked } from '../CancelSubscriptionModal/CancelSubscriptionReworked';
import SubscriptionPaymentMethodCard from '../SubscriptionPaymentMethodCard/SubscriptionPaymentMethodCard';
import { SubscriptionPlaceholder } from './components';
import useBillingCenter, { BillingCenterType } from './useBillingCenter';

const BillingCenter = ({ isManageBillingCenter }: BillingCenterType) => {
  const {
    subscriptionInformation,
    invoiceHistory,
    handleOpenCancelModal,
    isOpenedCancelModal,
    formattedSubscriptionData,
    closeReminderModalHandler,
    isSubscriptionReminderModalOpen,
    chooseSubscriptionPlanHandler,
    closeSubscriptionPlanHandler,
    isUpgradeSubscriptionPlanOpen,
    isUpgradePlanWithPaymentOpen,
    setIsUpgradePlanWithPaymentOpen,
    remainingDays,
    isFreeTrailOver,
    isSubscribedAnyPlan,
    discountCode,
  } = useBillingCenter({ isManageBillingCenter });

  return (
    <div className="max-w-[1130px] px-5 pb-5 md:px-8 md:pb-8">
      <div className="flex flex-col gap-5 md:flex-row">
        <div className="w-full">
          {formattedSubscriptionData ? (
            <SubscriptionBillingCard
              {...formattedSubscriptionData}
              onUpgradePlanClick={chooseSubscriptionPlanHandler}
              onRenewPlanClick={() => setIsUpgradePlanWithPaymentOpen(true)}
              discountFromStorage={discountCode}
            />
          ) : (
            <SubscriptionPlaceholder onChooseSubscriptionPlan={chooseSubscriptionPlanHandler} />
          )}
        </div>
        <div className="w-full">
          <FastSpringEmbeddedPaymentProvider>
            <SubscriptionPaymentMethodCard
              onChooseSubscriptionPlan={chooseSubscriptionPlanHandler}
            />
          </FastSpringEmbeddedPaymentProvider>
        </div>
      </div>

      <BillingHistory
        isSubscribedAnyPlan={isSubscribedAnyPlan}
        invoices={invoiceHistory}
        choosePlanHandler={chooseSubscriptionPlanHandler}
      />
      {subscriptionInformation?.activeSubscriptionAgreement?.id && isOpenedCancelModal && (
        <DialogComponent
          open={isOpenedCancelModal}
          onCancel={handleOpenCancelModal}
          children={<CancelSubscriptionReworked onClose={handleOpenCancelModal} />}
          width="w-[100vw] lg:w-[610px]"
          height="h-[100vh] lg:max-h-[800px]"
        />
      )}

      {isSubscriptionReminderModalOpen && (
        <SubscriptionReminderModal
          isOpen={isSubscriptionReminderModalOpen}
          onCancel={closeReminderModalHandler}
        />
      )}
      <UpgradeSubscriptionPlanModal
        isOpen={isUpgradeSubscriptionPlanOpen}
        onCancel={closeSubscriptionPlanHandler}
        handleOpenCancelModal={handleOpenCancelModal}
      />
      <UpgradePlanWithPaymentModal
        isOpen={isUpgradePlanWithPaymentOpen}
        onCancel={closeSubscriptionPlanHandler}
        remainingDays={remainingDays}
        isFreeTrailOver={isFreeTrailOver}
        discountCode={discountCode}
      />
    </div>
  );
};

export default BillingCenter;
