import { Header, LoaderComponent } from '@/components';

import { OnboardingScheduler, SelfOnboarding } from './components';
import useAccountSetup from './useAccountSetup';

const AccountSetup = () => {
  const { firstName, isLoading } = useAccountSetup();

  if (isLoading) {
    return (
      <div className="flex h-dvh items-center justify-center">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <div id="header" className="sticky top-0  z-10 w-full">
        <Header showHelp showMobileMenuIcon={false} showProfile={false} />
      </div>
      <div className="m-5 flex flex-col lg:m-0 lg:flex-row">
        <div className="lg:bg-primary-emerald-light-bg xl:w-full">
          <OnboardingScheduler firstName={firstName} />
        </div>
        <p className="text-content-body-medium flex justify-center py-6 text-base lg:hidden"> or</p>
        <SelfOnboarding />
      </div>
    </>
  );
};

export default AccountSetup;
