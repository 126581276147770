import { FC } from 'react';
import { tv } from 'tailwind-variants';

import { StatusProps } from './types';

export const statusVariants = tv({
  base: 'w-fit px-2 py-[3px] rounded text-xs leading-4',
  variants: {
    variant: {
      completed: 'bg-emerald-50 text-emerald-500',
      inviteSent: 'bg-blue-50 text-blue-500',
      primary: 'bg-blue-50 text-blue-500',
      inProgress: 'bg-yellow-100 text-yellow-600',
      deActivated: 'bg-red-50 text-red-600',
      default: 'bg-gray text-gray-900',
    },
  },
});

const Status: FC<StatusProps> = ({ variant, className, children }) => {
  return <div className={statusVariants({ variant, className })}>{children}</div>;
};

Status.displayName = 'Status';

export default Status;
