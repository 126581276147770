/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CreditCardIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAccountInfo, useBillingInfo } from '@/hooks';
import { SubscriptionInfo } from '@/redux/dto/auth';
import { Dispatch, RootState } from '@/redux/store';
import { AVAILABLE_SUBSCRIPTIONS, TRIAL_DAYS } from '@/utils/constants';
import { callToast, checkAccountForSubscriptionReminder } from '@/utils/helpers';

import StripeAndPaypal from '../StripeAndPaypal/StripeAndPaypal';
import { SubscriptionPlanType } from '../UpgradeSubscriptionPlan/types';

interface SubscriptionCardGroupProps {
  selectedPlan: string;
  plans: SubscriptionPlanType[];
  paymentSuccessHandler: (paymentData: { amount: number; planName: string }) => void;
  discount?: string;
}

const SubscriptionPaymentContainer: React.FC<SubscriptionCardGroupProps> = ({
  plans,
  selectedPlan,
  paymentSuccessHandler,
  discount,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const { account } = useAccountInfo();
  const { hasSubscriptions } = useBillingInfo();

  const email = useSelector((state: RootState) => state?.auth?.user?.account?.email);
  const userId = useSelector((state: RootState) => state?.auth?.user?.account?.id);
  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const { remainingDaysInFastSpring } = checkAccountForSubscriptionReminder({
    subscriptionInfo: subscriptionInformation,
    account,
  });

  const paymentMethods = subscriptionInformation?.paymentMethods;
  //  as per requirement we always render stripe and paypal payment method
  //  const shouldRenderStripeOrPayPal = paymentMethods?.some(payment => payment.type === 'fastspring');
  const shouldRenderStripeOrPayPal = true;

  const fastSpringCallBack = () => {
    if (window.fastspring) {
      const formattedSelectedPlan = selectedPlan ? selectedPlan : AVAILABLE_SUBSCRIPTIONS.starter;
      const session = {
        reset: true,
        products: plans.map(item => {
          const formattedRemainingDays = !hasSubscriptions
            ? TRIAL_DAYS
            : remainingDaysInFastSpring
            ? remainingDaysInFastSpring
            : 0;

          const formattedPathName = `${item.name}-t${formattedRemainingDays}`;
          return {
            path: formattedPathName,
            quantity: item.name === formattedSelectedPlan ? 1 : 0,
          };
        }),
        paymentContact: {
          email: email,
        },
        tags: {
          customerId: userId,
        },
        language: 'en',
        ...(discount && { coupon: discount }),
      };
      window.fastspring.builder.push(session);
    }
  };

  useEffect(() => {
    if (window.fastspring) {
      fastSpringCallBack();
    }
  }, [selectedPlan]);

  const addSBL = () => {
    const scriptId = 'fsc-api';
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      script(scriptId);
    }
  };

  useEffect(() => {
    addSBL();
    return () => {
      window.fastspring?.builder?.clean();
      document.getElementById('fsc-api')?.remove();
    };
  }, []);

  const dataPopupWebhookReceived = async (data: any) => {
    const paymentData = data?.items?.[0];
    if (!paymentData?.subscription) {
      callToast('error', 'Something went wrong, please try again later');
      return;
    }

    /**
     * On Successful Payment FastSpring calls the dataPopupWebhookReceived function the number of times the FastSpring script is loaded
     * To prevent that, we are saving saving the subscriptionSent flag in Window object
     */
    if (!window.subscriptionSent) {
      window.subscriptionSent = true;

      const paymentResponse: SubscriptionInfo = await dispatch.auth.verifyFsSubscription(
        paymentData?.subscription,
      );

      const subscriptionAgreements =
        Array.isArray(paymentResponse?.subscriptionAgreements) &&
        !!paymentResponse.subscriptionAgreements.length
          ? paymentResponse.subscriptionAgreements.reduce((acc, curr) =>
              curr.id > acc.id ? curr : acc,
            )
          : null;

      if (paymentResponse) {
        const formattedSubscriptionData = {
          amount: paymentData.subtotal,
          planName: paymentData.product,
          nextProcessingDate: subscriptionAgreements
            ? subscriptionAgreements.nextProcessingDate
            : null,
        };

        paymentSuccessHandler(formattedSubscriptionData);
      }
    }
  };

  const script = (scriptId: string) => {
    const fastSpringStorefront = import.meta.env.APP_FASTSPRING_STOREFRONT;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;
    script.src = 'https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js';
    script.dataset.storefront = fastSpringStorefront;
    window.callbackReceivePurchase = dataPopupWebhookReceived;
    script.setAttribute('data-popup-webhook-received', 'callbackReceivePurchase');
    script.onload = function () {
      fastSpringCallBack();
    };
    script.onerror = function (e) {
      callToast('error', 'Something went wrong, please contact support');
    };

    document.head.appendChild(script);
  };

  return (
    <div className="flex size-full overflow-hidden">
      {!shouldRenderStripeOrPayPal ? (
        <>
          <div id="fsc-embedded-checkout-container" className="relative min-h-[200px] w-full"></div>
        </>
      ) : (
        <div className="my-auto w-full lg:pl-7">
          <div className="flex size-full flex-col items-start lg:h-auto">
            <div className="flex w-full flex-col items-center">
              <div className="rounded-full bg-green-100 p-3">
                <CreditCardIcon className="size-6 text-green-500" aria-label="Credit Card Icon" />
              </div>

              <h3 className="font-F37Bolton-Medium text-2xl">Choose your payment method</h3>
              <p className="pt-2 text-gray-400">Flexible pricing that grows with you.</p>
            </div>

            <StripeAndPaypal
              selectedPlan={selectedPlan}
              classNames="min-h-fit"
              paymentSuccessHandler={paymentSuccessHandler}
              discount={discount}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPaymentContainer;
