import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { postCall } from '@/api/axios';
import { DiscountType } from '@/redux/dto/auth';
import { mutationEndpoints } from './endpoints';

type DiscountResponse = AxiosResponse<DiscountType>;
type PartialDiscountType = Partial<DiscountType>;

const usePostVerifyDiscount = ({
  mutationParams,
}: {
  mutationParams?: UseMutationOptions<DiscountResponse, Error, PartialDiscountType>;
}) => {
  const { mutationKey, url } = mutationEndpoints.verifyDiscount();

  return useMutation<DiscountResponse, Error, PartialDiscountType>({
    mutationKey,
    mutationFn: data => postCall(url, data),
    ...mutationParams,
  });
};

export default usePostVerifyDiscount;
