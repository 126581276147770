import {
  ForwardedRef,
  MutableRefObject,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { geocodeByAddress, geocodeByPlaceId } from 'react-google-places-autocomplete';
import { FieldError } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

import { CurrentStepContext } from '@/App';
import { extractEventName, keyUp } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

type stateLocation = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
};

interface GooglePlacesAutocompleteProps {
  defaultValue?: string;
  errorMessage?: FieldError | undefined;
  onPlaceSelect: (stateLocation: stateLocation | string) => void;
  name?: string;
}

const InputLocationSends = forwardRef(
  (
    { onPlaceSelect, defaultValue, errorMessage, ...rest }: GooglePlacesAutocompleteProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const location = useLocation();
    const [address, setAddress] = useState<string>(defaultValue ? defaultValue : '');
    const [isFocused, setIsFocused] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const { stepperInformation } = useContext(CurrentStepContext);

    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
      apiKey: import.meta.env.APP_GOOGLE_API_KEY,
      language: 'en',
    });
    useEffect(() => {
      setError(errorMessage?.message);
    }, [errorMessage]);

    useEffect(() => {
      if (defaultValue) {
        setAddress(defaultValue);
      }
    }, [defaultValue]);

    const handlePlaceSelect = async (placeId: string, address: string) => {
      try {
        const result = await geocodeByPlaceId(placeId);

        let streetAddress = result[0].formatted_address;
        let aptSteNumber = null;
        let city = '';
        let state = '';
        let zipCode = '';

        let localityFound = false;

        const fetchedDataHandler = (result: google.maps.GeocoderResult) => {
          for (let i = 0; i < result.address_components.length; i++) {
            const component = result.address_components[i];
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number':
                aptSteNumber = component.long_name || component.short_name;
                break;
              case 'route':
                if (result && result?.address_components) {
                  const firstComponent = result.address_components[0].long_name;
                  const secondComponent = component.long_name;
                  if (firstComponent === secondComponent) {
                    streetAddress = firstComponent;
                  } else {
                    streetAddress = `${firstComponent} ${secondComponent}`;
                  }
                }
                break;
              case 'locality':
                city = component.long_name;
                localityFound = true;
                break;
              case 'administrative_area_level_1':
                state = component.short_name;
                break;
              case 'postal_code':
                zipCode = component.long_name;
                break;
            }
          }

          if (!localityFound) {
            for (let i = 0; i < result.address_components.length; i++) {
              const component = result.address_components[i];
              const componentType = component.types[0];
              if (componentType === 'political') {
                city = component.long_name;
                break;
              }
            }
          }
        };

        if (Array.isArray(result) && result.length) {
          fetchedDataHandler(result[0]);
        }

        // If the street number is present in data fetched by the placeId then try to get data by address
        if (!aptSteNumber && address) {
          const resultByAddress = await geocodeByAddress(address);

          if (Array.isArray(resultByAddress) && resultByAddress.length) {
            streetAddress = resultByAddress[0].formatted_address;
            fetchedDataHandler(resultByAddress[0]);
          }
        }

        setAddress(streetAddress);

        if (aptSteNumber) {
          const addressInfo = {
            streetAddress,
            city,
            state,
            zipCode,
          };
          onPlaceSelect(addressInfo);
          setError(undefined);
        } else {
          setError('Select address with number');
        }
      } catch (error) {
        alert(error);
      }
    };
    const inputRef = useRef<HTMLInputElement>(null);

    // Use `useImperativeHandle` to expose the internal `inputRef` to the parent via `ref`
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleRef = (el: HTMLInputElement | null) => {
      inputRef.current = el;
      if (typeof ref === 'function') {
        ref(el); // Pass the ref to the parent component
      } else if (ref) {
        (ref as MutableRefObject<HTMLInputElement | null>).current = el;
      }
    };
    const componentRestrictions = { country: 'us' };
    return (
      <div className="relative">
        <input
          ref={handleRef}
          name="input_location"
          className={`h-[44px] w-full rounded-lg border ${
            error ? 'border-red-500' : 'border-gray-300'
          } pl-[10px] focus:outline-none`}
          value={address}
          placeholder="Street address (Ex. 4594 UnionSt...)"
          autoComplete="off"
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            trackEvent('inputChange', {
              name: 'location_input',
              currentPage: extractEventName(location.pathname),
              currentStep: stepperInformation.currentStep,
              previousStep: stepperInformation.previousStep,
              currentStepName: stepperInformation.currentStepName,
              previousStepName: stepperInformation.previousStepName,
            });
            setTimeout(() => {
              if (isFocused) {
                setIsFocused(false);
              }
            }, 200);
          }}
          onChange={evt => {
            getPlacePredictions({
              input: evt.target.value,
              componentRestrictions,
              language: 'en',
              types: ['address'],
            });
            setAddress(evt.target.value);
            onPlaceSelect(evt.target.value);
          }}
          {...rest}
        />
        {isFocused && !isPlacePredictionsLoading && (
          <ul className="absolute z-10 max-h-64 w-full overflow-y-auto rounded-md bg-white text-left shadow-md lg:w-full">
            {placePredictions.map(item => (
              <li
                onKeyUp={keyUp}
                tabIndex={0}
                role="menuitem"
                key={item.place_id}
                className="cursor-pointer px-4 py-2 hover:bg-gray-100 "
                onClick={event => {
                  event.preventDefault();
                  handlePlaceSelect(item.place_id, item.description);
                }}
              >
                <div className="font-F37Bolton-Medium  text-gray-900">{item.description}</div>
              </li>
            ))}
          </ul>
        )}
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    );
  },
);
export default InputLocationSends;
