import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCall } from '@/api/axios';
import { AppSettingType } from '@/redux/dto/auth';
import { queryEndpoints } from './endpoints';

type AppSettingResponse = AxiosResponse<AppSettingType>;

type UseGetAppSettingTypes = {
  rqParams?: Omit<UseQueryOptions<AppSettingResponse, Error, AppSettingResponse>, 'queryKey'>;
};

const useGetAppSettings = ({ rqParams }: UseGetAppSettingTypes = {}) => {
  const { queryKey, url } = queryEndpoints.getAppSetting();

  return useQuery<AppSettingResponse, Error>({
    queryKey,
    queryFn: () => getCall(url),
    gcTime: Infinity,
    // Invalidate App setting after every 60 minutes
    staleTime: 1000 * 60 * 60,
    ...rqParams,
  });
};
export default useGetAppSettings;
