import { Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface AccordionProps {
  title: ReactNode;
  value?: ReactNode;
  isLastItem?: boolean;
  children: ReactNode;
  isCollapsible?: boolean;
  accordionClasses?: string;
  titleVariant?: React.FunctionComponent<any>;
  titleProps?: any;
  accordionChildClasses?: string;
  accordionTitleClasses?: string;
}

const Accordion = ({
  title,
  value,
  children,
  isLastItem,
  isCollapsible = true,
  accordionClasses,
  titleVariant: TitleVariant,
  titleProps,
  accordionChildClasses,
  accordionTitleClasses,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => isCollapsible && setIsOpen(!isOpen);

  return (
    <div
      className={twMerge(
        `${!isLastItem ? 'border-b border-dashed border-gray-200' : ''}`,
        accordionClasses,
      )}
    >
      {TitleVariant ? (
        <TitleVariant
          title={title}
          isOpen={isOpen}
          toggleAccordion={toggleAccordion}
          titleProps={titleProps}
        />
      ) : (
        <div
          className="flex cursor-pointer items-center justify-between py-5"
          onClick={toggleAccordion}
        >
          <div className={accordionTitleClasses ?? ''}>{title || 'Title'}</div>

          <div className="flex items-center gap-x-[6px]">
            {value && <span className="text-sm text-gray-400">{value}</span>}
            {isCollapsible && (
              <ChevronUpIcon
                className={`size-5 transition-transform duration-300 ${
                  isOpen ? 'rotate-0' : 'rotate-180'
                }`}
              />
            )}
          </div>
        </div>
      )}

      <Transition
        show={isOpen}
        enter="transition-transform duration-300 ease-out"
        enterFrom="transform -translate-y-4 opacity-0"
        enterTo="transform translate-y-0 opacity-100"
        unmount={false}
      >
        <div className={twMerge('pb-4', accordionChildClasses)}>{children}</div>
      </Transition>
    </div>
  );
};

export default Accordion;
