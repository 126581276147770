import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';
import debounce from 'lodash.debounce';
import {
  MutableRefObject,
  ReactNode,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

type UseInputMaskTextFieldProps = {
  ref: Ref<HTMLInputElement>;
  isDisabled?: boolean;
  width?: string;
  errorMessage?:
    | ReactNode
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  name: string;
};

const useInputMaskTextField = ({
  ref,
  isDisabled,
  errorMessage,
  width,
  name,
}: UseInputMaskTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // Use `useImperativeHandle` to expose the internal `inputRef` to the parent via `ref`
  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const handleRef = (el: HTMLInputElement | null) => {
    inputRef.current = el;
    if (typeof ref === 'function') {
      ref(el); // Pass the ref to the parent component
    } else if (ref) {
      (ref as MutableRefObject<HTMLInputElement | null>).current = el;
    }
  };

  const handleInputChange = useCallback(
    debounce(() => {
      trackEvent('inputChange', {
        name,
        currentPage: extractEventName(location.pathname),
      });
    }, 700),
    [],
  );

  const labelClassName = `font-F37Bolton-Medium text-[16px] text-gray-900`;

  const inputClassName = useMemo(() => {
    const baseClass = `mt-[8px] h-[44px] rounded-[8px] placeholder-gray-300 focus:outline-none focus:ring-[1px] focus:ring-blue-500 w-full ${
      errorMessage
        ? 'border-red-500'
        : isDisabled
        ? 'border-gray-100 text-gray-100'
        : 'border-gray-300'
    }`;

    return baseClass;
  }, [errorMessage, width, isDisabled]);

  return {
    handleRef,
    handleInputChange,
    labelClassName,
    inputClassName,
  };
};

export default useInputMaskTextField;
