import { useNavigate } from 'react-router-dom';

import * as routes from '@/routes/routes';

const useSelfOnboarding = () => {
  const navigate = useNavigate();

  const handleSelfOnBoarding = () => {
    navigate(routes.ACCOUNT_SETUP_COMPANY);
  };
  return { handleSelfOnBoarding };
};
export default useSelfOnboarding;
