import { forwardRef } from 'react';
import {
  ComboBox,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Text,
  TextField,
} from 'react-aria-components';
import useAutoComplete, { AppAutoCompleteProps, AutoCompleteOptionsType } from './useAutoComplete';

const AutoComplete = forwardRef<HTMLInputElement, AppAutoCompleteProps>(
  ({ options, isRequired, title, errorMessage, onChange, ...rest }, ref) => {
    const { popoverWidth, filteredItems, setFilterValue, filterValue, inputRef } = useAutoComplete({
      options,
    });

    return (
      <div className="relative w-full" ref={ref}>
        <ComboBox
          allowsCustomValue
          items={filteredItems}
          inputValue={filterValue}
          onInputChange={value => {
            onChange && onChange(value);
            setFilterValue(value);
          }}
          {...rest}
        >
          <TextField className="relative flex flex-col">
            <Label className="text-medium font-F37Bolton-Medium pb-2">
              {title} {isRequired && <span className="text-red-500">*</span>}
            </Label>
          </TextField>
          <Input ref={inputRef} className="rounded-x-small w-full" /> {/* Attach ref here */}
          {errorMessage && (
            <Text
              slot="description"
              className={`mt-[8px] text-[14px] ${errorMessage ? 'text-red-500' : ''}`}
            >
              {errorMessage && errorMessage}
            </Text>
          )}
          <Popover
            className="rounded-x-small p-small absolute left-0 border bg-white"
            style={{ width: popoverWidth, top: '100%' }}
          >
            <ListBox className="w-full cursor-pointer">
              {(item: AutoCompleteOptionsType) => <ListBoxItem>{item.name}</ListBoxItem>}
            </ListBox>
          </Popover>
        </ComboBox>
      </div>
    );
  },
);

// Optionally set a display name for debugging
AutoComplete.displayName = 'AutoComplete';

export default AutoComplete;
