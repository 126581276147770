import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/redux/store';
import { FREE_3_MONTH_TRIAL_SUBSCRIPTION } from '@/utils/constants';
import { isArrayHasData } from '@/utils/helpers';

type useSubscriptionGlobalBannerProps = {
  remainingDays: number;
};

const useSubscriptionGlobalBanner = ({ remainingDays }: useSubscriptionGlobalBannerProps) => {
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [isUpgradeWithPaymentModalOpen, setIsUpgradeWithPaymentModalOpen] = useState(false);

  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);
  const activeSubscriptionAgreement = subscriptionInformation?.activeSubscriptionAgreement;
  const paymentMethods = subscriptionInformation?.paymentMethods;
  const subscriptionAgreements = subscriptionInformation?.subscriptionAgreements;
  const filteredSubscriptions = subscriptionAgreements.filter(
    item => item.plan !== FREE_3_MONTH_TRIAL_SUBSCRIPTION,
  );
  const isPaidPlanScheduledToBeCancelled = isArrayHasData(filteredSubscriptions)
    ? filteredSubscriptions.every(item => item.scheduledToBeCancelled)
    : false;

  const subscribePlanClickHandler = () => {
    const shouldRenderPayment =
      !activeSubscriptionAgreement ||
      activeSubscriptionAgreement?.status === 'canceled' ||
      !paymentMethods.some(method => method.status === 'default') ||
      isPaidPlanScheduledToBeCancelled ||
      activeSubscriptionAgreement?.scheduledToBeCancelled;

    if (shouldRenderPayment) {
      setIsUpgradeWithPaymentModalOpen(true);
    } else {
      setIsUpgradePlanModalOpen(true);
    }
  };

  const closeSubscriptionModalHandler = () => {
    setIsUpgradeWithPaymentModalOpen(false);
    setIsUpgradePlanModalOpen(false);
  };

  useEffect(() => {
    if (
      (remainingDays <= 1 &&
        activeSubscriptionAgreement?.plan === FREE_3_MONTH_TRIAL_SUBSCRIPTION) ||
      activeSubscriptionAgreement?.plan !== FREE_3_MONTH_TRIAL_SUBSCRIPTION
    ) {
      sessionStorage.setItem('discountCode', 'BLINK50');
    }
  }, [remainingDays, activeSubscriptionAgreement]);

  return {
    isUpgradePlanModalOpen,
    subscribePlanClickHandler,
    closeSubscriptionModalHandler,
    isUpgradeWithPaymentModalOpen,
  };
};

export default useSubscriptionGlobalBanner;
