import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountInfo, useBillingInfo } from '@/hooks';
import * as Routes from '@/routes/routes';
import {
  FEATURE_FLAGS,
  PAYWALL_POSITION_FEATURE_VALUES,
  customizationOptions,
} from '@/utils/constants';
import {
  checkAccountForAccountSetup,
  checkAccountForOnboardingCustomization,
  checkAccountForSubscriptionReminder,
} from '@/utils/helpers';
import { useFeatureValue } from '@growthbook/growthbook-react';

const useAccountSetup = () => {
  const navigate = useNavigate();
  const featureFlagValue = useFeatureValue(FEATURE_FLAGS.paywallPosition, '');

  const [shouldShowCustomizationModal, setShouldShowCustomizationModal] = useState(false);
  const [isSavingCustomization, setIsSavingCustomization] = useState(false);

  const { isLoading: isLoadingAuth0 } = useAuth0();
  const { account, patchAccount, isAccountLoading, company } = useAccountInfo();
  const { billingInfo, isBillingInfoLoading, hasSubscriptions } = useBillingInfo();

  const isLoading = isAccountLoading || isBillingInfoLoading || isLoadingAuth0;

  const { firstName = '', payrollManagement, createdAt } = account ?? {};

  const shouldShowPaywallBeforeSetup =
    featureFlagValue === PAYWALL_POSITION_FEATURE_VALUES.beforeAccountSetup;

  const bannerInfo = checkAccountForSubscriptionReminder({
    subscriptionInfo: billingInfo,
    account,
  });

  useEffect(() => {
    setShouldShowCustomizationModal(
      !payrollManagement && !!createdAt && checkAccountForOnboardingCustomization(createdAt),
    );
  }, [payrollManagement, createdAt]);

  const customizationModalCancelHandler = () => {
    setShouldShowCustomizationModal(false);
  };

  const onSaveCustomizationHandler = async (value: string | number | boolean | null) => {
    setIsSavingCustomization(true);
    try {
      await patchAccount({ payrollManagement: value });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSavingCustomization(false);
    }
  };

  const { shouldShowAccountSetup, isCompanyStepCompleted } = checkAccountForAccountSetup({
    account,
  });

  useEffect(() => {
    if (account && billingInfo) {
      const { phoneNumber, firstName, lastName } = account;
      const { name, businessRole } = company ?? {};

      const isPersonalDetailsCompleted = !!(
        firstName &&
        lastName &&
        phoneNumber &&
        name &&
        businessRole
      );

      const shouldRedirectToSignupBilling = shouldShowPaywallBeforeSetup && !hasSubscriptions;

      if (!isPersonalDetailsCompleted) {
        navigate(Routes.SIGNUP_STEPPER);
      } else if (shouldRedirectToSignupBilling) {
        navigate(Routes.SIGNUP_STEPPER_BILLING);
      } else if (!shouldShowAccountSetup || (shouldShowAccountSetup && isCompanyStepCompleted)) {
        navigate(Routes.DASHBOARD);
      }
    }
  }, [
    account,
    shouldShowAccountSetup,
    isCompanyStepCompleted,
    navigate,
    company,
    shouldShowPaywallBeforeSetup,
    hasSubscriptions,
    billingInfo,
  ]);

  const { bannerVariantToShow } = bannerInfo ?? {};

  return {
    shouldShowCustomizationModal,
    customizationModalCancelHandler,
    onSaveCustomizationHandler,
    customizationOptions,
    isSavingCustomization,
    firstName,
    bannerVariantToShow,
    isLoading,
  };
};

export default useAccountSetup;
