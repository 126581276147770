const features = [
  {
    title: 'Company Profile',
    description:
      'Provide basic company information like your EIN/FEIN from registration documents.',
  },
  {
    title: 'Payroll Settings',
    description:
      'Set your payroll schedule and define pay types (e.g.,overtime, tips, commission) and paid time-off',
  },
  {
    title: 'Taxes',
    description: 'Enter state unemployment insurance (SUI) and tax rates based on your location',
  },
  {
    title: 'Employees',
    description:
      'Add employee details, like hiring information and benefits, from their W-4 forms and most recent pay stub',
  },
];

const FeatureOverview = () => {
  return (
    <div className="flex flex-col gap-8">
      <h2 className="text-large mb-4 hidden text-xl font-semibold sm:flex">
        Here’s what you’ll need
      </h2>
      <div>
        {features.map((feature, index) => (
          <div key={index} className="flex gap-[13px]">
            <div className="flex w-fit flex-col items-center">
              <div className="bg-surface-dark text-2x-small flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-white">
                <p className="h-3.5 w-fit">{index + 1}</p>
              </div>
              {index < features.length - 1 && <div className="h-full w-1 border-l-2" />}
            </div>
            <div className="pb-3x-large">
              <h3 className="text-medium font-F37Bolton-Medium">{feature.title}</h3>
              <p className="text-content-body-medium  font-F37Bolton-Regular text-small max-w-[350px] pt-1">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureOverview;
