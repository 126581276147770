import { StatusProps } from '@/components/ui/Status/types';

export const PRICING_STRATEGY_TO_NAME_MAP = {
  monthly: 'month',
  yearly: 'year',
};

export const SUBSCRIPTION_STATUS_TO_STATUS_TYPE_MAP: { [key in string]: StatusProps['variant'] } = {
  active: 'completed',
  expired: 'deActivated',
  cancelled: 'deActivated',
  trialing: 'completed',
};
