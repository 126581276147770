import debounce from 'lodash.debounce';
import { ChangeEvent, forwardRef, useCallback, useMemo } from 'react';
import { Label, Text, TextArea as ReactAriaTextArea, TextField } from 'react-aria-components';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { InputTextAreaProps } from './types';

const InputTextArea = forwardRef<HTMLTextAreaElement, InputTextAreaProps>(
  (
    {
      title,
      subtitle,
      isRequired = false,
      isOptional = false,
      width,
      errorMessage,
      onChange,
      parentClasses,
      inputClasses,
      isShowDollarIcon = false,
      inputProps,
      disabled = false,
      customOnChange,
      endAdornment,
      maxCharacterCount,
      ...rest
    }: InputTextAreaProps,
    ref,
  ) => {
    const handleInputChange = useCallback(
      debounce(() => {
        trackEvent('inputChange', {
          name: rest.name,
          currentPage: extractEventName(window.location.pathname),
        });
      }, 700),
      [rest.name],
    );

    const valueChangeHandler = (value: ChangeEvent<HTMLTextAreaElement>) => {
      handleInputChange();
      onChange && onChange(value as any);
      customOnChange && customOnChange(value);
    };

    const labelClassName = `font-F37Bolton-Medium text-[16px] text-gray-900`;

    const inputClassName = useMemo(() => {
      let baseClass = `h-[44px] w-full rounded-[8px] placeholder-gray-300 focus:outline-none ${
        errorMessage
          ? 'border-red-500'
          : disabled
          ? 'border-gray-100 text-gray-100'
          : 'border-gray-300 focus:ring-[1px] focus:ring-blue-500'
      }`;

      if (title) baseClass += ' mt-[8px]';
      if (isShowDollarIcon) baseClass += ' pl-[25px]';
      if (inputClasses) baseClass += ` ${inputClasses}`;

      return baseClass;
    }, [errorMessage, isShowDollarIcon, disabled, inputClasses, title]);

    return (
      <TextField
        className={`relative flex flex-col ${width ?? 'w-full'} ${parentClasses ?? ''}`}
        {...rest}
        onChange={value => {
          valueChangeHandler(value);
        }}
      >
        <Label className={labelClassName}>
          {title} {isRequired && <span className="text-red-500">*</span>}{' '}
          {isOptional && <span className="text-gray-300">(optional)</span>}
        </Label>
        <div className="relative">
          <ReactAriaTextArea
            className={inputClassName}
            ref={ref}
            disabled={disabled}
            onChange={valueChangeHandler}
            {...inputProps}
          />
          {endAdornment && endAdornment}
        </div>

        {isShowDollarIcon && (
          <span className={`absolute left-[10px] top-[42px] ${disabled ? 'text-gray-100' : ''}`}>
            $
          </span>
        )}
        {maxCharacterCount && (
          <Text slot="description" className="text-right text-[14px] text-gray-400">
            {typeof rest.value === 'string' ? rest.value?.length : 0}/{maxCharacterCount}
          </Text>
        )}
        {(errorMessage || subtitle) && (
          <Text
            slot="description"
            className={`mt-[8px] text-[14px] ${errorMessage ? 'text-red-500' : 'text-gray-400'}`}
          >
            {errorMessage ? errorMessage : subtitle}
          </Text>
        )}
      </TextField>
    );
  },
);
InputTextArea.displayName = 'InputTextArea';
export default InputTextArea;
