import React, { lazy, Suspense } from 'react';
const Lottie = lazy(() => import('lottie-react'));

import loaderPage from '@/assets/lottie/loader.json';

interface LoaderProps {
  height?: number;
  classNames?: string;
}

const LoaderComponent: React.FC<LoaderProps> = ({ height = 50, classNames = '' }) => {
  return (
    <div className={`items-center justify-center ${classNames}`}>
      <Suspense fallback={<div />}>
        <Lottie animationData={loaderPage} style={{ height }} loop={true} />
      </Suspense>
    </div>
  );
};

export default LoaderComponent;
