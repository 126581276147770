import { useAccountInfo } from '@/hooks';

const useOnboardingSchedulerDesktop = () => {
  const { account } = useAccountInfo();
  const { lastName, email, firstName } = account || {};
  const fullName = firstName + ' ' + lastName;
  return {
    fullName,
    email,
  };
};
export default useOnboardingSchedulerDesktop;
