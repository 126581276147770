import { CheckIcon } from '@heroicons/react/20/solid';
import { ForwardedRef, forwardRef } from 'react';
import { Tab, TabList } from 'react-tabs';
import { twMerge } from 'tailwind-merge';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { TabTitleProps, TabType } from './types';

const TabTitles = forwardRef(
  (
    {
      currentTab,
      variant,
      tabs,
      setCurrentTab,
      currentTabIndex,
      backwardNavigateOnTitleClick,
      forwardNavigateOnTitleClick,
      tabTitlesComponent: TabTitlesComponent,
      tabTitlesComponentProps,
    }: TabTitleProps,
    tabListContainerRef: ForwardedRef<HTMLDivElement>,
  ) => {
    const visibleTabs = tabs.filter(item => !item.hidden);

    const visibleCurrentTabIndex = visibleTabs.findIndex(item => item.id === currentTab);

    const progressPercentage = ((visibleCurrentTabIndex + 1) / visibleTabs.length) * 90 || 0;

    const handleTabClick = (tab: TabType, tabIndex: number) => {
      if (tab.disabled) return; // Early return if tab is disabled

      trackEvent('tabChange', {
        currentTabName: tabs[currentTabIndex]?.title,
        currentPage: extractEventName(window.location.pathname),
      });

      const shouldNavigateBackward = currentTabIndex > tabIndex && backwardNavigateOnTitleClick;
      const shouldNavigateForward = currentTabIndex < tabIndex && forwardNavigateOnTitleClick;

      if (shouldNavigateBackward || shouldNavigateForward) {
        setCurrentTab(tab.id);
      }
    };

    if (TabTitlesComponent) {
      return (
        <TabTitlesComponent
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          currentTabIndex={currentTabIndex}
          backwardNavigateOnTitleClick={backwardNavigateOnTitleClick}
          forwardNavigateOnTitleClick={forwardNavigateOnTitleClick}
          handleTabClick={handleTabClick}
          tabTitlesComponentProps={tabTitlesComponentProps}
        />
      );
    }

    if (variant === 'progressBar') {
      return (
        <div>
          <div ref={tabListContainerRef} className="scrollbar-hide overflow-auto">
            <TabList className="flex">
              {tabs.map((tab, tabIndex) => (
                <Tab
                  role="tab"
                  key={tab.id}
                  onClick={() => {
                    handleTabClick(tab, tabIndex);
                  }}
                  className={twMerge(
                    `mx-4 flex cursor-pointer justify-start whitespace-nowrap focus:outline-none`,
                    currentTabIndex === tabIndex ? 'text-gray-600' : 'text-gray-200',
                    !!tab.hidden && 'hidden',
                  )}
                  id={tab.id}
                >
                  {tab.title}
                </Tab>
              ))}
            </TabList>
          </div>
          <div className="relative mt-[16px] h-[8px] w-full rounded-[8px] bg-gray-50">
            <div
              className={`absolute h-[8px] rounded-[8px] bg-emerald-400 transition-all duration-700 ease-in-out`}
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
        </div>
      );
    }

    return (
      <div aria-label="Progress" className="flex w-full">
        <TabList className="flex w-full items-center justify-center">
          {tabs.map((tab, tabIndex) => (
            <Tab
              key={tab.id}
              className={twMerge(
                tabIndex !== tabs.length - 1 ? `w-full max-w-[65px] grow` : '',
                'relative',
                !!tab.hidden && 'hidden',
              )}
            >
              {tabIndex < currentTabIndex ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-emerald-500" />
                  </div>
                  <div className="relative flex size-6 items-center justify-center rounded-full bg-emerald-500">
                    <CheckIcon className="size-[14px] text-white" aria-hidden="true" />
                    <span className="sr-only">{tab.title}</span>
                  </div>
                </>
              ) : tabIndex > currentTabIndex ? (
                <>
                  <div className="relative flex size-6 items-center justify-center rounded-full bg-gray-50">
                    <span className="sr-only">{tab.title}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="relative flex size-6 items-center justify-center rounded-full border-2 border-dashed border-emerald-500 bg-white ">
                    <span className="sr-only">{tab.title}</span>
                  </div>
                </>
              )}
            </Tab>
          ))}
        </TabList>
      </div>
    );
  },
);

TabTitles.displayName = 'TabTitles';

export default TabTitles;
