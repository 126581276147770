import { Button } from '@/components/ui/designSystem/Button/Button';

type SubscriptionPlaceholderProps = {
  onChooseSubscriptionPlan: () => void;
};

const SubscriptionPlaceholder = ({ onChooseSubscriptionPlan }: SubscriptionPlaceholderProps) => {
  return (
    <div className="p-medium gap-y-x-large rounded-x-small flex flex-col items-center border">
      <div className="gap-y-x-small flex flex-col items-center text-center">
        <h4>You do not have any active plans</h4>
        <p className="text-small text-center text-gray-400">
          To run payroll for your business, subscribe to a plan that suits your need
        </p>
      </div>
      <Button onPress={onChooseSubscriptionPlan}>Subscribe</Button>
    </div>
  );
};

export default SubscriptionPlaceholder;
