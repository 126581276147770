import Button from '@/components/Button/Button';
import useImpersonateAccountBanner from './useImpersonateAccountBanner';

const ImpersonateAccountBanner = () => {
  const { stopImpersonationHandler } = useImpersonateAccountBanner();
  return (
    <div className="flex h-[60px] items-center justify-between bg-emerald-100 px-4">
      <div className="font-F37Bolton-Medium text-sm sm:text-xl">Impersonation Mode</div>
      <Button
        variant="primary"
        parentClasses="px-3 text-sm"
        height="h-9"
        width="w-auto"
        onPress={stopImpersonationHandler}
      >
        Stop Impersonation
      </Button>
    </div>
  );
};

export default ImpersonateAccountBanner;
