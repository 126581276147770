// import url from 'utils/url';
import axios from '../axios';

export interface Address {
  streetAddress: string;
  aptSteNumber: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface CompanyInformation {
  name: string;
  address: Address;
  numberOfEmployees: number;
  numberOfContractors: number;
  ein: string;
  businessType: number;
  chooseEinOrSsn: number;
}

export interface Field {
  name: string;
  value: string;
}

export interface TaxItem {
  id: string;
  isExempt: boolean;
  fields: Field[];
}

export interface TaxItems {
  taxItems: TaxItem[];
}

// Get current user companies

export const getCurrentUserCompanies = () => {
  return axios.get(`/companies`);
};

//  Updates the Company resource.
export const putUpdateCompanyResourse = (companyId: string, payload: CompanyInformation) => {
  return axios.patch(`/company/${companyId}`, payload);
};

// Get the tax information for a specific company grouped by state
export const getTaxInformationCompanyByid = (companyId: string) => {
  return axios.get(`/company/${companyId}/taxInformation`);
};

// Update the tax items
export const pathUpdateCompanyTaxInformation = (companyId: string, payload: TaxItems) => {
  return axios.patch(`/company/${companyId}/taxInformation`, payload);
};

// Get the missing tax information grouped by state
export const getMissingTaxInformationGroupedByState = (companyId: string) => {
  return axios.get(`/company/${companyId}/taxInformation/missing`);
};

// Check if account can run a payroll

export const getInformationForRunPayroll = (companyId: string) => {
  return axios.get(`/company/${companyId}/payroll-info`);
};
