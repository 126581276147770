import { FC } from 'react';
import { MenuTrigger, Popover, Menu as ReactAriaMenu } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';

import MenuItem from './MenuItem';
import { AppMenuProps } from './types';

const Menu: FC<AppMenuProps<any>> = ({
  menuButtonVariant = 'primary',
  menuButtonLabel,
  menuButtonIcon,
  menuItemsList = [],
  onAction,
  menuWrapperClasses,
  menuButtonClasses,
  ...rest
}) => {
  return (
    <MenuTrigger {...rest}>
      <Button parentClasses={menuButtonClasses} variant={menuButtonVariant}>
        {menuButtonLabel}
        {menuButtonIcon && menuButtonIcon}
      </Button>
      <Popover
        className={twMerge(
          `min-w-[320px] rounded-lg border border-gray-100 bg-white p-2 shadow-[4px_4px_20px_0_rgba(0,0,0,0.07)]`,
          `${menuWrapperClasses ?? ''}`,
        )}
      >
        <ReactAriaMenu onAction={onAction} className="focus-visible:outline-none">
          {menuItemsList.length &&
            menuItemsList.map(items => (
              <MenuItem
                key={items.key}
                id={items.key}
                className={`active:bg-gray hover:bg-gray flex  items-center gap-2 rounded-xl p-3 font-medium hover:outline-none ${items.className} cursor-pointer`}
                startIcon={items.startIcon}
              >
                {items.label}
              </MenuItem>
            ))}
        </ReactAriaMenu>
      </Popover>
    </MenuTrigger>
  );
};

export default Menu;
