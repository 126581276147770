/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button, InputDropZone } from '@/components';
import DocumentCard from './DocumentCard';

interface FormData {
  question: string;
  files: File[];
}

const schema = yup.object().shape({
  question: yup.string().required('Question is required'),
  documents: yup.array().of(yup.mixed().notRequired()),
});

const ContactForm: React.FC = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      documents: [],
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'documents',
  });

  const onSubmit = () => {
    console.log('Not implemented');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-4">
        <label className="font-F37Bolton-Medium text-[24px] text-gray-900" htmlFor="question">
          Send us a message
        </label>
        <div className="mt-[30px]">
          <span className="font-F37Bolton-Medium text-gray-900">Your question</span>
          <textarea
            {...register('question')}
            id="question"
            placeholder="(e.g. “Need help with contactors”)"
            className="mt-[8px] w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 leading-tight text-gray-700 shadow focus:border-gray-300 focus:ring-0"
          ></textarea>
        </div>
        {errors.question && <p className="mt-2 text-sm text-red-500">{errors.question.message}</p>}
      </div>

      <div className="">
        <label className="mb-2 block font-F37Bolton-Medium">Photo / files</label>
        {fields.map((document, index) => (
          <DocumentCard key={index} fileObject={document} onClose={document => remove(document)} />
        ))}
        <InputDropZone preview={false} onChange={file => append(file)} />
      </div>

      <div className="mt-[100px] lg:mt-[30px]">
        <Button type="primary" children="Send" width="w-full lg:w-[220px]" />
      </div>
    </form>
  );
};

export default ContactForm;
