import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

import { Account } from '@/redux/dto/auth';
import { whiteListErrorsSentry } from './constants';

const GTM_ID = import.meta.env.APP_GTM_ID;
const COBROWSE_LICENSE = import.meta.env.APP_COBROWSE_LICENSE;

const sentryBeforeSend = (event: Sentry.Event, hint: Sentry.EventHint) => {
  const expectedCoBrowseErrorResponse = { success: false, status: 401 };
  const { originalException } = hint;
  const { exception } = event;
  const stackFrames = exception?.values?.[0]?.stacktrace?.frames;
  const errorValue = exception?.values?.[0]?.value;

  // Ignore expected CoBrowse response error
  if (
    JSON.stringify(originalException) === JSON.stringify(expectedCoBrowseErrorResponse) &&
    errorValue === 'Object captured as promise rejection with keys: data, status, success'
  ) {
    return null;
  }

  // Ignore network errors with "Failed to fetch" and "loadSeloUrls" or originating from "nivaai.com"
  const isNetworkError =
    errorValue === 'Failed to fetch' &&
    (stackFrames?.some(
      frame => frame.function === 'loadSeloUrls' || frame.filename?.includes('nivaai.com'),
    ) ||
      originalException?.stack?.includes('loadSeloUrls'));

  if (isNetworkError) {
    return null;
  }

  return event;
};

export const initializeAnalytics = () => {
  FullStory.init({ orgId: import.meta.env.APP_FULLSTORY_ORG, namespace: 'FullStory' });

  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: import.meta.env.APP_DNS_SENTRY,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(),
        new SentryFullStory(import.meta.env.APP_SENTRY_ORG_SLUG, { client: FullStory }),
      ],
      tracesSampleRate: 0.1, // Performance Monitoring rate, set to 10% in production
      release: import.meta.env.APP_VERSION,
      environment: import.meta.env.APP_MODE,
      replaysSessionSampleRate: 0.1, // Sample 10% of sessions for Replay
      replaysOnErrorSampleRate: 1.0, // Sample 100% of sessions with errors
      ignoreErrors: whiteListErrorsSentry,
      beforeSend: sentryBeforeSend,
    });
  }

  if (GTM_ID) {
    TagManager.initialize({ gtmId: GTM_ID });
  }
};

export const initializeCobrowse = () => {
  if (COBROWSE_LICENSE) {
    // Dynamically import CobrowseIO when needed
    import('cobrowse-sdk-js')
      .then(CobrowseIO => {
        CobrowseIO.default.license = COBROWSE_LICENSE; // Assign license
        CobrowseIO.default.start(); // Start the Cobrowse session
      })
      .catch(err => {
        console.error('Failed to load CobrowseIO', err);
      });
  }
};

export const setCobrowseUserData = (account: Account) => {
  // Lazy load CobrowseIO dynamically
  import('cobrowse-sdk-js')
    .then(CobrowseIO => {
      CobrowseIO.default.customData = {
        user_id: account?.id,
        user_name: `${account?.firstName} ${account?.lastName}`,
        user_email: account?.email,
      };
    })
    .catch(err => {
      console.error('Failed to load CobrowseIO', err);
    });
};
