import * as Sentry from '@sentry/react';
import debounce from 'lodash.debounce';
import { ReactNode, createContext, forwardRef, useCallback, useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '@/redux/store';
import { extractEventName } from '@/utils/helpers';

export const WithGTMInput = (Component: any) => {
  const AnalyticWrapper = ({ forwardedRef, ...props }) => {
    const location = useLocation();
    const { stepperInformation } = useContext(CurrentStepContext);
    const uuid = useSelector((state: RootState) => state?.auth?.user?.account?.uuid);
    const { onChange, name, ...rest } = props;

    const triggerOutsideChange = useCallback(
      (event, ...args) => {
        if (onChange) {
          onChange(event, ...args);
        }
      },
      [onChange],
    );

    const pushGTMData = useCallback(
      debounce(() => {
        try {
          if (name) {
            return TagManager.dataLayer({
              dataLayer: {
                event: 'inputChange',
                name: name,
                currentPage: extractEventName(location.pathname),
                currentStep: stepperInformation.currentStep,
                previousStep: stepperInformation.previousStep,
                currentStepName: stepperInformation.currentStepName,
                previousStepName: stepperInformation.previousStepName,
                userId: uuid,
              },
            });
          }
          if (!name && props.register) {
            return TagManager.dataLayer({
              dataLayer: {
                event: 'inputChange',
                name: props.register?.name,
                currentPage: extractEventName(location.pathname),
                currentStep: stepperInformation.currentStep,
                previousStep: stepperInformation.previousStep,
                currentStepName: stepperInformation.currentStepName,
                previousStepName: stepperInformation.previousStepName,
                userId: uuid,
              },
            });
          }
          if (!name && !props.register && props.label) {
            return TagManager.dataLayer({
              dataLayer: {
                event: 'inputChange',
                name: props.label,
                currentPage: extractEventName(location.pathname),
                currentStep: stepperInformation.currentStep,
                previousStep: stepperInformation.previousStep,
                currentStepName: stepperInformation.currentStepName,
                previousStepName: stepperInformation.previousStepName,
                userId: uuid,
              },
            });
          }
          if (!name && !props.register && !props.label && props.title) {
            return TagManager.dataLayer({
              dataLayer: {
                event: 'inputChange',
                name: props.title,
                currentPage: extractEventName(location.pathname),
                currentStep: stepperInformation.currentStep,
                previousStep: stepperInformation.previousStep,
                currentStepName: stepperInformation.currentStepName,
                previousStepName: stepperInformation.previousStepName,
                userId: uuid,
              },
            });
          }
          if (
            !name &&
            !props.register &&
            !props.label &&
            !props.title &&
            props.field &&
            props.field.name
          ) {
            return TagManager.dataLayer({
              dataLayer: {
                event: 'inputChange',
                name: props.field.name,
                currentPage: extractEventName(location.pathname),
                currentStep: stepperInformation.currentStep,
                previousStep: stepperInformation.previousStep,
                currentStepName: stepperInformation.currentStepName,
                previousStepName: stepperInformation.previousStepName,
                userId: uuid,
              },
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          console.log('Input tracked with error. Check sentry for more information');
          console.log(error);
          return TagManager.dataLayer({
            dataLayer: {
              event: 'inputChange',
              name: 'event is tracked, but the name of the input is unknown',
              currentPage: extractEventName(location.pathname),
              currentStep: stepperInformation.currentStep,
              previousStep: stepperInformation.previousStep,
              currentStepName: stepperInformation.currentStepName,
              previousStepName: stepperInformation.previousStepName,
              userId: uuid,
            },
          });
        }
      }, 700),
      [],
    );

    const handleChange = useCallback((event, ...args) => {
      triggerOutsideChange(event, ...args);
      pushGTMData(event, ...args);
    }, []);

    return <Component onChange={handleChange} ref={forwardedRef} {...rest} />;
  };

  return forwardRef((props, ref) => {
    return <AnalyticWrapper forwardedRef={ref} {...props} />;
  });
};

export const WithGTMButton = Component => {
  const AnalyticWrapper = props => {
    try {
      const { onClick, benefitType, ...rest } = props;
      const { stepperInformation } = useContext(CurrentStepContext);
      const uuid = useSelector((state: RootState) => state?.auth?.user?.account?.uuid);
      const handleClick = useCallback(
        (event, ...args) => {
          TagManager.dataLayer({
            dataLayer: {
              event: 'buttonClick',
              text: props.children ?? props.type,
              currentPage: extractEventName(location.pathname),
              currentStep: stepperInformation.currentStep,
              previousStep: stepperInformation.previousStep,
              currentStepName: stepperInformation.currentStepName,
              previousStepName: stepperInformation.previousStepName,
              userId: uuid,
              benefitType: benefitType ?? null,
            },
          });
          if (onClick) {
            onClick(event, ...args);
          }
        },
        [onClick],
      );

      return <Component onClick={handleClick} {...rest} />;
    } catch (error) {
      console.log(error);
    }
  };

  return forwardRef((props, ref) => {
    return <AnalyticWrapper ref={ref} {...props} />;
  });
};

type StepperInformationType = {
  currentStep: number | null;
  previousStep: number | null;
  currentStepName: string | null;
  previousStepName: string | null;
};

type contextType = {
  stepperInformation: StepperInformationType;
  setStepperInformation: React.Dispatch<React.SetStateAction<StepperInformationType>>;
};

const defaultStepperInformation: StepperInformationType = {
  currentStep: null,
  previousStep: null,
  currentStepName: null,
  previousStepName: null,
};

// Create the context with default values
export const CurrentStepContext = createContext<contextType>({
  stepperInformation: defaultStepperInformation,
  setStepperInformation: () => {}, // Default noop function
});

export const CurrentStepProvider = ({ children }: { children: ReactNode }) => {
  const [stepperInformation, setStepperInformation] =
    useState<StepperInformationType>(defaultStepperInformation);

  const value = { stepperInformation, setStepperInformation };

  return <CurrentStepContext.Provider value={value}>{children}</CurrentStepContext.Provider>;
};
