import CurrencyInput from 'react-currency-input-field';

import { WithGTMInput } from '../../HOCs/Analytics';

type Props = {
  type: 'hours' | 'invoice' | 'amount' | 'text';
  register?: () => void;
  placeholder?: string;
  onChange?: () => void;
  defaultValue?: string | number;
  allowNegativeValue?: boolean;
  inputClasses?: string;
};

const InputSymbolWithoutGTM = ({
  register,
  onChange,
  type,
  placeholder = '0',
  defaultValue,
  allowNegativeValue = false,
  name,
  inputClasses,
}: Props) => {
  if (type === 'amount') {
    return (
      <div className="flex size-full items-center">
        <label className="relative flex size-full items-center">
          <CurrencyInput
            allowNegativeValue={allowNegativeValue}
            decimalsLimit={2}
            defaultValue={defaultValue}
            maxLength={10}
            placeholder={placeholder}
            onValueChange={onChange}
            step={0.01}
            onWheel={event => event.currentTarget.blur()}
            min={0}
            className={`${inputClasses} size-full border-none pl-[30px] placeholder:text-gray-300 focus:ring-gray-900`}
            decimalSeparator="."
            groupSeparator=","
            name={name}
          />
          <span
            className={`${
              defaultValue ? 'text-gray-900' : 'text-gray-300'
            } absolute left-[10px] top-[11.5px]`}
          >
            $
          </span>
        </label>
      </div>
    );
  }
  if (type === 'text') {
    return (
      <div className="flex size-full items-center">
        <label className=" flex size-full items-center">
          <input
            type="text"
            min="0"
            placeholder={placeholder}
            className={`${inputClasses} size-full border-none pl-[15px] placeholder:text-gray-300 focus:ring-gray-900`}
            {...register}
          />
        </label>
      </div>
    );
  }
  if (type === 'invoice') {
    return (
      <div className="flex size-full items-center">
        <label className="ml-[16px] flex size-full items-center">
          #
          <input
            type="text"
            placeholder={placeholder}
            className="size-full border-none pl-[15px] focus:ring-0"
            onChange={onChange}
            {...register}
          />
        </label>
      </div>
    );
  }
  if (type === 'hours') {
    return (
      <div className="flex size-full items-center">
        <label className="ml-[16px] flex size-full items-center">
          Hr
          <input
            type="number"
            min="0"
            placeholder={placeholder}
            step={0.01}
            className="size-full border-none pl-[8px] focus:ring-0"
            {...register}
          />
        </label>
      </div>
    );
  }
  return null;
};

const InputSymbol = WithGTMInput(InputSymbolWithoutGTM);
export default InputSymbol;
