import { ReactComponent as BanknotesIcon } from '@/assets/banknotesGreen.svg';
import { ReactComponent as DocumentsIcon } from '@/assets/clipboard-document-check.svg';
import { ReactComponent as IdentificationIcon } from '@/assets/identification.svg';
import { ReactComponent as WomanIcon } from '@/assets/illustration/woman.svg';
import { LoaderComponent } from '@/components';
import Button from '@/components/Button/Button';
import { BRAND_INFO, SUPPORT_CONTACT_INFO } from '@/utils/constants';
import {
  CallToActionProps,
  ConditionalMessagesProps,
  IconTextBlockProps,
  InfoBlockProps,
  LastTabContentType,
} from '../../types';

const IconTextBlock = ({ icon: Icon, text }: IconTextBlockProps) => (
  <div className="flex h-[96px] w-[106px] flex-col items-center justify-center bg-[#FAF9F8] px-[14px] text-center">
    <Icon />
    <span className="font-F37Bolton-Medium mt-[8px] block">{text}</span>
  </div>
);

const InfoBlock = ({ children }: InfoBlockProps) => (
  <span className="mt-[12px] block text-center text-gray-400">{children}</span>
);

const CallToAction = ({ link, text, onClick }: CallToActionProps) => (
  <a
    href={link}
    className="mt-[32px] flex h-[48px] w-[335px] items-center justify-center rounded-[8px] bg-emerald-500 text-white"
    onClick={onClick}
  >
    {text}
  </a>
);

export const LastTabContent = ({
  reason,
  cancelObject,
  handleCancelSubscription,
  handleScheduleCall,
  handleCallNow,
  isLoading,
}: LastTabContentType) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex max-w-[310px] flex-col items-center">
        {reason !== '1' ? (
          reason !== '4' && <img src={SUPPORT_CONTACT_INFO.imgUrl} className="size-[100px]" />
        ) : (
          <div className="flex flex-col items-center justify-center">
            <WomanIcon />
            <span className="font-F37Bolton-Medium mt-[24px] block text-[24px]">
              Coming Soon...
            </span>
            <div className="mt-[24px] flex gap-x-[8px]">
              <IconTextBlock icon={BanknotesIcon} text="Direct Deposit" />
              <IconTextBlock icon={IdentificationIcon} text="Employee Portal" />
              <IconTextBlock icon={DocumentsIcon} text="Tax Reports" />
            </div>
            <InfoBlock>
              You can request a free trial extension until those features are ready!
            </InfoBlock>
          </div>
        )}
        <ConditionalMessages reason={reason} cancelObject={cancelObject} />
        <ConditionalActions
          reason={reason}
          cancelObject={cancelObject}
          handleScheduleCall={handleScheduleCall}
          handleCallNow={handleCallNow}
          handleCancelSubscription={handleCancelSubscription}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

const ConditionalMessages = ({ reason, cancelObject }: ConditionalMessagesProps) => (
  <>
    {reason === '2' && (
      <>
        <span className="font-F37Bolton-Medium mt-[24px] block text-center text-[24px]">
          Let’s solve This Together!
        </span>
        <InfoBlock>
          Book a call with Jayson, our onboarding specialist, and we guarantee he will resolve your
          issue.
        </InfoBlock>
      </>
    )}
    {reason === '3' && (
      <>
        <span className="font-F37Bolton-Medium mt-[24px] block text-[24px]">
          Let's Talk About Pricing!
        </span>
        <InfoBlock>
          Get on a call with our team, and we’ll do our best to match your price expectations.
        </InfoBlock>
      </>
    )}
    {reason === '4' && (
      <>
        {cancelObject?.lookingFor === 1 || cancelObject?.lookingFor === 'other' ? (
          <>
            <span className="font-F37Bolton-Medium mt-[24px] block text-center text-[24px]">
              Every problem has a solution!
            </span>
            <InfoBlock>
              Schedule a call with our payroll specialist to get you running payroll in no time.
            </InfoBlock>
          </>
        ) : cancelObject?.lookingFor === 4 ? (
          <>
            <span className="font-F37Bolton-Medium mt-[24px] block text-center text-[24px]">
              As a business owner, you need a payroll software!
            </span>
            <InfoBlock>
              Book a{' '}
              <span className="font-F37Bolton-Medium">free call with our payroll expert</span> to
              learn how {BRAND_INFO.name} keeps your business compliant.
            </InfoBlock>
          </>
        ) : (
          (cancelObject?.lookingFor === 2 || cancelObject?.lookingFor === 3) && (
            <>
              <span className="font-F37Bolton-Medium mt-[24px] block text-center text-[24px]">
                Looking for one-time documents?
              </span>
              <InfoBlock>
                Check out our partners at ThePayStubs to generate pay stubs and tax forms instantly.
              </InfoBlock>
            </>
          )
        )}
      </>
    )}
    {reason === 'other' && (
      <>
        <span className="font-F37Bolton-Medium mt-[24px] block text-center text-[24px]">
          Every problem has a solution!
        </span>
        <InfoBlock>
          Schedule a call with our payroll specialist to get you running payroll in no time.
        </InfoBlock>
      </>
    )}
  </>
);

const ConditionalActions = ({
  reason,
  cancelObject,
  handleScheduleCall,
  handleCallNow,
  handleCancelSubscription,
  isLoading,
}: LastTabContentType) => (
  <>
    {reason === '1' && (
      <>
        <CallToAction
          link="tel:+18887781009"
          text="Call now, request free trial extension"
          onClick={handleCallNow}
        />
        <Button
          variant="tertiary"
          children="Schedule a call"
          parentClasses="mt-[12px]"
          onPress={handleScheduleCall}
        />
        {isLoading ? (
          <LoaderComponent classNames="mt-[12px]" />
        ) : (
          <Button
            variant="tertiary"
            children="Cancel subscription"
            parentClasses="mt-[12px]"
            onPress={handleCancelSubscription}
          />
        )}
      </>
    )}
    {reason === '2' && (
      <>
        <Button
          variant="primary"
          children="Schedule a call with Jayson"
          parentClasses="mt-[32px]"
          onPress={handleScheduleCall}
        />
        {isLoading ? (
          <LoaderComponent classNames="mt-[12px]" />
        ) : (
          <Button
            variant="tertiary"
            children="Cancel subscription"
            parentClasses="mt-[12px]"
            onPress={handleCancelSubscription}
            isLoading={isLoading}
          />
        )}
      </>
    )}
    {reason === '3' && (
      <>
        <CallToAction
          link="tel:+18887781009"
          text="Call now, get a discount"
          onClick={handleCallNow}
        />
        <Button
          variant="tertiary"
          children="Schedule a call"
          parentClasses="mt-[12px]"
          onPress={handleScheduleCall}
        />
        {isLoading ? (
          <LoaderComponent classNames="mt-[12px]" />
        ) : (
          <Button
            variant="tertiary"
            children="Cancel subscription"
            parentClasses="mt-[12px]"
            onPress={handleCancelSubscription}
            isLoading={isLoading}
          />
        )}
      </>
    )}
    {reason === 'other' && (
      <>
        <Button
          variant="primary"
          children="Schedule call"
          parentClasses="mt-[32px]"
          onPress={handleScheduleCall}
        />
        {isLoading ? (
          <LoaderComponent classNames="mt-[12px]" />
        ) : (
          <Button
            variant="tertiary"
            children="Cancel subscription"
            parentClasses="mt-[12px]"
            onPress={handleCancelSubscription}
            isLoading={isLoading}
          />
        )}
      </>
    )}
    {reason === '4' && (cancelObject?.lookingFor == 2 || cancelObject?.lookingFor === 3) && (
      <>
        <CallToAction
          link="https://www.thepaystubs.com/"
          text="Go to ThePayStubs.com"
          onClick={handleCallNow}
        />
        {isLoading ? (
          <LoaderComponent classNames="mt-[12px]" />
        ) : (
          <Button
            variant="tertiary"
            children="Cancel subscription"
            parentClasses="mt-[12px]"
            onPress={handleCancelSubscription}
            isLoading={isLoading}
          />
        )}
      </>
    )}
    {reason === '4' &&
      (cancelObject?.lookingFor == 1 ||
        cancelObject?.lookingFor === 4 ||
        cancelObject?.lookingFor === 'other') && (
        <>
          <Button
            variant="primary"
            children="Schedule call"
            parentClasses="mt-[32px]"
            onPress={handleScheduleCall}
          />
          {isLoading ? (
            <LoaderComponent classNames="mt-[12px]" />
          ) : (
            <Button
              variant="tertiary"
              children="Cancel subscription"
              parentClasses="mt-[12px]"
              onPress={handleCancelSubscription}
              isLoading={isLoading}
            />
          )}
        </>
      )}
  </>
);
