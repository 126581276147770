import { ContentBlockInfoBoxProps } from './types';
import useContentBlockInfoBox from './useContentBlockInfoBox';

import './ContentBlockInfoBox.css';

const ContentBlockInfoBox = ({ identifier }: ContentBlockInfoBoxProps) => {
  const { markup } = useContentBlockInfoBox({ identifier });
  return (
    !!markup && (
      <div className="flex w-full justify-center rounded-md border border-navy-100 bg-white px-5 py-[30px] sm:px-[30px]">
        <div className="content-block w-full" dangerouslySetInnerHTML={markup} />
      </div>
    )
  );
};

export default ContentBlockInfoBox;
