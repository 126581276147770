import { MutationEndpoint, QueryEndpoint } from '../types';

export const payrollScheduleQueryEndpoints = {
  payrollSchedule: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payroll-schedule', 'payroll-settings'],
    url: `/company/${companyId}/payroll-settings`,
  }),
  getPayrollHistory: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payroll-schedule', 'payroll-history'],
    url: `/company/${companyId}/employees/payroll-history`,
  }),

  // TO DO: move in separate folder(currently only in use payroll schedule)
  getDashboardList: (companyId: number): QueryEndpoint => ({
    queryKey: ['company', companyId, 'payroll-schedule', 'dashboard-payroll-list'],
    url: `/company/${companyId}/dashboard`,
  }),
};

export const payrollScheduleMutationEndpoints = {
  getPayrollSchedulePreview: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'payroll-schedule', 'payroll-schedule-preview'],
    url: `/company/${companyId}/payroll-schedule/preview-schedule`,
    invalidateKeys: [],
  }),
  submitPayrollSchedule: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId, 'payroll-schedule', 'submit-payroll-schedule'],
    url: `/company/${companyId}/payroll-schedule/submit-schedule`,
    invalidateKeys: [],
  }),
};
