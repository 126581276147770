import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetRunnablePayroll } from '@/api';
import { PayrollScheduleType } from '@/api/payrollSchedule/types';
import useGetDashboardList from '@/api/payrollSchedule/useGetDashboardList';
import useGetEmployeePayrollHistory from '@/api/payrollSchedule/useGetEmployeePayrollHistory';
import useGetPayrollSchedule from '@/api/payrollSchedule/useGetPayrollSchedule';
import usePayrollSchedulePreview from '@/api/payrollSchedule/usePayrollSchedulePreview';
import useSubmitPayrollSchedule from '@/api/payrollSchedule/useSubmitPayrollSchedule';
import { useAccountInfo } from '@/hooks';
import { RunnablePayrolls } from '@/redux/dto/employee';
import { RootState } from '@/redux/store';
import { isArrayHasData } from '@/utils/helpers';

import { PayrollScheduleProps, PayrollScheduleTypes } from './types';
import { separateSchedules } from './utils';

export const OverWriteOption = {
  other: 'Custom Pay Period',
};

const usePayrollSchedule = ({
  setIsPayrollScheduleOpen,
  isEdit,
  step,
  setStep,
  setIsEdit,
  refetchDashboard,
}: PayrollScheduleProps) => {
  const [previewSchedule, setPreviewSchedule] = useState({
    backDated: [],
    upcoming: [],
    overdue: [],
  });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isScheduleEditable, setIsScheduleEditable] = useState(true);
  const [previewScheduleData, setPreviewScheduleData] = useState<PayrollScheduleType | undefined>(
    undefined,
  );
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [payFrequency, setPayFrequency] = useState(1);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const previewScheduleButtonRef = useRef();

  const { companyId, company } = useAccountInfo();

  const { data: payrollSchedule, refetch: refetchPayrollSchedule } =
    useGetPayrollSchedule(companyId);

  const { refetch: refetchRunnablePayrolls, isFetching: isFetchingRunnablePayrolls } =
    useGetRunnablePayroll({
      companyId,
      queryParams: {
        enabled: false,
      },
    });

  const { data: employeePayrollHistory } = useGetEmployeePayrollHistory(companyId);
  const { data: dashboardPayrollList } = useGetDashboardList(companyId);

  const { mutateAsync: mutateSchedulePreview } = usePayrollSchedulePreview({
    companyId,
  });

  const { mutateAsync: mutateSubmitSchedule } = useSubmitPayrollSchedule({ companyId });

  const handleSaveButtonValidation = (isDisabled: boolean) => {
    setIsSaveButtonDisabled(isDisabled);
  };

  const warningPayroll = useSelector((state: RootState) => state.employee.warningPayroll);

  const handleClose = () => {
    refetchDashboard && refetchDashboard();
    drawerCloseHandler();
  };

  const drawerCloseHandler = () => {
    setIsEdit(false);
    setIsConfirmationOpen(false);
    setIsPayrollScheduleOpen(false);
    if (step === 2) {
      setStep(prev => --prev);
    }
  };

  useEffect(() => {
    getHistory();
  }, [employeePayrollHistory]);

  const getHistory = async () => {
    setLoading(true);
    if (employeePayrollHistory) {
      const filteredHistory =
        Array.isArray(employeePayrollHistory) &&
        employeePayrollHistory.filter(history => history.type !== 'test');
      setIsScheduleEditable(!isArrayHasData(filteredHistory));
    }
    setLoading(false);
  };

  const getPreviewSchedule = async data => {
    setLoading(true);
    const { data: res } = await mutateSchedulePreview(data);
    if (res) {
      setLoading(false);
      // Don't pass runnablePayrolls to here as user is reviewing the schedule
      const scheduleData = separateSchedules(res?.scheduleEntries, data?.payPeriodEndDate);
      setPreviewSchedule(scheduleData);
      setStep(prev => ++prev);
      refetchPayrollSchedule();
      setPayFrequency(res?.payFrequency);
      setPreviewScheduleData({ ...res, ...data });
    } else {
      setLoading(false);
    }
  };

  const handleReviewData = async () => {
    setIsPreviewLoading(true);
    let runnablePayrolls: RunnablePayrolls = [];
    if (company?.payrollSchedule) {
      try {
        const { data: runnablePayrollsData } = await refetchRunnablePayrolls();
        const formattedRunnablePayrolls = runnablePayrollsData?.data;
        runnablePayrolls = Array.isArray(formattedRunnablePayrolls)
          ? formattedRunnablePayrolls
          : [];
      } catch (error) {
        console.error(error);
      }
    }

    // pass runnablePayrolls to separateSchedules only when the Payroll schedule is saved
    const data = separateSchedules(
      payrollSchedule?.scheduleEntries,
      moment(payrollSchedule?.payPeriodEndDate).format('MM/DD/YYYY'),
      runnablePayrolls,
    );
    setPayFrequency(payrollSchedule?.payFrequency ?? 0);
    setPreviewSchedule(data);
    setIsPreviewLoading(false);
  };

  useEffect(() => {
    if (isEdit) {
      handleReviewData();
    }
  }, [isEdit, payrollSchedule]);

  const queryClient = useQueryClient();

  const handleSubmitSchedule = async () => {
    setLoading(true);
    const { data: submitRes } = await mutateSubmitSchedule(data);

    if (submitRes) {
      Boolean(warningPayroll?.[0]?.isDone) && location.reload();
      if (dashboardPayrollList) {
        setLoading(false);
        refetchDashboard && refetchDashboard();
        setIsPayrollScheduleOpen(false);
      }
      queryClient.invalidateQueries({
        queryKey: ['company', companyId, 'payroll-schedule', 'payroll-settings'],
      });
      queryClient.invalidateQueries({
        queryKey: ['company', companyId, 'run-payroll', 'get-runnable'],
      });
    } else {
      setLoading(false);
    }
  };

  const handleEditSchedule = () => {
    setStep(1);
    setPreviewScheduleData(payrollSchedule);
  };

  const onSubmit = (data: PayrollScheduleTypes) => {
    setIsEdit(false);
    setPayFrequency(data?.payFrequency ?? 0);
    getPreviewSchedule(data);
    setData(data);
  };

  const handleBackButton = () => {
    setStep(prev => ++prev);
  };

  const handleIsDirty = (isDirty: boolean) => {
    setIsFormDirty(isDirty);
  };

  const handleCloseDirtyModal = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmDirtyModal = () => {
    setIsConfirmationOpen(false);
    setIsEdit(false);
    if (step === 2) {
      setStep(prev => --prev);
    }
    handleClose();
  };

  const handleDrawerClose = () => {
    if (isFormDirty) {
      setIsConfirmationOpen(true);
    } else {
      handleClose();
    }
  };

  return {
    handleClose,
    onSubmit,
    step,
    loading,
    isPreviewLoading,
    isFetchingRunnablePayrolls,
    previewSchedule,
    handleSubmitSchedule,
    handleBackButton,
    isConfirmationOpen,
    isScheduleEditable,
    handleEditSchedule,
    previewScheduleButtonRef,
    previewScheduleData,
    handleSaveButtonValidation,
    isSaveButtonDisabled,
    payFrequency,
    handleIsDirty,
    handleConfirmDirtyModal,
    handleCloseDirtyModal,
    handleDrawerClose,
  };
};

export default usePayrollSchedule;
