import { useQuery } from '@tanstack/react-query';

import { getCall } from '../axios';
import { payrollScheduleQueryEndpoints } from './endpoints';
import { PayrollListType } from './types';

const useGetDashboardList = (companyId: number) => {
  const { queryKey, url } = payrollScheduleQueryEndpoints.getDashboardList(companyId);

  return useQuery<PayrollListType, Error>({
    queryKey,
    queryFn: async () => {
      const response = await getCall(url);
      return response.data;
    },
  });
};

export default useGetDashboardList;
