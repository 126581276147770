import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '@/redux/store';

import { checkActiveFreeTrialInAccount } from '@/utils/helpers';
import { PaymentDetailsType } from './types';
import { UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID } from './utils/constants';

interface useUpgradePlanWithPaymentModalType {
  remainderDays?: string | number;
  isFreeTrailOver?: boolean;
  onCancel: () => void;
}

const useUpgradePlanWithPaymentModal = ({
  remainderDays,
  isFreeTrailOver,
  onCancel,
}: useUpgradePlanWithPaymentModalType) => {
  const dispatch = useDispatch<Dispatch>();

  const [currentStep, setCurrentStep] = useState(
    UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan,
  );

  const subscriptionInfo = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const isSubscriptionCanceled =
    subscriptionInfo?.activeSubscriptionAgreement?.scheduledToBeCancelled;

  const { freeTrialDaysLeftInSubscribedPlan } = checkActiveFreeTrialInAccount({
    subscriptionInfo: subscriptionInfo,
  });

  const [paymentDetails, setPaymentDetails] = useState<PaymentDetailsType | null>(null);

  const upgradePlanTitle = (() => {
    if (isSubscriptionCanceled) {
      return 'Renew subscription';
    }

    if (subscriptionInfo.subscriptionAgreements[0]?.status === 'ended') {
      return 'Your free trial is over!';
    }

    if (freeTrialDaysLeftInSubscribedPlan <= 1 && freeTrialDaysLeftInSubscribedPlan >= -7) {
      return 'Your free trial is almost over!';
    }

    if (isFreeTrailOver) {
      return 'Your free trial is over!';
    }

    if (remainderDays) {
      return `Your free trial ends in ${remainderDays} days!`;
    }

    return 'Manage your subscription';
  })();

  const upgradePlanSubTitle = (() => {
    if (
      (freeTrialDaysLeftInSubscribedPlan <= 1 && freeTrialDaysLeftInSubscribedPlan >= -7) ||
      subscriptionInfo.subscriptionAgreements[0].status === 'ended'
    ) {
      return 'Select your plan to continue running payroll.';
    }

    if (isFreeTrailOver || isSubscriptionCanceled) {
      return 'Select your plan to continue running payroll.';
    }

    if (remainderDays) {
      return `Ready to pick your plan with us?`;
    }

    return 'Take control of your plan. Upgrade, downgrade, or make changes to fit your needs.';
  })();

  const paymentSuccessHandler = async () => {
    const res = await dispatch.auth.getSubscriptionStatus();
    if (res) {
      const activeSubscriptionAgreement =
        res?.subscriptionAgreements[res?.subscriptionAgreements?.length - 1];
      setPaymentDetails({
        amount: activeSubscriptionAgreement?.price?.amount,
        planName: activeSubscriptionAgreement.plan,
        nextProcessingDate: activeSubscriptionAgreement?.nextProcessingDate,
        discount: activeSubscriptionAgreement.discountCodes,
      });

      setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderPaymentSuccess);
    }
  };

  const paymentSuccessConfirmationHandler = () => {
    onCancel();
    setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan);
  };

  return {
    currentStep,
    paymentSuccessHandler,
    paymentDetails,
    upgradePlanTitle,
    setCurrentStep,
    upgradePlanSubTitle,
    paymentSuccessConfirmationHandler,
  };
};

export default useUpgradePlanWithPaymentModal;
