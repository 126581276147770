// import url from 'utils/url';
import axios from '../axios';

export interface WorkLocation {
  streetAddress: string;
  aptSteNumber?: string;
  city: string;
  state: string;
  zipCode: string;
}

// Add a work location to a company

export const postWorkLocation = (companyId: string, payload: WorkLocation) => {
  return axios.post(`/company/${companyId}/work-location`, payload);
};