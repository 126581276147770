import { Dialog } from '@headlessui/react';
import { useEffect } from 'react';

import { ReactComponent as Logout } from '@/assets/logoutCircle.svg';
import { Button } from '@/components';
import { trackEvent } from '@/utils/track';

interface EditPhoneNumberModalProps {
  isOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  onAction: () => void;
}

const LogoutModal: React.FC<EditPhoneNumberModalProps> = ({
  isOpen,
  toggleModal,
  onAction,
}: EditPhoneNumberModalProps) => {
  let buttonValue = '';
  useEffect(() => {
    trackEvent('modalMount', {
      name: 'logout_modal',
    });
    return () => {
      trackEvent('modalUnmount', {
        name: 'logout_modal',
        clickedButton: buttonValue,
      });
    };
  }, []);

  return (
    <Dialog open={isOpen} onClose={() => null} className="relative z-50">
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm backdrop-brightness-75">
        <Dialog.Panel className="">
          <div className="flex h-[105vh] max-h-[100vh] w-full items-center justify-center overflow-y-auto overflow-x-hidden  lg:h-auto lg:w-[500px] lg:rounded-2xl lg:px-[40px]">
            <div className="w-full px-[40px]">
              <div className="bg-gray relative flex h-[300px] flex-col items-center rounded-2xl px-[20px] pt-[40px]">
                <Logout className="min-h-[48px] min-w-[48px]" />
                <h1 className="font-F37Bolton-Medium mt-[16px] text-center">
                  Do you really want to log out of your profile?
                </h1>
                <div className="mt-[20px]">
                  <Button
                    type={'primary'}
                    children="Log out"
                    width="w-[140px]"
                    onClick={() => {
                      onAction();
                      buttonValue = 'Log out';
                      toggleModal(!isOpen);
                    }}
                    className="focus:outline-offset-4"
                  />
                </div>
                <Button
                  type={'link'}
                  children="Cancel"
                  onClick={() => {
                    buttonValue = 'Cancel';
                    toggleModal(!isOpen);
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default LogoutModal;
