type CodeMessages = {
  [key: string]: {
    title: string;
    subtitle: string;
  };
};

export const codeMessageMap: CodeMessages = {
  BLINK20: {
    title: 'Get 20% off for 12 months!',
    subtitle: 'Limited time offer, don’t miss out.',
  },
  BLINK50: {
    title: 'Grab 50% off until the end of 2025!',
    subtitle: 'Limited time offer, don’t miss out',
  },
  DEFAULT: {
    title: 'Grab 50% off until the end of 2025!',
    subtitle: 'Limited time offer, don’t miss out',
  },
};
