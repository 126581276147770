import { CreditCardIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

import Button from '@/components/Button/Button';
import useSubscriptionPaymentMethodCard from './useSubscriptionPaymentMethodCard';

import { ReactComponent as PaypalLogo } from '@/assets/PayPallLogoFull.svg';
import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import { ReactComponent as MasterCardIcon } from '@/assets/masterCardIcon.svg';
import { ReactComponent as StripeLogo } from '@/assets/stripeLogo.svg';
import { DialogComponent, PaymentMethods } from '@/components';

type SubscriptionPaymentMethodCardProps = {
  /** Choose plan click handler */
  onChooseSubscriptionPlan?: () => void;
};

const SubscriptionPaymentMethodCard = ({
  onChooseSubscriptionPlan,
}: SubscriptionPaymentMethodCardProps) => {
  const {
    loadEmbeddedFastSpringPayment,
    isLoadingFastSpringData,
    isFastSpringIdAvailable,
    formattedData,
    isEditPaymentMethodModalOpen,
    closePaymentMethodHandler,
    editPaymentMethodClickHandler,
    successPaymentMethodHandler,
  } = useSubscriptionPaymentMethodCard();

  return (
    <div className="flex size-full flex-col gap-[18px] rounded-lg border p-4">
      <div className="flex flex-col gap-1">
        <p className="text-sm">Payment method</p>
        <p className="text-sm text-gray-300">Change how you pay for your plan.</p>
      </div>
      {formattedData ? (
        <div className="flex items-center gap-4 rounded-lg border px-3.5 py-2.5">
          <div className="flex">
            <div className="flex size-11 items-center justify-center rounded-full bg-emerald-100 p-3">
              <CreditCardIcon className="w-5 text-emerald-500" />
            </div>
          </div>
          <div className="flex flex-1 flex-wrap justify-between gap-y-1.5">
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-1 text-sm">
                Accepted by
                <div>
                  {formattedData.type === 'stripe' ? (
                    <StripeLogo />
                  ) : formattedData.type === 'paypal' ? (
                    <PaypalLogo className="h-5 w-14" />
                  ) : (
                    formattedData.type === 'fastspring' && formattedData.acceptedBy
                  )}
                </div>
              </div>
              {formattedData.type === 'fastspring'
                ? !!formattedData.lastPaymentDate &&
                  moment(formattedData.lastPaymentDate).isValid() && (
                    <p className="text-sm text-gray-300">
                      Last payment: {moment(formattedData.lastPaymentDate).format('MMM DD, YYYY')}
                    </p>
                  )
                : formattedData.type === 'stripe' && (
                    <div className="flex items-center gap-2">
                      {formattedData.cardInfo?.type === 'visa' ? (
                        <img
                          src="https://cdn.prod.website-files.com/65f9e81809d8f605e0896f4f/6719018fde1f79671e66ea9f_visaCardIcon.svg"
                          alt="Visa Card"
                        />
                      ) : (
                        <MasterCardIcon />
                      )}
                      <div className="flex items-center gap-1 text-sm text-gray-300">
                        <p className="text-xl"> {'**** '.repeat(3)}</p>{' '}
                        {formattedData.cardInfo?.last4 ?? ''}
                      </div>
                    </div>
                  )}
            </div>

            {formattedData.type === 'fastspring' && isFastSpringIdAvailable ? (
              <Button
                variant="primary"
                height="h-6"
                parentClasses="py-1 px-2.5 rounded-lg text-gray-400 text-xs self-start"
                width="w-full max-w-[65px]"
                isLoading={isLoadingFastSpringData}
                isDisabled={isLoadingFastSpringData}
                onPress={() => {
                  loadEmbeddedFastSpringPayment();
                }}
              >
                Manage
              </Button>
            ) : (
              ['paypal', 'stripe'].includes(formattedData.type) && (
                <Button
                  variant="text"
                  parentClasses="underline underline-offset-2 h-auto text-emerald-500 hover:text-emerald-700 font-F37Bolton-Medium text-sm"
                  onPress={() => editPaymentMethodClickHandler()}
                >
                  Edit
                </Button>
              )
            )}
          </div>
        </div>
      ) : (
        !!onChooseSubscriptionPlan && (
          <div className="flex h-20 w-full items-center justify-center rounded-lg border">
            <Button
              variant="text"
              height="h-auto"
              parentClasses="underline text-emerald-500 text-sm underline-offset-2"
              onPress={() => onChooseSubscriptionPlan()}
            >
              Choose your pricing plan
            </Button>
          </div>
        )
      )}

      <DialogComponent
        open={isEditPaymentMethodModalOpen}
        onCancel={closePaymentMethodHandler}
        height="h-auto"
        width="max-w-[95vw] w-[500px]"
        modalClasses={`px-5 py-7 rounded-lg`}
      >
        <div>
          <div className="absolute right-2 top-1.5 flex justify-end pr-[16px] ">
            <Button variant="icon" icon={CloseIcon} onPress={closePaymentMethodHandler} />
          </div>
          <PaymentMethods onSuccess={successPaymentMethodHandler} />
        </div>
      </DialogComponent>
    </div>
  );
};

export default SubscriptionPaymentMethodCard;
