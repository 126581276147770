import { useEffect, useRef, useState } from 'react';

import { ReactComponent as Illustration } from '@/assets/illustration/discountBannerIllustration.svg';

import { codeMessageMap } from './types';

export const DiscountBanner = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (bannerRef.current) {
        setIsSmallScreen(bannerRef.current.offsetWidth < 400);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const code = sessionStorage.getItem('discountCode') ?? '';
  const messages = codeMessageMap[code] || codeMessageMap.DEFAULT;

  return (
    <div
      className="bg-primary-emerald-light-bg rounded-small p-medium relative flex w-full flex-col"
      ref={bannerRef}
    >
      <span className="text-x-large font-F37Bolton-Bold text-content-primary">
        {messages.title}
      </span>
      <span className="text-small font-F37Bolton-Medium text-content-body-strong">
        {messages.subtitle}
      </span>
      {!isSmallScreen && <Illustration className="absolute right-0" />}
    </div>
  );
};
