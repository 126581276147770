import { Experiment, Result, useGrowthBook } from '@growthbook/growthbook-react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BROWSER_STORAGE_KEYS, FEATURE_FLAGS } from '@/utils/constants';
import useAccountInfo from '../useAccountInfo/useAccountInfo';

const getStoredFeatureFlags = () => {
  try {
    const storedValues = sessionStorage.getItem(BROWSER_STORAGE_KEYS.growthBookForcedFeatureValues);
    const parsedValue = storedValues ? JSON.parse(storedValues) : {};
    return Object.values(parsedValue).length ? parsedValue : {};
  } catch (error) {
    console.error('Failed to parse stored feature flag JSON', error);
    return {};
  }
};

const useGrowthbookSetup = () => {
  const growthbook = useGrowthBook();
  const [queryParams] = useSearchParams();

  const { account, patchAccount } = useAccountInfo();

  const isGrowthBookReady = growthbook?.ready;

  // Refresh Growthbook features, if Grothbook is not ready
  const refetchFeatures = async () => {
    if (!isGrowthBookReady) {
      await growthbook?.refreshFeatures();
    }
  };

  useEffect(() => {
    refetchFeatures();
  }, [isGrowthBookReady]);

  useEffect(() => {
    loadFeatures();
  }, [account]);

  useEffect(() => {
    if (isGrowthBookReady) {
      setForcedFeatureValues();
    }
  }, [queryParams, growthbook, isGrowthBookReady]);

  // Update User attributes in Growthbook
  const loadFeatures = async () => {
    try {
      if (!account) return;

      await growthbook?.updateAttributes({
        id: account.id,
        userId: account.id,
        auth0id: account.auth0Id,
        email: account.email,
        createdAt: account.createdAt,
      });

      growthbook?.setTrackingCallback(trackExperiment);

      growthbook?.refreshFeatures();
    } catch (error) {
      console.error('Error in Growthbook initialization', error);
    }
  };

  // set Forced feature flag values
  const setForcedFeatureValues = () => {
    const queryFeatureFlags = Array.from(queryParams.entries()).filter(([param]) =>
      Object.values(FEATURE_FLAGS).includes(param),
    );

    let featureKeyValues = {};

    if (queryFeatureFlags.length) {
      featureKeyValues = Object.fromEntries(queryFeatureFlags);
      sessionStorage.setItem(
        BROWSER_STORAGE_KEYS.growthBookForcedFeatureValues,
        JSON.stringify(featureKeyValues),
      );
    } else {
      featureKeyValues = getStoredFeatureFlags();
    }

    if (Object.values(featureKeyValues).length && growthbook) {
      const forcedFeatures = new Map(Object.entries(featureKeyValues));

      growthbook.setForcedFeatures(forcedFeatures);

      growthbook?.refreshFeatures();
    }
  };

  // Update account Metadata to add Feature Flag
  const trackExperiment = (experiment: Experiment<any>, result: Result<any>) => {
    if (!account) return;

    if (window.FS) {
      window.FS('trackEvent', {
        name: 'Experiment Viewed',
        properties: {
          experimentId: experiment.key,
          variationId: result.key,
        },
      });
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'experiment_viewed',
      experiment_id: experiment.key,
      variation_id: result.key,
    });

    const formattedMetaData =
      account.metadata && !Array.isArray(account.metadata) ? account.metadata : {};

    const metadataKey = result.featureId ?? experiment.key;

    if (
      !Object.hasOwn(formattedMetaData, metadataKey) ||
      formattedMetaData[metadataKey] !== result.value
    ) {
      const payload = {
        metadata: {
          ...formattedMetaData,
          [metadataKey]: result.value,
        },
      };

      patchAccount(payload);
    }
  };
};

export default useGrowthbookSetup;
