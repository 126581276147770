import { useState } from 'react';
import { useCalendlyEventListener } from 'react-calendly';

const useOnboardingSchedulerMobile = () => {
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);
  const [isCalendlyLoading, setIsCalendlyLoading] = useState(true);
  const handleCalendlyModal = () => {
    setIsCalendlyModalOpen(!isCalendlyModalOpen);
    setIsCalendlyLoading(true);
  };
  useCalendlyEventListener({ onEventTypeViewed: () => setIsCalendlyLoading(false) });
  return {
    handleCalendlyModal,
    isCalendlyLoading,
    isCalendlyModalOpen,
  };
};
export default useOnboardingSchedulerMobile;
