// import url from 'utils/url';
import { BusinessRoleModel } from '@/redux/dto/auth';

import axios from '../axios';

export interface businessProfile {
  businessType: number;
  name: string;
  numberOfEmployees: number;
  numberOfContractors: number;
  chooseEinOrSsn: number;
  ein: string;
  ssn: string;
}

export interface businessAddress {
  address: string;
  aptSteNumber: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface userProfile {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface accountProfile {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
}

export interface accountPassword {
  currentPassword: string;
  newPassword: string;
  newPassword2: string;
}

export interface createUserSignupSimple {
  firstName: string;
  lastName: string;
  email: string;
  paymentMethod: string;
  product?: string;
  coupon?: string;
}

export interface callRequest {}

export const getUserStatus = () => {
  return axios.get('/whoami');
};

export const getOnboardingStatus = () => {
  return axios.get('/account');
};

export const postOnboardingBusinessProfile = (payload: businessProfile) => {
  return axios.post('/user/onboarding/business', payload);
};

export const postOnboardingBusinessPurpose = (payload: businessProfile) => {
  return axios.post('/user/onboarding/business-purpose', payload);
};

export const postOnboardingBusinessAddress = (payload: businessAddress) => {
  return axios.post('/user/onboarding/business-address', payload);
};

export const postOnboardingBusinessRole = (payload: BusinessRoleModel) =>
  axios.post('/user/onboarding/business-role', payload);

export const postOnboardingUserProfile = (payload: userProfile) => {
  return axios.post('/user/onboarding/profile', payload);
};

export const makeCallRequest = (accountId: number | undefined, payload: callRequest) =>
  axios.post(`/account/${accountId}/request-call`, payload);

//Retrieves a Profile resource.

export const getProfileResource = (id: string) => {
  return axios.get(`/profile/${id}`);
};

//Updates the Account resource.
export const updateAccountResource = (id: string, payload: accountProfile) => {
  return axios.patch(`/account/${id}`, payload);
};

//Update current account's password
export const updatePassword = (payload: accountPassword) => {
  return axios.post(`/update-password`, payload);
};

// Set that this account is interested in employee payroll plan

export const interestedEmployeePayrollPlan = (id: number) => {
  return axios.post(`/account/${id}/interested-in-employee-payroll`, {});
};

// Create user for signup-simple

export const createUserSignupSimple = (payload: createUserSignupSimple) => {
  const axiosWithoutInterceptor = axios.create();
  return axiosWithoutInterceptor.post('/create-user', payload);
};
