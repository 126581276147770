import moment from 'moment';

import { RunnablePayrolls } from '@/redux/dto/employee';
import { ScheduleType } from './components/ReviewScheduleContent/types';
import { quarters } from './types';

export const dayDifferenceCondition = (day1: number, day2: number | string) => {
  const momentDay1 = moment(day1, 'DD');

  let momentDay2;

  // Handle 'Last day of the month' for day2
  if (day2 === 'Last day of the month') {
    momentDay2 = moment().endOf('month'); // Get the last day of the current month
  } else {
    momentDay2 = moment(day2, 'DD');
  }

  const dayDifference = Math.abs(momentDay2.diff(momentDay1, 'days'));

  if (dayDifference < 13) {
    return 'For a semimonthly pay period, the first and second pay days cannot be less than 13 days apart.';
  } else if (dayDifference > 17) {
    return 'For a semimonthly pay period, the first and second pay days cannot be more than 17 days apart.';
  } else {
    return false;
  }
};

export const getPrevSemiMonthDate = (selectedDate: string) => {
  const result = [];
  const currentDate = moment(selectedDate);
  const startOfYear = moment(currentDate).subtract(1, 'year').startOf('year');

  const monthsToGoBack = currentDate.diff(startOfYear, 'months');
  for (let i = 0; i < monthsToGoBack; i++) {
    // Calculate the 15th and last day of the previous month
    const fifteenthOfPrevMonth =
      currentDate.date() === 15
        ? currentDate.clone().subtract(1, 'months').date(15)
        : currentDate.clone().date(15);

    const lastDayOfPrevMonth = currentDate.clone().subtract(1, 'months').endOf('month');
    currentDate.date() === 15
      ? result.push(
          lastDayOfPrevMonth.format('MM/DD/YYYY'),
          fifteenthOfPrevMonth.format('MM/DD/YYYY'),
        )
      : result.push(
          fifteenthOfPrevMonth.format('MM/DD/YYYY'),
          lastDayOfPrevMonth.format('MM/DD/YYYY'),
        );

    // Move to the next month
    currentDate.subtract(1, 'months');
  }

  return result.reverse();
};

export const getSemiMonthOtherPrevDate = (
  selectedDate: string,
  firstDay?: number,
  secondDay?: number,
) => {
  const currentDate = moment(selectedDate);
  const dateList: string[] = [];

  const startOfYear = moment(currentDate).subtract(1, 'year').startOf('year');

  const monthsToGoBack = currentDate.diff(startOfYear, 'months');

  for (let i = 0; i <= monthsToGoBack; i++) {
    const previousMonth = currentDate.clone().subtract(i, 'months');

    const fifteenthDay = previousMonth.clone().date(firstDay);

    const lastDay = previousMonth.clone().date(secondDay);

    if (lastDay.isAfter(currentDate)) {
      lastDay.subtract(1, 'month');
    }

    // Add dates only if they are in the past
    if (lastDay.isBefore(currentDate) && !dateList.includes(lastDay.format('MM/DD/YYYY'))) {
      dateList.push(lastDay.format('MM/DD/YYYY'));
    }
    if (fifteenthDay.isBefore(currentDate)) {
      dateList.push(fifteenthDay.format('MM/DD/YYYY'));
    }
  }

  return dateList.reverse();
};

export const getMonthPrevDate = (selectedDate: string, payDay: string, firstPayPeriod?: string) => {
  const currentDate = moment(selectedDate);

  const dateList: string[] = [];
  const startOfYear = moment(currentDate).subtract(1, 'year').startOf('year');
  const monthsToGoBack = currentDate.diff(startOfYear, 'months');

  for (let i = 1; i <= monthsToGoBack; i++) {
    const previousMonth =
      payDay === 'Last day of the month' && !firstPayPeriod
        ? currentDate.clone().subtract(i, 'months').endOf('months')
        : currentDate.clone().subtract(i, 'months');

    const date = previousMonth.clone();
    dateList.push(date.format('MM/DD/YYYY'));
  }

  return dateList;
};

export const getPreviousWeekDays = (date: string | null) => {
  const previousMondays = [];
  const today = moment(date);

  const startOfPreviousYear = moment(today).subtract(1, 'year').startOf('year');

  const monthsToGoBack = today.diff(startOfPreviousYear, 'weeks');

  for (let i = 1; i <= monthsToGoBack; i++) {
    const previousWeekDays = today.clone().subtract(i, 'weeks');
    if (previousWeekDays.isSameOrBefore(today, 'day')) {
      previousMondays.push(previousWeekDays.format('MM/DD/YYYY'));
    }
  }

  return previousMondays.reverse();
};

export const getPreviousBiWeekDays = (date: string | null) => {
  const previousMondays = [];
  const today = moment(date);

  const startOfPreviousYear = moment(today).subtract(1, 'year').startOf('year');

  const monthsToGoBack = today.diff(startOfPreviousYear, 'weeks');

  for (let i = 3; i <= monthsToGoBack + 1; i += 2) {
    const previousWeekDays = today.clone().subtract(i, 'weeks');
    if (previousWeekDays.isSameOrBefore(today, 'day')) {
      previousMondays.push(previousWeekDays.format('MM/DD/YYYY'));
    }
  }

  return previousMondays.reverse();
};

export const separateSchedules = (
  schedule,
  firstPayDate: string,
  runnablePayrolls?: RunnablePayrolls,
) => {
  let backdated: ScheduleType[] = [];
  let overdue: ScheduleType[] = [];
  let upcoming: ScheduleType[] = [];

  for (const entry of schedule) {
    const payDateMoment = moment(moment(entry.endDate).format('MM/DD/YYYY'));
    if (payDateMoment.isBefore(moment(firstPayDate))) {
      backdated.push(entry);
    } else {
      upcoming.push(entry);
    }
  }

  if (runnablePayrolls?.length) {
    const updatedBackdated = runnablePayrolls
      .filter(item => item.isPast)
      .map(({ payDate, payPeriod: { startDate, endDate } }) => ({ payDate, startDate, endDate }));

    const updatedOverdue = runnablePayrolls
      .filter(item => item.isOverdue)
      .map(({ payDate, payPeriod: { startDate, endDate } }) => ({ payDate, startDate, endDate }));

    let updatedUpcoming: ScheduleType[] = [];
    const firstUpcomingPayroll = runnablePayrolls.find(item => !(item.isPast || item.isOverdue));

    if (firstUpcomingPayroll) {
      updatedUpcoming = upcoming.filter(item =>
        moment(item.payDate).isSameOrAfter(firstUpcomingPayroll.payDate),
      );
    }
    backdated = updatedBackdated;
    overdue = updatedOverdue;
    upcoming = updatedUpcoming;
  }

  return {
    backdated,
    upcoming,
    overdue,
  };
};

export const getAllMonths = () => {
  const currentMonth = moment().month();
  const months = [];

  for (let i = currentMonth; i < 12; i++) {
    const monthName = moment().month(i).format('MMMM YYYY');
    months.push(monthName);
  }

  return months;
};

export const getAllDatesForMonth = (selectedMonth: number) => {
  if (selectedMonth < 1 || selectedMonth > 12) {
    throw new Error('Invalid month. Month should be between 1 and 12.');
  }

  const currentDate = moment();

  const currentMonth = currentDate.month() + 1; // Moment.js uses 0-indexed months
  const currentDay = currentDate.date();

  const futureDates = [];

  for (let i = 1; i <= moment(selectedMonth, 'MM').daysInMonth(); i++) {
    // Skip past dates if the month is the current month
    if (selectedMonth === currentMonth && i < currentDay) {
      continue;
    }

    const futureDate = currentDate
      .clone()
      .month(selectedMonth - 1)
      .date(i);
    futureDates.push(futureDate.format('MM/DD/YYYY'));
  }

  return futureDates;
};

export const getQuarter = (month: number) => {
  // Ensure month is a valid number between 1 and 12
  if (typeof month !== 'number' || month < 1 || month > 12) {
    throw new Error('Invalid month. Please provide a number between 1 and 12.');
  }

  // Create a Moment.js object for the given month
  const momentObj = moment().month(month - 1);

  // Determine the quarter based on Moment.js quarter function
  const quarter = momentObj.quarter();

  return [Object.keys(quarters).find(key => quarters[key] === quarter)];
};
