import { useGetAppSettings, usePostTermsAcceptance } from '@/api';
import { useAccountInfo } from '@/hooks';
import { useMemo } from 'react';

const useTermsAndCondition = () => {
  const { account } = useAccountInfo();
  const acceptedTerms = account?.acceptedTerms;

  const { data: appSettingResponse } = useGetAppSettings();
  const data = appSettingResponse?.data;

  const termsAndConditionsDocumentVersion = data?.termsAndConditionsDocumentVersion;

  const { mutate, isPending: isSavingAcceptedTerms } = usePostTermsAcceptance();

  const hasAcceptedLatestTerms = useMemo(() => {
    if (acceptedTerms && termsAndConditionsDocumentVersion) {
      return acceptedTerms.some(item => item.documentVersion === termsAndConditionsDocumentVersion);
    }
    return true;
  }, [acceptedTerms, termsAndConditionsDocumentVersion]);

  const acceptedTermsHandler = () => {
    mutate({
      documentVersion: termsAndConditionsDocumentVersion,
    });
  };

  return { hasAcceptedLatestTerms, acceptedTermsHandler, isSavingAcceptedTerms };
};

export default useTermsAndCondition;
