import { InlineWidget } from 'react-calendly';
import { FeatureOverView } from '../FeatureOverview';
import useOnboardingSchedulerDesktop from './useOnboardingSchedulerDesktop';

const OnboardingSchedulerDesktop = () => {
  const { fullName, email } = useOnboardingSchedulerDesktop();
  return (
    <div className="gap-4x-large hidden lg:flex">
      <div className="h-[914px] overflow-y-auto lg:min-w-[347px] 2xl:min-w-[500px] [&>.calendly-inline-widget]:w-full [&>.calendly-inline-widget]:rounded-lg">
        <InlineWidget
          prefill={{
            email: email,
            name: fullName,
          }}
          url="https://calendly.com/d/3xy-ywd-knc/chat-with-paystubs-com-team"
          styles={{ height: '100%' }}
        />
      </div>

      <FeatureOverView />
    </div>
  );
};

export default OnboardingSchedulerDesktop;
