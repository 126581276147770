import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';

import PaymentSuccess from '../SubscriptionReminder/PaymentSuccess/PaymentSuccess';
import UpgradeSubscriptionPlan from '../UpgradeSubscriptionPlan/UpgradeSubscriptionPlan';
import useUpgradeSubscriptionPlanModal from './useUpgradePlanWithPaymentModal';
import { UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID } from './utils/constants';

type UpgradeSubscriptionPlanModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  remainingDays?: string | number;
  subTitle?: ReactNode;
  isFreeTrailOver?: boolean;
  discountCode: string;
};

const UpgradePlanWithPaymentModal = ({
  isOpen,
  onCancel,
  remainingDays,
  isFreeTrailOver,
  discountCode,
}: UpgradeSubscriptionPlanModalProps) => {
  const {
    currentStep,
    paymentSuccessHandler,
    paymentDetails,
    upgradePlanTitle,
    upgradePlanSubTitle,
    setCurrentStep,
    paymentSuccessConfirmationHandler,
  } = useUpgradeSubscriptionPlanModal({
    remainderDays: remainingDays,
    isFreeTrailOver,
    onCancel,
  });

  return (
    <DialogComponent
      open={isOpen}
      onCancel={() => {
        onCancel();
        setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan);
      }}
      height="h-auto max-h-[95vh]"
      width={twMerge(
        'w-[95vw] sm:max-w-[520px]',
        currentStep === UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan &&
          'sm:w-[85vw] sm:max-w-[1100px]',
      )}
      modalClasses={`px-5 py-7 rounded-lg`}
    >
      {/* While closing the Payment modal, not removing existing loaded FastSpring  */}
      {isOpen && (
        <div>
          <div className="absolute right-2 top-1.5 flex justify-end pr-[16px] ">
            <Button
              variant="icon"
              icon={CloseIcon}
              onPress={() => {
                onCancel();
                setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan);
              }}
            />
          </div>
          <div className="w-full pt-5">
            {currentStep === UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderPaymentSuccess &&
            paymentDetails ? (
              <div className="w-full max-w-[480px]">
                <PaymentSuccess
                  amount={paymentDetails.amount}
                  planName={paymentDetails.planName}
                  nextProcessingDate={paymentDetails?.nextProcessingDate}
                  onConfirm={paymentSuccessConfirmationHandler}
                  discount={paymentDetails?.discount}
                  billingDate=""
                />
              </div>
            ) : (
              <div className="mx-auto w-full max-w-[1000px]">
                <UpgradeSubscriptionPlan
                  paymentSuccessHandler={paymentSuccessHandler}
                  choosePlanTitle={upgradePlanTitle}
                  subTitle={upgradePlanSubTitle}
                  subTitleStyle={!isFreeTrailOver && !remainingDays ? 'text-gray-400' : ''}
                  onCancel={() => {
                    onCancel();
                    setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan);
                  }}
                  discountCodeState={discountCode}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </DialogComponent>
  );
};

export default UpgradePlanWithPaymentModal;
