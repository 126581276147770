import { createTV } from 'tailwind-variants';

import config from '@/../tailwind.config';

import {
  ClassNameValue,
  extendTailwindMerge,
  getDefaultConfig,
  mergeConfigs,
} from 'tailwind-merge';

let twMerge: (...classLists: ClassNameValue[]) => string = () => '';

const twMergeExtendConfig = {
  extend: {
    classGroups: {
      'font-size': Object.keys(config.theme.extend.fontSize).map(key => `text-${key}`),
    },
  },
};

twMerge = extendTailwindMerge(twMergeExtendConfig);

const customTv = createTV({
  twMergeConfig: mergeConfigs(getDefaultConfig(), twMergeExtendConfig),
});

export { customTv, twMerge };
