import { createModel } from '@rematch/core';

import { RootModel } from '.';

type demoState = {
  error: any;
  demoInfo: Object;
};

export const demo = createModel<RootModel>()({
  state: {
    error: null,
    demoInfo: {
      businessRole: '',
      email: '',
      firstName: '',
      lastName: '',
      numberOfContractors: null,
      numberOfEmployees: null,
      otherBusinessRole: '',
      termsAccepted: null,
    },
  } as unknown as demoState,
  reducers: {
    UPDATE_DEMO_SUBMISSION_SUCCESS: (state, payload) => {
      return {
        ...state,
        demoInfo: payload,
      };
    },
  },
  effects: dispatch => {
    return {
      async submitDemoSuccess(payload: Object) {
        dispatch.demo.UPDATE_DEMO_SUBMISSION_SUCCESS(payload);
      },
    };
  },
});
