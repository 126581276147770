import { CheckIcon } from '@heroicons/react/20/solid';
import { ForwardedRef, forwardRef } from 'react';
import { Tab, TabList } from 'react-tabs';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { TabTitleProps } from './types';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const TabTitles = forwardRef(
  (
    { variant, tabs, currentTab, setCurrentTab, additionalTabWidth }: TabTitleProps,
    tabListContainerRef: ForwardedRef<HTMLDivElement>,
  ) => {
    const initialStepWidth = 48;
    const totalBarWidth = 335;
    const remainingWidth = totalBarWidth - initialStepWidth;
    const stepWidth = remainingWidth / tabs.length + (Number(additionalTabWidth) || 0);
    const greenWidth = initialStepWidth + stepWidth * tabs.findIndex(tab => tab.id === currentTab);

    return variant === 'progressBar' ? (
      <>
        <div ref={tabListContainerRef} className="scrollbar-hide overflow-auto">
          <TabList className="flex">
            {tabs.map(tab => (
              <Tab
                key={tab.id}
                onClick={() => {
                  trackEvent('tabChange', {
                    currentTabName: tabs[Number(currentTab) - 1].title,
                    currentPage: extractEventName(window.location.pathname),
                  });
                  if (!tab.disabled) {
                    setCurrentTab(tab.id);
                  }
                }}
                className={`mx-4 flex cursor-pointer justify-start whitespace-nowrap focus:outline-none ${
                  currentTab === tab.id ? 'text-gray-600' : 'text-gray-200'
                }`}
              >
                {tab.title}
              </Tab>
            ))}
          </TabList>
        </div>
        <div className="relative mt-[16px] h-[8px] w-full rounded-[8px] bg-gray-50">
          <div
            className={`absolute h-[8px] rounded-[8px] bg-emerald-400 transition-all duration-700 ease-in-out`}
            style={{ width: `${greenWidth}px` }}
          ></div>
        </div>
      </>
    ) : (
      <div aria-label="Progress" className="flex w-full">
        <ol role="listbox" className="flex w-full items-center justify-center">
          {tabs.map(tab => (
            <li
              key={tab.id}
              className={classNames(
                tab.id !== tabs.length ? `w-full max-w-[65px] grow` : '',
                'relative',
              )}
            >
              {tab.id < Number(currentTab) ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-emerald-500" />
                  </div>
                  <div className="relative flex size-6 items-center justify-center rounded-full bg-emerald-500">
                    <CheckIcon className="size-[14px] text-white" aria-hidden="true" />
                    <span className="sr-only">{tab.title}</span>
                  </div>
                </>
              ) : tab.id > Number(currentTab) ? (
                <>
                  <div className="relative flex size-6 items-center justify-center rounded-full bg-gray-50">
                    <span className="sr-only">{tab.title}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="relative flex size-6 items-center justify-center rounded-full border-2 border-dashed border-emerald-500 bg-white ">
                    <span className="sr-only">{tab.title}</span>
                  </div>
                </>
              )}
            </li>
          ))}
        </ol>
      </div>
    );
  },
);

TabTitles.displayName = 'TabTitles';

export default TabTitles;
