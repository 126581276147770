import { createModel } from '@rematch/core';

import {
  activateEmployee,
  deactivateEmployee,
  deleteBenefitEmployee,
  deleteCustomPostTaxEmployee,
  deleteEmployee,
  deleteGarnishmentEmployee,
  downloadEmployeeW2,
  employeeProfile,
  generateTaxDetailsEmployee,
  generateYtdTaxDeductions,
  getBenefitsEmployee,
  getCompensationInformation,
  getCustomPostTaxEmployee,
  getEmployeeDetails,
  getEmployeeW2,
  getGarnishmentsEmployee,
  getTaxDetailsEmployee,
  getYTD,
  patchEmployeeW2,
  patchYtdTaxDeductions,
  postBenefitEmployee,
  postCreateIncompleteEmployee,
  postCustomPostTaxEmployee,
  postGarnishmentEmployee,
  postShareViaEmailEmployee,
  postTaxDetailsEmployee,
  previewEmployeeW2,
  previewPaystubEmployee,
  putEmployeeInformationService,
  putUpdateCompensationInformation,
  skipBenefits,
  updateBenefitsEmployee,
  updateCustomPostTaxEmployee,
  updateGarnishmentEmployee,
  updateGeneralDetailsOfEmployee,
} from '@/api/services/employee';
import {
  cancelEmployeesPayrollItem,
  getPayrollCheck,
  getRunnableActiveEmployee,
  getRunnablePayrolls,
  postPayEmployee,
  postSubmitRunnablePayrollForCompany,
  putApproveEmployeePayroll,
} from '@/api/services/payEmployee';
import {
  deletePayrollEmployeeHistory,
  getContractorPaymentHistoryDetailsEmployee,
  getEmployeePayStubsDownload,
  getEmployeePayStubsDownloadAll,
  getEmployeePayStubsDownloadItem,
  getEmployeePayStubsPayrollItem,
  getListOfPayrollHistoryEmployee,
  getListOfPayrollHistoryEmployeeReworked,
  getPayStubsHistoryEmployee,
} from '@/api/services/payrollHistory';
import { shareEmployeePayrollEndpoint } from '@/api/services/sharePayroll';
import { Benefit, CustomPostTax, Garnishment } from '@/components/shared/BonusCard/BonusCard';
import { Payment } from '@/redux/dto/contractor';
import {
  ActionWarnings,
  Employee,
  EmployeeCompensation,
  EmployeeDetails,
  RunnableEmployee,
  RunnablePayrolls,
  TaxFormEmployee,
} from '@/redux/dto/employee';
import { PayrollHistoryResponse } from '@/redux/dto/history';
import { callToast } from '@/utils/helpers';

import type { RootModel } from '.';

type employeeState = {
  erorr: unknown;
  incompleteEmployee: employeeProfile;
  currentEmployee: Employee;
  warningPayroll: ActionWarnings;
  currentEmployeeDetails: EmployeeDetails[];
  currentEmployeeCompensation: EmployeeCompensation[];
  currentEmployeeBenifit: Benefit[];
  listPayrollHistoryEmployee: any;
  currentPaymentHistoryEmployee: any;
  currentEmployeeGarnishment: Garnishment[];
  currentEmployeeTaxInformation: TaxFormEmployee;
  runnableEmployee: RunnableEmployee[];
  currentEmployeePostTax: CustomPostTax[];
  runnablePayrolls: RunnablePayrolls;
};

export const employee = createModel<RootModel>()({
  state: {
    error: null,
    incompleteEmployee: null,
    currentEmployee: null,
    warningPayroll: null,
    currentEmployeeCompensation: null,
    listPayrollHistoryEmployee: null,
    currentPaymentHistoryEmployee: null,
    payStubs: null,
    currentEmployeeTaxInformation: null,
    currentEmployeeBenifit: null,
    runPayrollInformation: null,
    incompleteEmployeeTaxInformation: null,
    currentEmployeeGarnishment: null,
    currentEmployeePostTax: null,
    runnableEmployee: null,
    runnablePayrolls: null,
  } as unknown as employeeState,
  reducers: {
    POST_CREATE_INCOMPLETE_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        incompleteEmployee: payload,
        currentEmployee: {
          ...state.currentEmployee,
          ...payload,
        },
      };
    },
    POST_CREATE_INCOMPLETE_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PUT_EMPLOYMENT_INFORMATION_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        employmentInformation: payload,
        currentEmployee: {
          ...state.currentEmployee,
          ...payload,
        },
      };
    },
    PUT_EMPLOYMENT_INFORMATION_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PUT_COMPENSATION_INFORMATION_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        compensation: payload,
        currentEmployeeCompensation: {
          ...state.currentEmployeeCompensation,
          ...payload,
        },
      };
    },
    PUT_COMPENSATION_INFORMATION_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_BENEFITS_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeeBenifit: payload,
      };
    },
    GET_BENEFITS_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    POST_BENEFIT_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeeBenifit: payload,
      };
    },
    POST_BENEFIT_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DELETE_BENEFITS_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeeBenifit: payload,
      };
    },
    DELETE_BENEFITS_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DELETE_PAYROLL_EMPLOYEE_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    DELETE_PAYROLL_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_GARNISHMENTS_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeeGarnishment: payload,
      };
    },
    GET_GARNISHMENTS_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    POST_GARNISHMENTS_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeeGarnishment: payload,
      };
    },
    POST_GARNISHMENTS_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DELETE_GARNISHMENTS_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeeGarnishment: payload,
      };
    },
    DELETE_GARNISHMENTS_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },

    //Custom post tax

    GET_CUSTOM_POST_TAX_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeePostTax: payload,
      };
    },
    GET_CUSTOM_POST_TAX_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    POST_CUSTOM_POST_TAX_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeePostTax: payload,
      };
    },
    POST_CUSTOM_POST_TAX_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DELETE_CUSTOM_POST_TAX_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeePostTax: payload,
      };
    },
    DELETE_CUSTOM_POST_TAX_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },

    // Employee details
    GET_EMPLOYEE_DETAILS_BY_ID_SUCCESS: (state, payload) => {
      return {
        ...state,
        currentEmployee: payload,
        currentEmployeeDetails: payload,
      };
    },
    GET_EMPLOYEE_DETAILS_BY_ID_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },

    DEACTIVATE_EMPLOYEE_SUCCESS: state => {
      return {
        ...state,
        currentEmployee: {
          ...state.currentEmployee,
          isActive: false,
        },
      };
    },
    DEACTIVATE_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    ACTIVATE_EMPLOYEE_SUCCESS: state => {
      return {
        ...state,
        currentEmployee: {
          ...state.currentEmployee,
          isActive: true,
        },
      };
    },
    ACTIVATE_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },

    //Compensation

    GET_EMPLOYEE_COMPENSATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        currentEmployeeCompensation: payload,
        error: payload,
      };
    },
    GET_EMPLOYEE_COMPENSATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_EMPLOYEE_TAX_INFORMATION_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    UPDATE_EMPLOYEE_TAX_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_EMPLOYEE_YTD_TAX_INFORMATION_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    UPDATE_EMPLOYEE_YTD_TAX_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_EMPLOYEE_TAX_INFORMATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeeTaxInformation: payload,
      };
    },
    GET_EMPLOYEE_TAX_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PREVIEW_PAYSTUB_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        incompleteEmployeeTaxInformation: payload,
      };
    },
    PREVIEW_PAYSTUB_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GENERATE_EMPLOYEE_TAX_INFORMATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        incompleteEmployeeTaxInformation: payload,
      };
    },
    GENERATE_EMPLOYEE_TAX_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GENERATE_EMPLOYEE_YTD_TAX_INFORMATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        incompleteEmployeeYtdTaxDeduction: payload,
      };
    },
    GENERATE_EMPLOYEE_YTD_TAX_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_RUNNABLE_EMPLOYEE_PAYROLLS_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        runnablePayrolls: payload,
      };
    },
    GET_RUNNABLE_EMPLOYEE_PAYROLLS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CHECK_ACCOUNT_RUN_PAYROLL_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        warningPayroll: payload,
      };
    },
    GET_CHECK_ACCOUNT_RUN_PAYROLL_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        listPayrollHistoryEmployee: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_REWORKED_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        listPayrollHistoryEmployee: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_REWORKED_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CURRENT_PAYMENT_INFORMATION_CURRENT_CONTRACTOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        currentPaymentHistoryEmployee: payload,
      };
    },
    GET_CURRENT_PAYMENT_INFORMATION_CURRENT_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_EMPLOYEES_PAY_STUBS_DOWNLOAD_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        payStubs: payload,
      };
    },
    GET_EMPLOYEES_PAY_STUBS_DOWNLOAD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    CANCELL_PAYMENT_INFORMATION_EMPLOYEE_DETAILS_BY_ID_SUCCESS: (state, paymentId) => {
      const updateStatusPayments = state.currentPaymentHistoryEmployee?.payments?.map(
        (payment: Payment) => ({
          ...payment,
          status: payment.status === 2 ? 2 : payment.id === paymentId ? 2 : 1,
        }),
      );

      return {
        ...state,
        error: null,
        currentPaymentHistoryContractor: {
          ...state.currentPaymentHistoryEmployee,
          payments: updateStatusPayments,
        },
      };
    },
    CANCELL_PAYMENT_INFORMATION_EMPLOYEE_BY_ID_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_ACTIVE_EMPLOYEES_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        runnableEmployee: payload,
      };
    },
    GET_ACTIVE_EMPLOYEES_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    POST_RUN_PAYROLL_EMPLOYEES_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        runPayrollInformation: payload,
      };
    },
    POST_RUN_PAYROLL_EMPLOYEES_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    APPROVE_RUN_PAYROLL_EMPLOYEES_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    APPROVE_RUN_PAYROLL_EMPLOYEES_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SKIP_RUNNABLE_PAYROLL_EMPLOYEES_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    SKIP_RUNNABLE_PAYROLL_EMPLOYEES_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SUBMIT_RUNNABLE_PAYROLL_EMPLOYEES_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    SUBMIT_RUNNABLE_PAYROLL_EMPLOYEES_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },

    GET_LIST_PAYROLL_HISTORY_TAB_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        listPayrollHistoryEmployee: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_TAB_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DELETE_EMPLOYEE_ON_ADD_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    DELETE_EMPLOYEE_ON_ADD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    // Skip benefits step
    SKIP_BENEFITS_EMPLOYEE_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentEmployeeBenifit: payload,
      };
    },
    SKIP_BENEFITS_EMPLOYEE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_YTD_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_YTD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_W2_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_W2_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PREVIEW_W2_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PREVIEW_W2_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DOWNLOAD_W2_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DOWNLOAD_W2_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PATCH_W2_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PATCH_W2_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SHARE_VIA_EMAIL_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SHARE_VIA_EMAIL_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },
  effects: dispatch => {
    const { employee } = dispatch;
    return {
      async postCreateIncompleteEmployee(payload): Promise<unknown> {
        try {
          const { companyId, data: state } = payload;
          const { data } = await postCreateIncompleteEmployee(companyId, state);
          employee.POST_CREATE_INCOMPLETE_EMPLOYEE_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.POST_CREATE_INCOMPLETE_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async setupIncomleteEmployeeId(payload): Promise<void> {
        try {
          employee.POST_CREATE_INCOMPLETE_EMPLOYEE_SUCCESS(payload);
        } catch (error: any) {
          employee.POST_CREATE_INCOMPLETE_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateGeneralDetailsEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, data: state } = payload;
          const { data } = await updateGeneralDetailsOfEmployee(companyId, employeeId, state);
          const obj = { ...data, id: employeeId };

          employee.POST_CREATE_INCOMPLETE_EMPLOYEE_SUCCESS(obj);
          return data;
        } catch (error: any) {
          employee.POST_CREATE_INCOMPLETE_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      // Compensation
      async getCompensationInformationEmployee(payload): Promise<EmployeeCompensation> {
        try {
          const { companyId, employeeId } = payload;
          const { data } = await getCompensationInformation(companyId, employeeId);
          employee.GET_EMPLOYEE_COMPENSATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_EMPLOYEE_COMPENSATION_FAILURE(error);
          console.log(error);
        }
      },
      async putCompensetaionInformationEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, data: state } = payload;
          const { data } = await putUpdateCompensationInformation(companyId, employeeId, state);
          employee.PUT_COMPENSATION_INFORMATION_EMPLOYEE_SUCCESS(data);
          // await dispatch.members.getTeamMembersList(companyId);
          return payload;
        } catch (error: any) {
          employee.PUT_COMPENSATION_INFORMATION_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      // Benefits
      async getBenefitsEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId } = payload;
          const { data } = await getBenefitsEmployee(companyId, employeeId);
          employee.GET_BENEFITS_EMPLOYEE_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_BENEFITS_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async deleteBenefitEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, benefitId } = payload;
          const { data } = await deleteBenefitEmployee(companyId, employeeId, benefitId);
          employee.DELETE_BENEFITS_EMPLOYEE_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.DELETE_BENEFITS_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateBenefitEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, benefitId, data: state } = payload;
          const { data } = await updateBenefitsEmployee(companyId, employeeId, benefitId, state);
          employee.DELETE_BENEFITS_EMPLOYEE_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.DELETE_BENEFITS_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async postNewBenefitEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, data: state } = payload;
          const { data } = await postBenefitEmployee(companyId, employeeId, state);
          employee.POST_BENEFIT_EMPLOYEE_SUCCESS(data);
          dispatch.employee.getBenefitsEmployee({ companyId, employeeId });
          return payload;
        } catch (error: any) {
          employee.POST_BENEFIT_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Garnishments
      async getGarnishmentsEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId } = payload;
          const { data } = await getGarnishmentsEmployee(companyId, employeeId);
          employee.GET_BENEFITS_EMPLOYEE_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_BENEFITS_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async deleteGarnishmentEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, garnishmentId } = payload;
          const { data } = await deleteGarnishmentEmployee(companyId, employeeId, garnishmentId);
          employee.DELETE_BENEFITS_EMPLOYEE_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.DELETE_BENEFITS_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateGarnishmentEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, garnishmentId, data: state } = payload;
          const { data } = await updateGarnishmentEmployee(
            companyId,
            employeeId,
            garnishmentId,
            state,
          );
          employee.DELETE_BENEFITS_EMPLOYEE_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.DELETE_BENEFITS_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async postNewGarnishmentEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, data: state } = payload;
          const { data } = await postGarnishmentEmployee(companyId, employeeId, state);
          employee.POST_BENEFIT_EMPLOYEE_SUCCESS(data);
          dispatch.employee.getGarnishmentsEmployee({ companyId, employeeId });
          return payload;
        } catch (error: any) {
          employee.POST_BENEFIT_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Custom Post Tax
      async getCustomPostTaxsEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId } = payload;
          const { data } = await getCustomPostTaxEmployee(companyId, employeeId);
          employee.GET_CUSTOM_POST_TAX_EMPLOYEE_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_CUSTOM_POST_TAX_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async deleteCustomPostTaxEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, customPostTaxId } = payload;
          const { data } = await deleteCustomPostTaxEmployee(
            companyId,
            employeeId,
            customPostTaxId,
          );
          employee.DELETE_CUSTOM_POST_TAX_EMPLOYEE_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.DELETE_CUSTOM_POST_TAX_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateCustomPostTaxEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, customPostTaxId, data: state } = payload;
          const { data } = await updateCustomPostTaxEmployee(
            companyId,
            employeeId,
            customPostTaxId,
            state,
          );
          employee.DELETE_CUSTOM_POST_TAX_EMPLOYEE_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.DELETE_CUSTOM_POST_TAX_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async putEmployeesInformation(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, data: state } = payload;
          const { data } = await putEmployeeInformationService(companyId, employeeId, state);
          employee.PUT_EMPLOYMENT_INFORMATION_EMPLOYEE_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.PUT_EMPLOYMENT_INFORMATION_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async postCustomPostTaxEmployee(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, data: state } = payload;
          const { data } = await postCustomPostTaxEmployee(companyId, employeeId, state);
          employee.POST_CUSTOM_POST_TAX_EMPLOYEE_SUCCESS(data);
          dispatch.employee.getCustomPostTaxsEmployee({ companyId, employeeId });
          return payload;
        } catch (error: any) {
          employee.POST_CUSTOM_POST_TAX_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Get current employee information
      async getEmployeeDetailsById(payload): Promise<Employee> {
        try {
          const { companyId, employeeId, isReturnResponse } = payload;
          const { data } = await getEmployeeDetails(companyId, employeeId);
          employee.GET_EMPLOYEE_DETAILS_BY_ID_SUCCESS(data);
          return isReturnResponse ? data : payload;
        } catch (error: any) {
          employee.GET_EMPLOYEE_DETAILS_BY_ID_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Ativate-deativate employee
      async deactivateEmployeeById(payload): Promise<unknown> {
        try {
          const { companyId, employeeId } = payload;
          const { data } = await deactivateEmployee(companyId, employeeId);
          employee.DEACTIVATE_EMPLOYEE_SUCCESS(data);
          dispatch.members.getTeamMembersList(companyId);
          return payload;
        } catch (error: any) {
          employee.DEACTIVATE_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async activateEmployeeById(payload): Promise<unknown> {
        try {
          const { companyId, employeeId } = payload;
          const { data } = await activateEmployee(companyId, employeeId);
          employee.ACTIVATE_EMPLOYEE_SUCCESS(data);
          dispatch.members.getTeamMembersList(companyId);
          return payload;
        } catch (error: any) {
          employee.ACTIVATE_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      //YTD Tax Deductions
      async generateYtdTaxDeductionEmployee(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { employeeId } = payload;
          const { data } = await generateYtdTaxDeductions(companyId.toString(), employeeId);
          employee.GENERATE_EMPLOYEE_YTD_TAX_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GENERATE_EMPLOYEE_YTD_TAX_INFORMATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //The updated Employee YTD Tax Deductions resource
      async updateEmployeeYtdTaxInformation(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { employeeId, data: ytd } = payload;
          const { data } = await patchYtdTaxDeductions(companyId.toString(), employeeId, ytd);
          employee.UPDATE_EMPLOYEE_YTD_TAX_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.UPDATE_EMPLOYEE_YTD_TAX_INFORMATION_FAILURE(error);
          callToast('error', error?.response?.data?.detail);
        }
      },
      //Paystub preview Employee
      async previewPaystub(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { employeeId, paymentId } = payload;
          const { data } = await previewPaystubEmployee(
            companyId.toString(),
            employeeId,
            paymentId,
          );
          employee.PREVIEW_PAYSTUB_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.PREVIEW_PAYSTUB_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      //Tax Information  Employee
      async generateTaxDetailsEmployee(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { employeeId } = payload;
          const { data } = await generateTaxDetailsEmployee(companyId.toString(), employeeId);
          employee.GENERATE_EMPLOYEE_TAX_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GENERATE_EMPLOYEE_TAX_INFORMATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      // Get Tax Details Employee
      async getTaxDetailsEmployee(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { employeeId } = payload;
          const { data } = await getTaxDetailsEmployee(companyId.toString(), employeeId);
          employee.GET_EMPLOYEE_TAX_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_EMPLOYEE_TAX_INFORMATION_FAILURE(error);
        }
      },
      // Update Employee Tax Information
      async updateEmployeeTaxInformation(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { employeeId, data: taxs } = payload;
          const { data } = await postTaxDetailsEmployee(companyId.toString(), employeeId, taxs);
          employee.UPDATE_EMPLOYEE_TAX_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.UPDATE_EMPLOYEE_TAX_INFORMATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      // Get runnable payrolls for a company
      async getRunnablePayrolls(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await getRunnablePayrolls(companyId.toString());
          employee.GET_RUNNABLE_EMPLOYEE_PAYROLLS_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_RUNNABLE_EMPLOYEE_PAYROLLS_FAILURE(error);
        }
      },
      // Check if account can run a payroll
      async getCheckIfAccountCanRunPayroll(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await getPayrollCheck(companyId.toString());
          employee.GET_CHECK_ACCOUNT_RUN_PAYROLL_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_CHECK_ACCOUNT_RUN_PAYROLL_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getPayrollHistoryEmployees(payload): Promise<unknown> {
        try {
          // eslint-disable-next-line
          const { companyId, page, dateOfPaymentBefore, dateOfPaymentAfter } = payload;
          const { data } = await getListOfPayrollHistoryEmployee(
            companyId,
            page,
            dateOfPaymentBefore,
            dateOfPaymentAfter,
          );
          employee.GET_LIST_PAYROLL_HISTORY_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.GET_LIST_PAYROLL_HISTORY_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getPayrollHistoryEmployeesPure(payload): Promise<PayrollHistoryResponse | undefined> {
        try {
          // eslint-disable-next-line
          const { companyId, page, dateOfPaymentBefore, dateOfPaymentAfter } = payload;
          const { data } = await getListOfPayrollHistoryEmployee(
            companyId,
            page,
            dateOfPaymentBefore,
            dateOfPaymentAfter,
          );
          employee.GET_LIST_PAYROLL_HISTORY_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_LIST_PAYROLL_HISTORY_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      async getPayrollHistoryEmployeesReworked(payload): Promise<unknown> {
        try {
          const { companyId, dateOfPaymentBefore, dateOfPaymentAfter } = payload;
          const { data } = await getListOfPayrollHistoryEmployeeReworked(
            companyId,
            dateOfPaymentBefore,
            dateOfPaymentAfter,
          );
          employee.GET_LIST_PAYROLL_HISTORY_REWORKED_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_LIST_PAYROLL_HISTORY_REWORKED_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getDetailsPaymentHistoryByIdEmployee(payload): Promise<unknown> {
        try {
          const { companyId, paymentHistoryId } = payload;
          const { data } = await getContractorPaymentHistoryDetailsEmployee(
            companyId,
            paymentHistoryId,
          );
          employee.GET_CURRENT_PAYMENT_INFORMATION_CURRENT_CONTRACTOR_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_CURRENT_PAYMENT_INFORMATION_CURRENT_CONTRACTOR_SUCCESS(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      async getEmployeesPayStubsDownload(payload): Promise<unknown> {
        try {
          const { companyId, id } = payload;
          const { data } = await getEmployeePayStubsDownload(companyId, id);
          employee.GET_EMPLOYEES_PAY_STUBS_DOWNLOAD_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_EMPLOYEES_PAY_STUBS_DOWNLOAD_FAILURE(error);
          callToast('error', error);
        }
      },
      async cancelPaymentsEmployeesInformationDetails(payload): Promise<unknown> {
        try {
          const { companyId, payrollId, id } = payload;
          const { data } = await cancelEmployeesPayrollItem(companyId, payrollId, id);
          employee.CANCELL_PAYMENT_INFORMATION_EMPLOYEE_DETAILS_BY_ID_SUCCESS(payrollId);
          return data;
        } catch (error: any) {
          employee.CANCELL_PAYMENT_INFORMATION_EMPLOYEE_BY_ID_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getRunnableActiveEmployees(payload): Promise<unknown> {
        try {
          const { companyId } = payload;
          const { data } = await getRunnableActiveEmployee(companyId);
          employee.GET_ACTIVE_EMPLOYEES_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_ACTIVE_EMPLOYEES_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async postRunPayrollEmployees(payload): Promise<unknown> {
        try {
          const { companyId, state, id } = payload;
          const { data } = await postPayEmployee(companyId, state, id);
          employee.POST_RUN_PAYROLL_EMPLOYEES_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.POST_RUN_PAYROLL_EMPLOYEES_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async approvePayrollEmployee(payload): Promise<unknown> {
        try {
          const { companyId, id } = payload;
          const { data } = await putApproveEmployeePayroll(companyId, id);
          employee.APPROVE_RUN_PAYROLL_EMPLOYEES_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.APPROVE_RUN_PAYROLL_EMPLOYEES_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async submitRunnablePayroll(payload): Promise<unknown> {
        try {
          const { companyId, state } = payload;
          const { data } = await postSubmitRunnablePayrollForCompany(companyId, state);
          employee.SUBMIT_RUNNABLE_PAYROLL_EMPLOYEES_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.SUBMIT_RUNNABLE_PAYROLL_EMPLOYEES_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
          return error;
        }
      },
      async getPaystubsHistoryEmployee(payload): Promise<unknown> {
        try {
          const { companyId, page, employeeId, dateOfPaymentBefore, dateOfPaymentAfter } = payload;
          const { data } = await getPayStubsHistoryEmployee(
            companyId,
            page,
            employeeId,
            dateOfPaymentBefore,
            dateOfPaymentAfter,
          );
          employee.GET_LIST_PAYROLL_HISTORY_TAB_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_LIST_PAYROLL_HISTORY_TAB_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getEmployeePayStubsDownloadItem(payload): Promise<unknown> {
        try {
          const { companyId, employeeId, id } = payload;
          const { data } = await getEmployeePayStubsDownloadItem(companyId, employeeId, id);
          return data;
        } catch (error: any) {
          callToast('error', error);
        }
      },
      // Download (PDF), All Pay stubs for specific Payroll
      async downloadAllPayStubsSpecificPayroll(payload): Promise<unknown> {
        try {
          const { companyId, id } = payload;
          const { data } = await getEmployeePayStubsDownloadAll(companyId, id);
          return data;
        } catch (error: any) {
          callToast('error', error);
        }
      },
      // Download (PDF), Pay stubs for specific Payroll Item

      async downloadPayrollItemPayStubsSpecificPayroll(payload): Promise<unknown> {
        try {
          const { companyId, payrollId, id } = payload;
          const { data } = await getEmployeePayStubsPayrollItem(companyId, payrollId, id);
          return data;
        } catch (error: any) {
          callToast('error', error);
        }
      },

      // Delete employee when exiting create modal
      async deleteEmployee(payload): Promise<unknown> {
        try {
          const { companyId, currentEmployeeId } = payload;
          const { data } = await deleteEmployee(companyId, currentEmployeeId);
          employee.DELETE_EMPLOYEE_ON_ADD_SUCCESS(data);
          dispatch.members.getTeamMembersList(companyId);
          return data;
        } catch (error: any) {
          employee.DELETE_EMPLOYEE_ON_ADD_FAILURE(error);
          callToast('error', error?.response?.data?.detail);
        }
      },

      // Skip benefits-garndishments-post-tax-deductions step
      async skipBenefits(payload): Promise<unknown> {
        try {
          const { companyId, employeeId } = payload;
          const { data } = await skipBenefits(companyId, employeeId);
          employee.SKIP_BENEFITS_EMPLOYEE_SUCCESS(data);
          return payload;
        } catch (error: any) {
          employee.SKIP_BENEFITS_EMPLOYEE_FAILURE(error);
          callToast('error', error?.response?.data?.detail);
        }
      },

      async deletePayroll(payload): Promise<unknown> {
        try {
          const { companyId, id } = payload;
          const { data } = await deletePayrollEmployeeHistory(companyId, id);
          employee.DELETE_PAYROLL_EMPLOYEE_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.DELETE_PAYROLL_EMPLOYEE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Get dates for Gross pay YTD subtitle
      async getGrossPayYTD(payload): Promise<unknown> {
        try {
          const { companyId, employeeId } = payload;
          const { data } = await getYTD(companyId, employeeId);
          employee.GET_YTD_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_YTD_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Share Employee Payroll
      async shareEmployeePayroll({ companyId, body }): Promise<unknown> {
        try {
          const { status } = await shareEmployeePayrollEndpoint({ companyId, body });
          return status;
        } catch (error: any) {
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Get All Employee's W2 form
      async getEmployeeW2Form(payload): Promise<unknown> {
        try {
          const { year } = payload;
          const { data } = await getEmployeeW2(year);
          employee.GET_W2_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.GET_W2_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Preview Employee's W2 form
      async previewEmployeeW2Form(payload): Promise<unknown> {
        try {
          const { id } = payload;
          const { data } = await previewEmployeeW2(id);
          employee.PREVIEW_W2_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.PREVIEW_W2_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Download Employee's W2 form as PDF file
      async downloadEmployeeW2Form(payload): Promise<unknown> {
        try {
          const { id } = payload;
          const { data } = await downloadEmployeeW2(id);
          employee.DOWNLOAD_W2_SUCCESS(data);
          return data;
        } catch (error: any) {
          employee.DOWNLOAD_W2_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      //Update Form W2
      async patchEmployeeW2Form(payload): Promise<unknown> {
        try {
          const { id, body } = payload;
          const { data, status } = await patchEmployeeW2(id, body);
          employee.PATCH_W2_SUCCESS(data);
          return { data, status };
        } catch (error: any) {
          employee.PATCH_W2_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Share Employee's W2 form via email
      async shareViaEmailEmployee(payload): Promise<unknown> {
        try {
          const { formID, email } = payload;
          const { data, status } = await postShareViaEmailEmployee(formID, { email: email });
          employee.SHARE_VIA_EMAIL_SUCCESS(data);
          return { data, status };
        } catch (error: any) {
          employee.SHARE_VIA_EMAIL_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
