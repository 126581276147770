import { ReactComponent as NotesIcon } from '@/assets/notes.svg';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { Button } from '@/components/ui/designSystem/Button/Button';
import { LINKS } from '@/utils/constants';
import useTermsAndCondition from './useTermsAndCondition';

const TermsAndCondition = () => {
  const { acceptedTermsHandler, hasAcceptedLatestTerms, isSavingAcceptedTerms } =
    useTermsAndCondition();

  return (
    <DialogComponent
      width="w-full max-w-[90vw] sm:max-w-[400px] max-w-[335px]"
      height="h-auto"
      modalClasses="rounded-small p-x-large bg-white flex flex-col justify-center sm:block"
      open={!hasAcceptedLatestTerms}
      onCancel={() => {
        //Pass empty function, as there is no way to cancel confirmation modal
      }}
    >
      <div className="gap-y-x-large flex w-full flex-col">
        <div className="gap-y-3x-large flex flex-col">
          <div className="flex justify-center">
            <div className="flex w-full flex-col items-center">
              <div className="bg-primary-emerald-light-bg flex size-12 items-center justify-center rounded-full">
                <NotesIcon />
              </div>
              <h1 className="text-content-heading text-3x-large font-F37Bolton-Bold mb-small mt-x-large leading-8">
                Terms of Service update
              </h1>

              <p className="text-content-body-medium text-large text-center font-extralight leading-6">
                We've recently updated our
                <a
                  href={LINKS.termsOfUse}
                  target="_blank"
                  className="mx-2x-small text-primary-emerald underline underline-offset-2"
                >
                  Terms of Service
                </a>
                and
                <a
                  href={LINKS.privacyPolicy}
                  target="_blank"
                  className="mx-2x-small text-primary-emerald underline underline-offset-2"
                >
                  Privacy Policy
                </a>
                . Please review and accept these updates to continue using BlinkPayroll.
              </p>

              <Button
                className="text-large mt-3x-large w-full"
                isLoading={isSavingAcceptedTerms}
                onPress={acceptedTermsHandler}
              >
                Accept & Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DialogComponent>
  );
};

export default TermsAndCondition;
