import * as Yup from 'yup';

import { EmployeePayrollDataType } from '@/redux/dto/history';

import { EMPLOYEE_PAYROLL_TYPES } from './constants';

Yup.addMethod(Yup.string, 'dateNotWeekend', function (errorMessage) {
  return this.test('test-date-not-weekend', errorMessage, function (value: string) {
    const { path, createError } = this;
    const date = new Date(value);

    const isWeekend = date.getDay() === 0 || date.getDay() === 6;

    return !isWeekend || createError({ path, message: errorMessage });
  });
});

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const email = Yup.string().email('Please enter a valid email').required('Email is required');

const emailOptional = Yup.string().email().nullable();

const benefitName = Yup.string()
  .required('Benefit name is required')
  .max(200, 'Benefit name can not be this long');

const postTaxDeductionName = Yup.string()
  .required('Post-tax deduction name is required')
  .max(200, 'Post-tax deduction name can not be this long');

const password = Yup.string()
  .min(8, 'Must be at least 8 characters')
  .max(32, 'Maximum 32 characters')
  .required('enter_passswrod')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*)(?=.{8,})/,
    'Password must contain at least one uppercase letter,one lowercase letter,and one number',
  )
  .matches(/^(\S+$)/, 'This field must not contain spaces.');

export const phone_number = Yup.string()
  .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, 'Phone number is not valid')
  .required('Please enter your phone number');

const firstName = Yup.string()
  .required('First name is required')
  .typeError('This field must <br></br> only contain letters')
  .matches(
    /^[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+(\s?[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]+)*$/,
    'This field must only contain letters',
  )
  .min(1, 'Enter at least 2 letters')
  .max(200, 'First name cannot be this long');

const middleName = Yup.string().nullable();

const lastName = Yup.string()
  .required('Last name is required')
  .typeError('This field must only contain letters')
  .matches(
    /^[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+(\s?[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]+)*$/,
    'This field must only contain letters',
  )
  .min(1, 'Enter at least 2 letters')
  .max(200, 'Last name cannot be this long');

export const inputPhoneNumberBasic = Yup.string()
  .notRequired()
  .nullable()
  .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, 'Phone number is not valid');

const per = Yup.number()
  .required('This field is required')
  .test('not-zero', 'This field is required', value => value !== 0);

const employeeType = Yup.number()
  .required('Employee type is required')
  .test('not-zero', 'Employee type is required', value => value !== 0);
const benefitType = Yup.number().required('Benifit type is required');

const garnishmentType = Yup.number().required('Garnishment type is required');

const deductionPortion = Yup.number().required('Select deduction portion');

const businessType = Yup.string().required('Type of business is required');

const industry = Yup.string().required('Business industry is required');

const businessName = Yup.string()
  .max(200, 'Company name cannot be this long')
  .required('Company/Employer name is mandatory');

const businessNameContractor = Yup.string()
  .required('Business name is required')
  .max(200, 'Business name cannot be this long');

const numberOfEmployees = Yup.number()
  .typeError('Must be a number')
  .min(0, 'Must be only positive number')
  .required('Number of employees is mandatory');

const numberOfContractors = Yup.number()
  .typeError('Must be a number')
  .min(0, 'Must be only positive number')
  .required('Number of contractors is mandatory');

const chooseEinOrSsn = Yup.number().nullable();

const ssnRequired = Yup.string()
  .required('SSN is required')
  .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be 9 characters');

const einRequired = Yup.string()
  .required('EIN is required')
  .matches(/^\d{2}-\d{7}$/, 'EIN must be 9 characters');

const address = Yup.string()
  .required('Street address is required')
  .matches(/^[^,]*$/, 'Street address must not contain a comma');

const aptSteNumber = Yup.string().nullable();
const city = Yup.string().required('City is required');
const state = Yup.string().required('State is required');
const zipCode = Yup.string().required('Zip Code is required');

const contractorType = Yup.number().required('Select contractor type');
const jobTitle = Yup.string().max(200, 'Job Title cannot be this long').nullable().notRequired();

const wageType = Yup.number().required('Wage type is required ');

const isPaidThisYear = Yup.boolean().required('Required');

export const EditBusinessFormAnotherTypesSchema = Yup.object().shape({
  businessType: businessType,
  name: businessName,
  numberOfEmployees: numberOfEmployees,
  numberOfContractors: numberOfContractors,
  chooseEinOrSsn: chooseEinOrSsn,
  ein: einRequired,
  address: address,
  aptSteNumber: aptSteNumber,
  city: city,
  state: state,
  zipCode: zipCode,
});

export const EditBusinessFormSoleProprietorshipSchema = Yup.object().shape({
  businessType: businessType,
  name: businessName,
  numberOfEmployees: numberOfEmployees,
  numberOfContractors: numberOfContractors,
  chooseEinOrSsn: chooseEinOrSsn,
  ein: Yup.string().when('chooseEinOrSsn', {
    is: (chooseEinOrSsn: number) => chooseEinOrSsn === 1,
    then: () => einRequired,
    otherwise: formSchema => formSchema.nullable().notRequired(),
  }),
  ssn: Yup.string().when('chooseEinOrSsn', {
    is: (chooseEinOrSsn: number) => chooseEinOrSsn === 2,
    then: formSchema => formSchema.required('SSN is required').min(9, 'SSN must be 9 characters'),
    otherwise: formSchema => formSchema.nullable().notRequired(),
  }),
  address: address,
  aptSteNumber: aptSteNumber,
  city: city,
  state: state,
  zipCode: zipCode,
});

export const UpdateEinOrSsnSchema = Yup.object().shape({
  chooseEinOrSsn: chooseEinOrSsn,
  ein: Yup.string().when('chooseEinOrSsn', {
    is: (chooseEinOrSsn: number) => chooseEinOrSsn === 1,
    then: formSchema => formSchema.required('EIN is required').min(9, 'EIN must be 9 characters'),
    otherwise: formSchema => formSchema.notRequired().nullable(),
  }),
  ssn: Yup.string().when('chooseEinOrSsn', {
    is: (chooseEinOrSsn: number) => chooseEinOrSsn === 2,
    then: formSchema => formSchema.required('SSN is required').min(9, 'SSN must be 9 characters'),
    otherwise: formSchema => formSchema.nullable().notRequired(),
  }),
});

export const CreateScheduleSchema = Yup.object().shape({
  payFrequency: Yup.number()
    .test('not-zero', 'Pay frequency is required', value => value !== 0)
    .required('Pay frequency is required'),
  firstPayDate: Yup.string()
    .required('First pay date is required')
    .typeError('First pay date is required'),
  secondPayDate: Yup.string()
    .when('payFrequency', {
      is: 3,
      then: () =>
        Yup.string()
          .required('Second pay date is required')
          .typeError('Second pay date is required')
          .dateNotWeekend('The date should not fall on weekend'),
      otherwise: () => Yup.string().notRequired(),
    })
    .when(['firstPayDate', 'payFrequency'], ([firstPayDate, payFrequency]) => {
      if (payFrequency === 3 && firstPayDate) {
        return Yup.string()
          .test(
            'is-lower-than-first-pay-date',
            'The second pay date should be after the first pay date',
            secondPayDate => {
              return new Date(firstPayDate) <= new Date(secondPayDate);
            },
          )
          .test(
            'is-thirteen-days-appart',
            'The first and second paydate should be at least 13 days apart',
            secondPayDate => {
              const timeDifference = Math.abs(new Date(firstPayDate) - new Date(secondPayDate));

              // Calculate the number of days
              const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

              // Check if the dates are exactly 13 days apart
              return daysDifference >= 13;
            },
          );
      }
    }),
  payPeriodStartDate: Yup.string()
    .required('Pay period is required')
    .typeError('Pay period is required'),
});

export const AddedNewWorkLocationSchema = Yup.object().shape({
  streetAddress: address,
  aptSteNumber: aptSteNumber,
  city: city,
  state: state,
  zipCode: zipCode,
});

export const AboutYourBusinessLocationFormSchema = Yup.object().shape({
  businessType: Yup.string().required('Business type is required'),
  streetAddress: Yup.string()
    .required('Street address is required')
    .matches(/^[^,]*$/, 'Street address must not contain a comma'),
  aptSteNumber: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('Zip code is required'),
});

// Alternative sign up

export const SignupSimpleSchema = Yup.object().shape({
  firstName: firstName,
  email: email,
});

export const SharePayrollSchema = Yup.object().shape({
  employeesData: Yup.array().of(
    Yup.object().shape({
      isChecked: Yup.boolean(),
      email: Yup.string().when(['isChecked'], {
        is: (isChecked: boolean) => isChecked,
        then: formSchema =>
          formSchema
            .required('Email is required')
            .test('Validate Email', 'Must be a valid email', value =>
              emailRegex.test(String(value).toLowerCase()),
            ),
        otherwise: formSchema => formSchema.notRequired(),
      }),
    }),
  ),
});
export const ContractorSharePayrollSchema = Yup.object().shape({
  contractorsData: Yup.array().of(
    Yup.object().shape({
      isChecked: Yup.boolean(),
      email: Yup.string().when(['isChecked'], {
        is: (isChecked: boolean) => isChecked,
        then: formSchema =>
          formSchema
            .required('Email is required')
            .test('Validate Email', 'Must be a valid email', value =>
              emailRegex.test(String(value).toLowerCase()),
            ),
        otherwise: formSchema => formSchema.notRequired(),
      }),
    }),
  ),
});

export const SingleContractorSharePayrollSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .test('Validate Email', 'Must be a valid email', value =>
      emailRegex.test(String(value).toLowerCase()),
    ),
});

// Account

export const BasicInfoSchema = Yup.object().shape({
  firstName: firstName,
  lastName: lastName,
  phoneNumber: phone_number,
});

export const EditFullNameSchema = Yup.object().shape({
  firstName: firstName,
  lastName: lastName,
});

export const EditPhoneNumberSchema = Yup.object().shape({
  phoneNumber: phone_number,
});

export const EditPasswordSchema = Yup.object().shape({
  currentPassword: password.required('Current Password is required'),
  newPassword: password.required('New Password is required'),
  newPassword2: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const FeedbackTextareaSchema = Yup.object().shape({
  feedback: Yup.string().required('This field is required'),
});

// Employee steps

export const EmployeeDetailsSchema = Yup.object().shape({
  firstName: firstName,
  middleName: middleName,
  lastName: lastName,
  ssn: ssnRequired,
});

export const EmployeeInformationSchema = Yup.object().shape({
  title: jobTitle,
  email: emailOptional,
  chooseWorkLocation: Yup.number()
    .oneOf([1, 2, 3], 'Select work location')
    .required('Choose work location is required'),
  workLocationId: Yup.number().when('chooseWorkLocation', {
    is: 2,
    then: schema =>
      schema
        .required('Work location is required')
        .typeError('Work location is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema => schema.nullable(),
  }),
  phoneNumber: inputPhoneNumberBasic,
  homeAddress: Yup.object().shape({
    streetAddress: address,
    aptSteNumber: aptSteNumber,
    city: city,
    state: state,
    zipCode: zipCode,
  }),
  workAddress: Yup.object().when('chooseWorkLocation', {
    is: 3,
    then: () => {
      return Yup.object({
        streetAddress: address,
        aptSteNumber: aptSteNumber,
        city: city,
        state: state,
        zipCode: zipCode,
      });
    },
    otherwise: schema => schema.nullable().typeError('Work  is requred'),
  }),
});

export const EmployeeCompensationInformationSchema = Yup.object().shape({
  employeeType: employeeType,
  per: per,
  amount: Yup.number()
    .required('Amount is required')
    .typeError('Amount is required')
    .transform(value => (isNaN(value) ? undefined : value)),
  isPaidThisYear: isPaidThisYear,
  grossPayYtd: Yup.number().when('isPaidThisYear', {
    is: true,
    then: schema =>
      schema
        .required('Gross pay YTD is required')
        .typeError('Gross pay YTD is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema => schema.nullable().notRequired().typeError('Gross pay YTD is required'),
  }),
  customHourlyRates: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      rate: Yup.string().required('Rate is required'),
    }),
  ),
});

export const EmployeeCompensationInformationUpdateSchema = Yup.object().shape({
  employeeType: employeeType,
  per: per,
  amount: Yup.number()
    .required('Amount is required')
    .typeError('Amount is required')
    .transform(value => (isNaN(value) ? undefined : value)),
  isPaidThisYear: Yup.boolean().optional(),
  grossPayYtd: Yup.number().when('isPaidThisYear', {
    is: true,
    then: schema =>
      schema
        .required('Gross pay YTD is required')
        .typeError('Gross pay YTD is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema => schema.nullable().notRequired().typeError('Gross pay YTD is required'),
  }),
  customHourlyRates: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      rate: Yup.string().required('Rate is required'),
    }),
  ),
});

export const EmployeeBenefitSchemaShort = Yup.object().shape({
  isAdditionalFieldsAdded: Yup.boolean(),
  name: benefitName,
  type: benefitType,
  deductionPortion: deductionPortion,
  amountPerPayPeriod: Yup.number().when('deductionPortion', {
    is: 1,
    then: schema =>
      schema
        .required('Amount per pay period is required')
        .typeError('Amount per pay period is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Amount per pay period is required'),
  }),
  percentageGrossPay: Yup.number().when('deductionPortion', {
    is: 2,
    then: schema =>
      schema
        .required('Percentage gross pay is required')
        .typeError('Percentage gross pay is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Percentage gross pay is required'),
  }),

  deductionToDate: Yup.number()
    .nullable()
    .typeError('Deduction to date 2023 is required')
    .transform(value => (isNaN(value) ? undefined : value)),

  deductionLimit: Yup.number()
    .nullable()
    .typeError('Deduction limit 2023 is required')
    .transform(value => (isNaN(value) ? undefined : value)),
});

export const EmployeeBenefitSchema = Yup.object().shape({
  isAdditionalFieldsAdded: Yup.boolean(),
  name: benefitName,
  type: benefitType,
  deductionPortion: deductionPortion,
  amountPerPayPeriod: Yup.number().when('deductionPortion', {
    is: 1,
    then: schema =>
      schema
        .required('Amount per pay period is required')
        .typeError('Amount per pay period is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Amount per pay period is required'),
  }),
  percentageGrossPay: Yup.number().when('deductionPortion', {
    is: 2,
    then: schema =>
      schema
        .required('Percentage gross pay is required')
        .typeError('Percentage gross pay is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Percentage gross pay is required'),
  }),

  deductionToDate: Yup.number()
    .nullable()
    .typeError('Deduction to date 2023 is required')
    .transform(value => (isNaN(value) ? undefined : value)),

  deductionLimit: Yup.number()
    .nullable()
    .typeError('Deduction limit 2023 is required')
    .transform(value => (isNaN(value) ? undefined : value)),

  companyContribution: Yup.number().when('isAdditionalFieldsAdded', {
    is: true,
    then: schema =>
      schema
        .required('Company contribution is required')
        .typeError('Company contribution is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Company contribution is required'),
  }),
  companyAmountPerPayPeriod: Yup.number().when('companyContribution', {
    is: 1,
    then: schema =>
      schema
        .required('Amount per pay period is required')
        .typeError('Amount per pay period is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Amount per pay period is required'),
  }),
  companyPercentageGrossPay: Yup.number().when('companyContribution', {
    is: 2,
    then: schema =>
      schema
        .required('Percentage gross pay is required')
        .typeError('Percentage gross pay is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Percentage gross pay is required'),
  }),
  companyDeductionToDate: Yup.number().when('isAdditionalFieldsAdded', {
    is: true,
    then: schema =>
      schema
        .nullable()
        .typeError('Wrong type')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Company contribution is required'),
  }),
  companyDeductionLimit: Yup.number().when('isAdditionalFieldsAdded', {
    is: true,
    then: schema =>
      schema
        .nullable()
        .typeError('Deduction limit 2023 is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Company contribution is required'),
  }),
});

export const EmployeeGarnishmentSchema = Yup.object().shape({
  type: garnishmentType,
  garnishmentName: Yup.string().when('type', {
    is: 2,
    then: schema =>
      schema
        .required('Garnishment name is required')
        .max(200, 'Garnishment name can not be this long')
        .typeError('Garnishment name is required'),
    otherwise: schema => schema.nullable().notRequired().typeError('Garnishment name is required'),
  }),
  deductionPortion: deductionPortion,
  amountPerPayPeriod: Yup.number().when('deductionPortion', {
    is: 1,
    then: schema =>
      schema
        .required('Amount per pay period is required')
        .typeError('Amount per pay period is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Amount per pay period is required'),
  }),
  percentageGrossPay: Yup.number().when('deductionPortion', {
    is: 2,
    then: schema =>
      schema
        .required('Percentage gross pay is required')
        .typeError('Percentage gross pay is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Percentage gross pay is required'),
  }),

  deductionToDate: Yup.number()
    .nullable()
    .typeError('Deduction to date 2023 is required')
    .transform(value => (isNaN(value) ? undefined : value)),

  deductionLimit: Yup.number()
    .nullable()
    .typeError('Deduction limit 2023 is required')
    .transform(value => (isNaN(value) ? undefined : value)),
});

export const EmployeeCustomPostTaxSchema = Yup.object().shape({
  name: postTaxDeductionName,
  deductionPortion: deductionPortion,
  amountPerPayPeriod: Yup.number().when('deductionPortion', {
    is: 1,
    then: schema =>
      schema
        .required('Amount per pay period is required')
        .typeError('Amount per pay period is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Amount per pay period is required'),
  }),
  percentageGrossPay: Yup.number().when('deductionPortion', {
    is: 2,
    then: schema =>
      schema
        .required('Percentage gross pay is required')
        .typeError('Percentage gross pay is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema =>
      schema.nullable().notRequired().typeError('Percentage gross pay is required'),
  }),

  deductionToDate: Yup.number()
    .nullable()
    .typeError('Deduction to date 2023 is required')
    .transform(value => (isNaN(value) ? undefined : value)),

  deductionLimit: Yup.number()
    .nullable()
    .typeError('Deduction limit 2023 is required')
    .transform(value => (isNaN(value) ? undefined : value)),
});

//Contractor steps

const reimbursement = {
  type: Yup.string().required('Please fill reimbursement title'),
  value: Yup.number().required('Please fill reimbursement amount'),
};

export const ContractorDetailsIndividualSchema = Yup.object().shape({
  contractorType: contractorType,
  firstName: firstName,
  middleName: middleName,
  lastName: lastName,
  ssn: ssnRequired,
});

export const ContractorDetailsBusinessSchema = Yup.object().shape({
  contractorType: contractorType,
  businessName: businessNameContractor,
  ein: einRequired,
});

export const ContractorHiringInformationSchema = Yup.object().shape({
  jobTitle: jobTitle,
  phoneNumber: inputPhoneNumberBasic,
  streetAddress: address,
  aptSteNumber: aptSteNumber,
  city: city,
  state: state,
  zipCode: zipCode,
  email: emailOptional,
});

export const RequestEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
});

export const Contractor1099NecFormSchema = Yup.object().shape({
  nonEmployeeCompensation: Yup.number().required('Compensation required'),
  federalIncomeTaxWithHeld: Yup.number().optional().nullable(),
  accountNumber: Yup.string().optional().nullable(),
  stateTaxWithHoldings: Yup.array().of(
    Yup.object().shape({
      stateTaxWithHeld: Yup.number()
        .required('State Tax Withheld is required')
        .transform((value, originalValue) =>
          originalValue && !isNaN(originalValue) ? Number(originalValue) : value,
        ),
      statePayerNumber: Yup.number().required('State/Payer State Number is required'),
      stateIncome: Yup.number()
        .required('State Income is required')
        .transform((value, originalValue) =>
          originalValue && !isNaN(originalValue) ? Number(originalValue) : value,
        ),
    }),
  ),
  payerMadeDirectSales: Yup.boolean().optional(),
});

export const ContractorCompensationInformationSchema = Yup.object().shape({
  wageType: wageType,
  isPaidThisYear: isPaidThisYear,
  hourlyRate: Yup.number().when('wageType', {
    is: 2,
    then: schema =>
      schema
        .required('Hourly rate is required')
        .typeError('Hourly rate is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema => schema.nullable().typeError('Hourly rate is requred'),
  }),
  totalPaymentsYtd: Yup.number()
    .required('Total payments YTD is required')
    .moreThan(-1, 'Total payments YTD must be not equal to zero')
    .notOneOf([0], 'Total payments YTD cannot be zero')
    .typeError('Total payments YTD is required'),
});

export const ContractorCompensationInformationSchemaNullable = Yup.object().shape({
  wageType: wageType,
  isPaidThisYear: isPaidThisYear,
  hourlyRate: Yup.number().when('wageType', {
    is: 2,
    then: schema =>
      schema
        .required('Hourly rate is required')
        .typeError('Hourly rate is required')
        .transform(value => (isNaN(value) ? undefined : value)),
    otherwise: schema => schema.nullable().typeError('Hourly rate is requred'),
  }),
  totalPaymentsYtd: Yup.number()
    .nullable()
    .notRequired()
    .typeError('Total payments YTD is required'),
});

const formSchema = {
  isChecked: Yup.boolean(),
  wageType: Yup.number(),
  reimbursement: Yup.array().of(Yup.object().shape(reimbursement)),
  bonus: Yup.number()
    .notRequired()
    .transform(value => (isNaN(value) || value === null || value === undefined ? 0 : value)),
  earnings: Yup.number().when(['wageType', 'isChecked'], {
    is: (wageType: number, isChecked: boolean) => isChecked,
    then: formSchema =>
      formSchema
        .required('Earnings is required')
        .moreThan(0, 'Earnings is required')
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string') {
            return Number(originalValue.replace(',', '.'));
          }
          return value;
        }),

    otherwise: formSchema =>
      formSchema.notRequired().transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return Number(originalValue.replace(',', '.'));
        }
        return value;
      }),
  }),
  invoiceNumber: Yup.string()
    .nullable()
    .when('isChecked', {
      is: true,
      then: formSchema =>
        formSchema
          .notRequired()
          .transform((_, originalValue) => (originalValue === '' ? null : originalValue)),

      otherwise: formSchema =>
        formSchema
          .notRequired()
          .transform((value, originalValue) => (originalValue === '' ? null : originalValue)),
    }),
  memo: Yup.string()
    .trim()
    .max(255, 'Memo should be less than or equal to 255 characters')
    .notRequired(),
};

const oneTimeDeduction = {
  type: Yup.string().required('Please fill deduction title'),
  value: Yup.number()
    .test(
      'minimum-value',
      'The one-time deduction cannot be 50% more than the total amount of net wages',
      function (value) {
        const totalAmount = this.from?.[1].value.totalAmount || undefined;

        if (totalAmount && value) {
          if (value > totalAmount * 1.5) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
    )
    .required('Please fill deduction amount'),
};

const paymentEmployee = {
  isChecked: Yup.boolean(),
  oneTimeDeductions: Yup.array().of(Yup.object().shape(oneTimeDeduction)),
  reimbursement: Yup.array().of(Yup.object().shape(reimbursement)),
  bonus: Yup.number()
    .notRequired()
    .transform(value => (isNaN(value) || value === null || value === undefined ? 0 : value))
    .when('isChecked', {
      is: isChecked => isChecked,
      then: schema =>
        // Validate Bonus Field for the Bonus payroll
        schema.test('validate-bonus-field', 'Bonus must be greater than 0', function (value) {
          // Get Payroll Type
          const payrollType = this?.from?.[this?.from?.length - 1]?.value?.payrollType;
          const isBonusPayroll = EMPLOYEE_PAYROLL_TYPES.bonus === payrollType;
          if (!isBonusPayroll) {
            return true;
          }
          const formattedValue = parseFloat(value);
          return !isNaN(formattedValue) && formattedValue > 0;
        }),
      otherwise: schema => schema.notRequired().nullable(),
    }),
  PTO: Yup.number()
    .notRequired()
    .transform((value, originalValue) => (originalValue === '' ? null : Number(originalValue))),
  paycheckTips: Yup.number()
    .notRequired()
    .transform((value, originalValue) => (originalValue === '' ? null : Number(originalValue))),
  commission: Yup.number()
    .notRequired()
    .transform((value, originalValue) => (originalValue === '' ? null : Number(originalValue)))
    .when('isChecked', {
      is: isChecked => isChecked,
      then: schema =>
        // Validate Commission Field for the Bonus payroll
        schema.test(
          'validate-commission-field',
          'Commission must be greater than 0',
          function (value) {
            // Get Payroll Type
            const payrollType = this?.from?.[this?.from?.length - 1]?.value?.payrollType;
            const isCommissionPayroll = EMPLOYEE_PAYROLL_TYPES.commission === payrollType;
            if (!isCommissionPayroll) {
              return true;
            }
            const formattedValue = parseFloat(value);
            return !isNaN(formattedValue) && formattedValue > 0;
          },
        ),
      otherwise: schema => schema.notRequired().nullable(),
    }),
  overtimeHours: Yup.number()
    .notRequired()
    .transform((_, originalValue) => (originalValue === '' ? null : Number(originalValue))),
  doubleOvertimeHours: Yup.number()
    .notRequired()
    .transform((_, originalValue) => (originalValue === '' ? null : Number(originalValue))),
  regularHours: Yup.number().when(['isChecked'], {
    is: (isChecked: boolean) => isChecked,
    then: formSchema =>
      formSchema
        .required('Please fill in this field')
        .transform((_, originalValue) =>
          ['', null, undefined].includes(originalValue) ? null : Number(originalValue),
        ),

    otherwise: formSchema =>
      formSchema
        .notRequired()
        .transform((_, originalValue) =>
          ['', null, undefined].includes(originalValue) ? null : Number(originalValue),
        ),
  }),
  customHourlyRates: Yup.array().of(
    Yup.object().when(['isChecked'], {
      is: (isChecked: boolean) => isChecked,
      then: formSchema => formSchema.notRequired().nullable(),
      otherwise: formSchema => formSchema.notRequired().nullable(),
    }),
  ),

  totalAmount: Yup.number(),
};

export const PayContractorSchema = Yup.object().shape({
  dateOfPayment: Yup.string()
    .required('Date of payment is required')
    .typeError('Date of payment is required'),
  startDate: Yup.string().notRequired(),
  endDate: Yup.string().notRequired(),
  payments: Yup.array().of(Yup.object().shape(formSchema)),
});

export const PayEmployeeSchema = Yup.object().shape({
  payrollType: Yup.string().nullable(),
  payFrequency: Yup.number()
    .when('payrollType', {
      is: (payrollType: EmployeePayrollDataType['type']) =>
        [EMPLOYEE_PAYROLL_TYPES.regular, EMPLOYEE_PAYROLL_TYPES.test].includes(payrollType),
      then: schema => schema.required(),
      otherwise: schema => schema.notRequired().nullable(),
    })
    .required(),
  status: Yup.number().required(),
  payDate: Yup.string().required('Pay Date is  required').typeError('Pay Date is  required'),
  payments: Yup.array()
    .of(Yup.object().shape(paymentEmployee))
    .when('payrollType', {
      is: payrollType => {
        return [
          EMPLOYEE_PAYROLL_TYPES.bonus,
          EMPLOYEE_PAYROLL_TYPES.commission,
          EMPLOYEE_PAYROLL_TYPES.off_cycle,
        ].includes(payrollType);
      },
      then: schema =>
        schema.test(
          'at-least-one-employee-required-to-run-payroll',
          'Please select at least one employee to run payroll',
          value => {
            return Array.isArray(value) ? value.some(item => item.isChecked) : true;
          },
        ),
      otherwise: schema => schema.notRequired().nullable(),
    }),
});

export const SelectRegularPayrollSchema = Yup.object().shape({
  payrolls: Yup.array().of(
    Yup.object().shape({
      isChecked: Yup.boolean(),
      payDate: Yup.string().when(['isChecked'], {
        is: (isChecked: boolean) => isChecked,
        then: formSchema => formSchema.required('Please select a pay date'),

        otherwise: formSchema => formSchema.notRequired(),
      }),
    }),
  ),
  dateOfPayment: Yup.string()
    .required('Date of payment is required')
    .typeError('Date of payment is required'),
  dateOfPaymentOverdue: Yup.string()
    .required('Date of payment is required')
    .typeError('Date of payment is required'),
  dateOfPaymentPast: Yup.string()
    .required('Date of payment is required')
    .typeError('Date of payment is required'),
});

export const EmailShareSchema = Yup.object().shape({
  shareContractorsEmail: Yup.string()
    .email('Please enter a valid email')
    .required(`Contractor's email is required`),
});

export const TestValidationSchema = Yup.object().shape({
  dateOfPayment: Yup.string()
    .required('Date of payment is required')
    .typeError('Date of payment is required'),
});

// ---- Onboarding_B Schemas ----

export const OnboardingB_AboutYourBusinessFormSchema = Yup.object().shape({
  name: businessNameContractor,
  industry: industry,
  customIndustry: Yup.string().when(['industry'], {
    is: (industry: string) => industry === 'Other',
    then: schema => schema.required('Please specify'),
    otherwise: schema => schema.notRequired(),
  }),
});
// ---- Onboarding_B Schemas ----

export const userPersonaMissingSchema = Yup.object().shape({
  businessName: Yup.string()
    .required('Business name is required')
    .min(3, 'Business name must be at least 3 characters')
    .max(50, 'Business name must be at most 50 characters'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, 'Phone number is not valid'),
});

export const liveDemoFormSchema = Yup.object().shape({
  firstName,
  lastName,
  businessRole: Yup.string().required('Please select a role'),
  numberOfEmployees,
  numberOfContractors,
  email,
  termsAccepted: Yup.boolean()
    .required('Please accept terms of use and privacy policy')
    .oneOf([true], 'Please accept terms of use and privacy policy'),
  otherBusinessRole: Yup.string()
    .nullable()
    .test('is-other-role-required', 'Please specify your role', function (value) {
      const { businessRole } = this.parent;
      if (businessRole === 'other') {
        return value ? true : false;
      }
      return true;
    }),
});
