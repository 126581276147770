import { DialogComponent, LoaderComponent } from '@/components';
import Accordion from '@/components/ui/Accordion/Accordion';
import { Button } from '@/components/ui/designSystem/Button/Button';
import { CalendlyModal } from '@/modules/myAccount/components/CancelSubscriptionModal/modals/CalendlyModal';
import { FeatureOverView } from '../FeatureOverview';
import useOnboardingSchedulerMobile from './useOnboardingSchedulerMobile';

const OnboardingSchedulerMobile = () => {
  const { handleCalendlyModal, isCalendlyLoading, isCalendlyModalOpen } =
    useOnboardingSchedulerMobile();
  return (
    <div className="flex flex-col gap-6 lg:hidden">
      <Button onPress={handleCalendlyModal}>Book a slot</Button>
      {isCalendlyModalOpen && (
        <DialogComponent
          modalClasses="rounded-lg block  lg:hidden"
          open={isCalendlyModalOpen}
          onCancel={handleCalendlyModal}
          children={
            <div className="relative h-full">
              {isCalendlyLoading && (
                <div className=" absolute flex h-full w-full flex-col items-center justify-center">
                  <LoaderComponent />
                </div>
              )}

              <CalendlyModal
                closeButtonOnRight={true}
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: true, // Hide content like description
                  hideLandingPageDetails: true, // Hide additional Calendly content
                  hideGdprBanner: true,
                }}
                onClose={handleCalendlyModal}
              />
            </div>
          }
          width=" w-[90vw] sm:w-[50vw] "
          height="h-[65vh] "
        />
      )}
      <Accordion
        accordionClasses="w-full border px-medium rounded-x-small [&>:first-child]:py-medium "
        title="Here’s what you’ll need"
        accordionChildClasses="flex flex-col gap-6 pt-6"
        accordionTitleClasses="text-large font-F37Bolton-Medium"
        isLastItem
      >
        <FeatureOverView />
      </Accordion>
    </div>
  );
};

export default OnboardingSchedulerMobile;
