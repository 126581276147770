import { queryEndpoints as accountQueryEndPoints } from '@/api/account/endpoints';
import { MutationEndpoint, QueryEndpoint } from '@/api/types';

export const queryEndpoints = {
  getAppSetting: (): QueryEndpoint => ({
    queryKey: ['app-settings'],
    url: '/app-settings',
  }),
};

export const mutationEndpoints = {
  updateTerms: (accountId?: number): MutationEndpoint => ({
    mutationKey: ['update-terms', accountId],
    url: `/account/${accountId}/accept-terms`,
    invalidateKeys: [accountQueryEndPoints.getAccountInfo().queryKey],
  }),
};
