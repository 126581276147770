import moment from 'moment';

import { OverWriteOption } from '@/modules/payrollSchedule/usePayrollSchedule';

type PayPeriodDate = {
  [key: string]: string;
};

export const getNextWeekDays = (days: string | null) => {
  const day = moment.weekdays().findIndex((ele: string) => ele === days);
  const numOfWeeks = 26;
  const upcomingMondays = [];
  const today = moment();

  for (let i = 0; i <= numOfWeeks; i++) {
    const nextWeekDays = today.clone().startOf('isoWeek').add(i, 'weeks').day(day);

    // Exclude the current week's Day
    if (nextWeekDays.isSameOrAfter(today, 'day')) {
      upcomingMondays.push(nextWeekDays.format('MM/DD/YYYY'));
    }
  }

  return upcomingMondays;
};

export const getWeekDays = () => {
  return moment.weekdays().splice(1, 5);
};

// ALL DAYS OF MONTH
export const getDaysInMonths = () => {
  const daysInMonths = [];
  for (let i = 1; i <= moment().daysInMonth() - 1; i++) {
    daysInMonths.push(i);
  }
  daysInMonths.push('Last day of the month');
  return daysInMonths;
};

export const getNextMonthDate = (day: number | string) => {
  const monthPayDate = [];
  const currentDate = moment().startOf('month');

  for (let i = 0; i < 12; i++) {
    if (day === 'Last day of the month') {
      currentDate.endOf('month');
    } else {
      // Ensure the specified day is within the valid range of the month
      currentDate.date(Math.min(day as number, currentDate.daysInMonth()));
    }
    if (currentDate.isBefore(moment(), 'day')) {
      currentDate.add(1, 'months').startOf('month'); // Move to the next month
      continue; // Skip the rest of the loop and start with the next iteration
    }

    monthPayDate.push(currentDate.format('MM/DD/YYYY'));
    currentDate.add(1, 'months');
  }
  return monthPayDate;
};

export const getSemiMonthlyDate = () => {
  const currentDate = moment();
  const semiMonthlyDate = [];

  for (let i = 0; i < 6; i++) {
    const semiMonthlyStart = currentDate.clone().date(15);
    const semiMonthlyEnd = currentDate.clone().endOf('month');
    if (semiMonthlyStart.isSameOrAfter(moment())) {
      semiMonthlyDate.push(semiMonthlyStart.format('MM/DD/YYYY'));
    }

    if (semiMonthlyEnd.isSameOrAfter(moment())) {
      semiMonthlyDate.push(semiMonthlyEnd.format('MM/DD/YYYY'));
    }
    currentDate.add(1, 'months');
  }

  return semiMonthlyDate;
};

// PERIOD DATE
export const getPayPeriodDate = (selectedDate: string | null) => {
  const startDate = moment(selectedDate);

  const prevStartOfMonth = startDate.clone().subtract(1, 'months').startOf('month');

  const dateList = [];

  while (startDate.isSameOrAfter(prevStartOfMonth)) {
    dateList.push(startDate.format('MM/DD/YYYY'));
    startDate.subtract(1, 'day');
  }

  return dateList;
};

// Semi-Monthly Period Date
export const getSemiMonthlyPayPeriodDate = (
  payPeriodStartDate: string | null,
  firstPayDate: string | null,
  secondPayDate: string | null,
): string[] => {
  const payPeriodEndDate: string[] = [];

  const pushDatesUntilLimit = (endDate: moment.Moment, limitDate: moment.Moment) => {
    // Include the dates until endDate is equal to limitDate
    endDate.startOf('day');
    limitDate.startOf('day');
    while (endDate.isSameOrAfter(limitDate)) {
      payPeriodEndDate.push(endDate.format('MM/DD/YYYY'));
      endDate.subtract(1, 'days');
    }
  };

  // Ensure the required inputs are not null
  if (!payPeriodStartDate || !secondPayDate) {
    return payPeriodEndDate;
  }

  // Parse the payPeriodStartDate using moment
  const startDate = moment(payPeriodStartDate);

  if (!startDate.isValid()) {
    return payPeriodEndDate;
  }

  // Determine which pay date to use as the end point
  const startDay = startDate.date();
  const startMonth = startDate.clone().format('MM');
  let secondPayDateDay: number;

  // Handle 'Last day of the month' case for secondPayDate
  if (
    secondPayDate === 'Last day of the month' ||
    (Number(startDay) < Number(secondPayDate) && Number(startMonth) === 2)
  ) {
    secondPayDateDay = startDate.clone().endOf('month').date();
  } else {
    secondPayDateDay = parseInt(secondPayDate);
  }

  if (startDay === parseInt(firstPayDate)) {
    // Case 1: User selected firstPayDate, go backward until latest secondPayDate
    let endDate = startDate.clone();
    let endDateLimit = endDate.clone().subtract(1, 'month').date(secondPayDateDay);

    // Check if the resulting date is valid
    if (!endDateLimit.isValid() || endDateLimit.date() !== secondPayDateDay) {
      endDateLimit = endDate.clone().subtract(1, 'month').endOf('month');
    }

    pushDatesUntilLimit(endDate, endDateLimit);
  } else if (startDay === secondPayDateDay) {
    // Case 2: User selected secondPayDate, go backward until latest firstPayDate
    let endDate = startDate.clone();
    let endDateLimit = endDate.clone().date(parseInt(firstPayDate));

    pushDatesUntilLimit(endDate, endDateLimit);
  }

  return payPeriodEndDate;
};

export const getDatesForOneYear = (firstPayDay: string, secondPayDay: string) => {
  let startDate = moment().date(+firstPayDay);
  let endDate = moment().date(+secondPayDay);

  const resultDates = [];

  for (let i = 0; i < 12; i++) {
    const startDateFormat = startDate.format('MM/DD/YYYY');
    let endDateFormat;

    if (secondPayDay === 'Last day of the month') {
      endDateFormat = endDate.clone().endOf('month').format('MM/DD/YYYY');
    } else {
      endDateFormat = endDate.format('MM/DD/YYYY');
    }

    // Check if the start date is in the past
    if (moment(startDateFormat).isBefore(moment(), 'day')) {
      startDate = moment().date(+firstPayDay).add(1, 'months');
      continue; // Skip the current iteration
    }

    // Check if the end date is in the past
    if (moment(endDateFormat).isBefore(moment(), 'day')) {
      endDate = moment().date(+secondPayDay).add(1, 'months');
      continue; // Skip the current iteration
    }

    resultDates.push(endDateFormat);
    resultDates.push(startDateFormat);

    startDate.add(1, 'months');
    endDate.add(1, 'months');
  }

  return resultDates.sort();
};

export const calculateSemiMonthlyPayPeriods = (
  selectedDate: string | null,
  payPeriod: string[],
  firstPayDate?: string | null,
  secondPayDate?: string | null,
) => {
  if (selectedDate && payPeriod) {
    const selectedMoment = moment(selectedDate, 'MM/DD/YYYY');

    const selectedIndex = payPeriod.findIndex(date => date === selectedDate);

    function findPreviousSecondDate(selectedDateStr, firstDate, secondDate) {
      // Parse the selected date string using Moment.js
      const selectedDate = moment(selectedDateStr, 'MM/DD/YYYY');

      // Create moments for the first and second dates
      const momentFirstDate = moment(selectedDate).date(firstDate);
      const momentSecondDate = moment(selectedDate).date(secondDate);

      const previousDate =
        selectedDate.date() <= firstDate
          ? momentSecondDate.clone().subtract(1, 'months').date(secondDate)
          : momentFirstDate.clone().date(firstDate);

      // Return the formatted result
      return previousDate.format('MM/DD/YYYY');
    }

    function getDateLogic(inputDate) {
      const dateToCheck = moment(inputDate, 'MM/DD/YYYY');

      if (firstPayDate && secondPayDate) {
        return findPreviousSecondDate(inputDate, firstPayDate, secondPayDate);
      }

      if (dateToCheck.date() === 15) {
        return dateToCheck.subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
      }

      if (dateToCheck.isSame(dateToCheck.clone().endOf('month'), 'day')) {
        return dateToCheck.date(15).format('MM/DD/YYYY');
      }
    }

    const firstPayPeriodStart = !payPeriod[selectedIndex - 1]
      ? moment(getDateLogic(payPeriod[selectedIndex])).add(1, 'days').format('MM/DD/YYYY')
      : moment(payPeriod[selectedIndex - 1], 'MM/DD/YYYY')
          .add(1, 'days')
          .format('MM/DD/YYYY');
    const firstPayPeriodEnd = moment(selectedDate, 'MM/DD');

    const secondPayPeriodStart = !payPeriod[selectedIndex - 1]
      ? moment(getDateLogic(payPeriod[selectedIndex]))
          .add(1, 'days')
          .subtract(5, 'days')
          .format('MM/DD/YYYY')
      : moment(payPeriod[selectedIndex - 1], 'MM/DD/YYYY')
          .add(1, 'days')
          .subtract(5, 'days')
          .format('MM/DD');
    const secondPayPeriodEnd = selectedMoment.subtract(5, 'days');

    return [
      {
        [secondPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(secondPayPeriodStart).format(
          'ddd, MMM DD',
        )} - ${secondPayPeriodEnd.format('ddd, MMM DD')}`,
      },
      {
        [firstPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(firstPayPeriodStart).format(
          'ddd, MMM DD',
        )} - ${firstPayPeriodEnd.format('ddd, MMM DD')}`,
      },
      { other: OverWriteOption.other },
    ];
  }
};

// PAY PERIOD FOR FORWARD MONTHS
export const calculatePayPeriods = (selectedDate: string | null) => {
  if (selectedDate) {
    const firstPayPeriodStart = moment(selectedDate).subtract(1, 'weeks').add(1, 'days');
    const firstPayPeriodEnd = moment(selectedDate);

    const secondPayPeriodStart = moment(selectedDate).subtract(2, 'weeks').add(1, 'days');
    const secondPayPeriodEnd = moment(selectedDate).subtract(1, 'weeks');

    return [
      {
        [secondPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(secondPayPeriodStart).format(
          'ddd, MMM DD',
        )} - ${secondPayPeriodEnd.format('ddd, MMM DD')}`,
      },
      {
        [firstPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(firstPayPeriodStart).format(
          'ddd, MMM DD',
        )} - ${firstPayPeriodEnd.format('ddd, MMM DD')}`,
      },
      { other: OverWriteOption.other },
    ];
  }
};

// CALCULATE THE PAY-PERIOD FOR BI-WEEKLY
export const calculateBiWeekPayPeriods = (selectedDate: string | null) => {
  if (selectedDate) {
    const firstPayPeriodStart = moment(selectedDate).subtract(2, 'weeks').add(1, 'days');
    const firstPayPeriodEnd = moment(selectedDate);

    const secondPayPeriodStart = moment(selectedDate).subtract(3, 'weeks').add(1, 'days');
    const secondPayPeriodEnd = moment(selectedDate).subtract(1, 'weeks');

    return [
      {
        [secondPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(secondPayPeriodStart).format(
          'ddd, MMM DD',
        )} - ${secondPayPeriodEnd.format('ddd, MMM DD')}`,
      },
      {
        [firstPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(firstPayPeriodStart).format(
          'ddd, MMM DD',
        )} - ${firstPayPeriodEnd.format('ddd, MMM DD')}`,
      },
      { other: OverWriteOption.other },
    ];
  }
};

// CALCULATE WEEKLY BACK-DATED PAY-PERIOD
export const calculateWeeklyBackdatedPayPeriod = (
  dateList: string[],
  payPeriod?: string,
  payPeriodList?: PayPeriodDate[],
) => {
  if (dateList) {
    const payPeriodIndex = payPeriodList?.findIndex(item => {
      const values = Object.values(item);
      return values.includes(payPeriod!);
    });

    const payPeriods = dateList.map(date => {
      const payPeriodStart =
        payPeriodIndex === 0
          ? moment(date).subtract(2, 'weeks').add(1, 'days')
          : moment(date).subtract(1, 'weeks').add(1, 'days');
      const payPeriodEnd = payPeriodIndex === 0 ? moment(date).subtract(1, 'weeks') : moment(date);

      return [
        {
          [payPeriodEnd.format('YYYY-MM-DD')]: `${moment(payPeriodStart).format(
            'ddd, MMM DD',
          )} - ${payPeriodEnd.format('ddd, MMM DD')}`,
        },
      ];
    });

    return payPeriods
      .flat()
      .filter(element => element !== undefined)
      .reverse();
  }
};

// CALCULATE BI-WEEKLY BACK-DATED PAY-PERIOD

export const calculateBiWeeklyBackdatedPayPeriod = (
  dateList: string[],
  payPeriod?: string,
  payPeriodList?: PayPeriodDate[],
) => {
  if (dateList) {
    const payPeriodIndex = payPeriodList?.findIndex(item => {
      const values = Object.values(item);
      return values.includes(payPeriod!);
    });

    const payPeriods = dateList.map(date => {
      const payPeriodStart =
        payPeriodIndex == 0
          ? moment(date).subtract(2, 'weeks').add(1, 'days')
          : moment(date).subtract(1, 'weeks').add(1, 'days');
      const payPeriodEnd = payPeriodIndex == 0 ? moment(date) : moment(date).add(1, 'weeks');

      return [
        {
          [payPeriodEnd.format('YYYY-MM-DD')]: `${payPeriodStart.format(
            'ddd, MMM DD',
          )} - ${payPeriodEnd.format('ddd, MMM DD')}`,
        },
      ];
    });

    return payPeriods
      .flat()
      .filter(element => element !== undefined)
      .reverse();
  }
};

// MONTHLY PAY PERIOD FOR FORWARD MONTHS
export const calculateMonthlyPayPeriods = (
  payDate: string | null,
  payPeriod: string[],
  payDay?: string | null,
) => {
  if (payDate && payPeriod) {
    const parsedPayDate = moment(payDate, 'MM/DD/YYYY');
    const prevPayDate = moment(payPeriod[payPeriod.findIndex(date => date === payDate) - 1]);

    const prevDate =
      payDay === 'Last day of the month'
        ? moment(payDate, 'MM/DD/YYYY')
            .subtract(1, 'months')
            .endOf('months')
            .add(1, 'days')
            .format('MM/DD/YYYY')
        : moment(payPeriod[payPeriod.findIndex(date => date === payDate)])
            .subtract(1, 'months')
            .add(1, 'days')
            .format('MM/DD/YYYY');

    const previousMonthStartDate = parsedPayDate.clone().subtract(1, 'months').startOf('month');
    const previousMonthEndDate = parsedPayDate.clone().subtract(1, 'months').endOf('month');

    const currentPayPeriodStartDate = prevPayDate.add(1, 'days');
    const currentPayPeriodEndDate = parsedPayDate.clone();

    const firstDate =
      payPeriod.findIndex(date => date === payDate) === 0
        ? moment(prevDate).format('ddd, MMM DD')
        : currentPayPeriodStartDate.format('ddd, MMM DD');

    const formattedPreviousMonthPeriod = `${previousMonthStartDate.format(
      'ddd, MMM DD',
    )} - ${previousMonthEndDate.format('ddd, MMM DD')}`;

    const formattedCurrentPayPeriod = `${firstDate} - ${currentPayPeriodEndDate.format(
      'ddd, MMM DD',
    )}`;
    return [
      { [previousMonthEndDate.format('YYYY-MM-DD')]: formattedPreviousMonthPeriod },
      { [currentPayPeriodEndDate.format('YYYY-MM-DD')]: formattedCurrentPayPeriod },
      { other: OverWriteOption.other },
    ];
  }
};

// PAY PERIOD FOR BACKWARD SEMI MONTHLY
export const calculateBackdatedPayPeriod = (
  dateList: string[],
  payPeriod: string,
  daysDiff?: number,
  payDay?: number,
  semiMonthlyPayPeriod?: PayPeriodDate[],
) => {
  if (dateList) {
    const payPeriodIndex = semiMonthlyPayPeriod?.findIndex(item => {
      const values = Object.values(item);
      return values.includes(payPeriod);
    });
    if (payDay == 15) {
      function getDateLogic(inputDate) {
        const dateToCheck = moment(inputDate);

        if (dateToCheck.date() === 15) {
          return dateToCheck.endOf('month').format('MM/DD/YYYY');
        }

        if (dateToCheck.isSame(dateToCheck.clone().endOf('month'), 'day')) {
          return dateToCheck.date(15).format('MM/DD/YYYY');
        }

        return dateToCheck.date(15);
      }

      const payPeriods = dateList.map(date => {
        const selectedIndex = dateList.findIndex(d => d === date);

        const firstPayPeriodStart = !dateList[selectedIndex - 1]
          ? moment(getDateLogic(date)).add(1, 'days').format('MM/DD/YYYY')
          : moment(dateList[selectedIndex - 1], 'MM/DD/YYYY')
              .add(1, 'days')
              .format('MM/DD/YYYY');
        const firstPayPeriodEnd = moment(date, 'MM/DD/YYYY');

        const secondPayPeriodStart = !dateList[selectedIndex - 1]
          ? moment(getDateLogic(date)).add(1, 'days').subtract(5, 'days').format('MM/DD/YYYY')
          : moment(dateList[selectedIndex - 1], 'MM/DD/YYYY')
              .add(1, 'days')
              .subtract(5, 'days')
              .format('MM/DD/YYYY');
        const secondPayPeriodEnd = moment(date, 'MM/DD/YYYY').subtract(5, 'days');

        if (payPeriod === OverWriteOption.other) {
          const firstPayPeriodStartOther = !dateList[selectedIndex - 1]
            ? moment(getDateLogic(date)).add(1, 'days').format('MM/DD/YYYY')
            : moment(dateList[selectedIndex - 1], 'MM/DD/YYYY')
                .add(1, 'days')
                .subtract(daysDiff, 'days')
                .format('MM/DD/YYYY');
          const firstPayPeriodEndOther = moment(date, 'MM/DD/YYYY').subtract(daysDiff, 'days');

          return [
            {
              [firstPayPeriodEndOther.format('YYYY-MM-DD')]: `${moment(
                firstPayPeriodStartOther,
              ).format('ddd, MMM DD')} - ${firstPayPeriodEndOther.format('ddd, MMM DD')}`,
            },
          ];
        }

        if (payPeriodIndex === 1) {
          return [
            {
              [firstPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(firstPayPeriodStart).format(
                'ddd, MMM DD',
              )} - ${firstPayPeriodEnd.format('ddd, MMM DD')}`,
            },
          ];
        } else if (payPeriodIndex === 0) {
          return [
            {
              [secondPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(secondPayPeriodStart).format(
                'ddd, MMM DD',
              )} - ${secondPayPeriodEnd.format('ddd, MMM DD')}`,
            },
          ];
        }
      });

      return payPeriods.flat().reverse();
    } else {
      function getDateLogic(inputDate) {
        const dateToCheck = moment(inputDate);

        if (dateToCheck.date() === 15) {
          return dateToCheck.endOf('month').format('MM/DD/YYYY');
        }

        if (dateToCheck.isSame(dateToCheck.clone().endOf('month'), 'day')) {
          return dateToCheck.date(15).format('MM/DD/YYYY');
        }

        return dateToCheck.date(15);
      }

      const payPeriods = dateList.map(date => {
        const selectedIndex = dateList.findIndex(d => d === date);

        const firstPayPeriodStart = !dateList[selectedIndex - 1]
          ? moment(getDateLogic(date)).add(1, 'days').format('MM/DD/YYYY')
          : moment(dateList[selectedIndex - 1], 'MM/DD/YYYY')
              .add(1, 'days')
              .format('MM/DD/YYYY');
        const firstPayPeriodEnd = moment(date, 'MM/DD/YYYY');

        const secondPayPeriodStart = !dateList[selectedIndex - 1]
          ? moment(getDateLogic(date)).add(1, 'days').subtract(5, 'days').format('MM/DD/YYYY')
          : moment(dateList[selectedIndex - 1], 'MM/DD/YYYY')
              .add(1, 'days')
              .subtract(5, 'days')
              .format('MM/DD/YYYY');
        const secondPayPeriodEnd = moment(date, 'MM/DD/YYYY').subtract(5, 'days');

        if (payPeriod === OverWriteOption.other) {
          const firstPayPeriodStartOther = !dateList[selectedIndex - 1]
            ? moment(getDateLogic(date)).add(1, 'days').format('MM/DD/YYYY')
            : moment(dateList[selectedIndex - 1], 'MM/DD/YYYY')
                .add(1, 'days')
                .subtract(daysDiff, 'days')
                .format('MM/DD/YYYY');
          const firstPayPeriodEndOther = moment(date, 'MM/DD/YYYY').subtract(daysDiff, 'days');

          return [
            {
              [firstPayPeriodEndOther.format('YYYY-MM-DD')]: `${moment(
                firstPayPeriodStartOther,
              ).format('ddd, MMM DD')} - ${firstPayPeriodEndOther.format('ddd, MMM DD')}`,
            },
          ];
        }

        if (payPeriodIndex === 1) {
          return [
            {
              [firstPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(firstPayPeriodStart).format(
                'ddd, MMM DD',
              )} - ${firstPayPeriodEnd.format('ddd, MMM DD')}`,
            },
          ];
        } else if (payPeriodIndex == 0) {
          return [
            {
              [secondPayPeriodEnd.format('YYYY-MM-DD')]: `${moment(secondPayPeriodStart).format(
                'ddd, MMM DD',
              )} - ${secondPayPeriodEnd.format('ddd, MMM DD')}`,
            },
          ];
        }
      });

      return payPeriods.flat().reverse();
    }
  }
};

export const calculateMonthlyBackDatedPayPeriods = (
  dateList: string[],
  payPeriod?: string,
  monthlyPayPeriod?: PayPeriodDate[],
  payDay?: string,
  firstPayPeriod?: string,
) => {
  const payPeriodIndex = monthlyPayPeriod?.findIndex(item => {
    const values = Object.values(item);
    return values.includes(payPeriod!);
  });
  if (Boolean(payPeriod)) {
    const payPeriodStrings: any = [];

    dateList.forEach(dateString => {
      const currentDate = moment(dateString, 'MM/DD/YYYY');

      // Pay Period 1: Previous month's start to end of the previous month
      const period1Start = moment(currentDate).subtract(1, 'months').startOf('month');
      const period1End = moment(currentDate).subtract(1, 'months').endOf('month');

      // Pay Period 2: Previous month's pay date plus 1 day until the input date
      const prevMonthPayDate =
        payDay === 'Last day of the month' && !Boolean(firstPayPeriod)
          ? moment(currentDate).subtract(1, 'months').endOf('months')
          : moment(currentDate).subtract(1, 'months');

      const period2Start = moment(prevMonthPayDate).add(1, 'days');
      const period2End = moment(currentDate);

      const payPeriod1String = `${period1Start.format('ddd, MMM DD')} - ${period1End.format(
        'ddd, MMM DD',
      )}`;
      const payPeriod2String = `${period2Start.format('ddd, MMM DD')} - ${period2End.format(
        'ddd, MMM DD',
      )}`;

      if (payPeriodIndex === 0) {
        payPeriodStrings.push({ [period1End.format('YYYY-MM-DD')]: payPeriod1String });
      } else if (payPeriodIndex === 1 || payPeriodIndex === 2) {
        payPeriodStrings.push({ [period2End.format('YYYY-MM-DD')]: payPeriod2String });
      }
    });

    return payPeriodStrings;
  }
};

export const groupOptionByYear = (options: { [key: string]: string }[]) => {
  if (Array.isArray(options) && options.length) {
    const formattedOptions = options.reduce((acc: { [key: string]: string[] }, curr) => {
      const formattedDateArray = Object.entries(curr);
      const [dateStr, backDatedOption] = formattedDateArray?.length ? formattedDateArray[0] : [];
      if (formattedDateArray?.length) {
        const year = moment(dateStr).year().toString();
        acc[year] = acc[year] ?? [];
        acc[year].push(backDatedOption!);
      }
      return acc;
    }, {});

    return Object.entries(formattedOptions).sort(
      ([yearOfA], [yearOfB]) => Number(yearOfB) - Number(yearOfA),
    );
  }
  return [];
};

export const groupOptionByYearForMonthly = (options: { [key: string]: string }[]) => {
  if (Array.isArray(options) && options.length) {
    const formattedOptions = options.reduce(
      (acc: { [key: string]: { [key: string]: string }[] }, curr) => {
        const formattedDateArray = Object.entries(curr);
        const [dateStr] = formattedDateArray?.length ? formattedDateArray[0] : [];
        if (formattedDateArray?.length) {
          const year = moment(dateStr).year().toString();
          acc[year] = acc[year] ?? [];
          acc[year].push(curr);
        }
        return acc;
      },
      {},
    );

    return Object.entries(formattedOptions).sort(
      ([yearOfA], [yearOfB]) => Number(yearOfB) - Number(yearOfA),
    );
  }
  return [];
};

export const formatDate = (dateStr: string, dateFormat: string): string => {
  return dateStr && moment(dateStr).isValid() ? moment(dateStr).format(dateFormat) : dateStr;
};
