import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCall } from '@/api/axios';
import { SubscriptionInfo } from '@/redux/dto/auth';

import { queryEndpoints } from './endpoints';

type BillingInfoResponseType = AxiosResponse<SubscriptionInfo>;

type UseBillingInfoTypes = {
  rqParams?: Omit<
    UseQueryOptions<BillingInfoResponseType, Error, BillingInfoResponseType>,
    'queryKey'
  >;
};

const useBillingCenterInfo = ({ rqParams }: UseBillingInfoTypes) => {
  const { queryKey, url } = queryEndpoints.getBillingInfo();

  return useQuery<BillingInfoResponseType, Error>({
    queryKey,
    queryFn: () => getCall(url),
    ...rqParams,
  });
};
export default useBillingCenterInfo;
