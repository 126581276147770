import axios from '../axios';

export interface Payroll {
  payDate: string;
  payPeriod: PayPeriod;
  frequency: number;
}

export interface PayPeriod {
  startDate: string;
  endDate: string;
}

// Get runnable payrolls for a company

export const getRunnablePayrolls = (companyId: string) => {
  return axios.get(`/company/${companyId}/pay-employee/get-runnable`);
};

// Check if account can run a payroll

export const getPayrollCheck = (companyId: string) => {
  return axios.get(`/company/${companyId}/payroll-check`);
};

//Get runnable active employee for a company

export const getRunnableActiveEmployee = (companyId: string) => {
  return axios.get(`/company/${companyId}/pay-employee/get-active`);
};

//Submit runnable payroll for a company

export const postSubmitRunnablePayrollForCompany = (companyId: string, payload: unknown) => {
  return axios.post(`/company/${companyId}/pay-employee/submit-runnable`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

//Approve an employee payroll

export const putApproveEmployeePayroll = (companyId: string, id: number) => {
  return axios.put(`/company/${companyId}/payroll/${id}/approve`, {});
};

// Run employee payroll
//Check PP-2022
export const postPayEmployee = (companyId: string, payload: unknown, id: number) => {
  return axios.put(`/company/${companyId}/pay-employee/${id}`, payload);
};

// Cancel Employee's Payroll Item

export const cancelEmployeesPayrollItem = (companyId: string, payrollId: string, id: string) => {
  return axios.patch(
    `/company/${companyId}/employees/payroll-history/${payrollId}/items/${id}/cancel`,
    {},
  );
};
